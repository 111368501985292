import { API } from '@aws-amplify/api';
import { API_NAME } from '../../../api/constants';
import fetch, { urls } from '../../common/fetch';
import {
  Image,
  ImageCreateRequest,
  ImageCreateResponse,
  ImageDeleteRequest,
  ImageDeleteResponse,
  ImageEditRequest,
  ImageEditResponse,
  ImageJson,
  ImageUploadCompleteRequest,
  ImageUploadCompleteResponse,
} from './types';
import { jsonToImage } from './utils';

const jsonBody = (body: any) => ({
  body,
  headers: { 'Content-Type': 'application/json' },
});

/** Get a list of images */
export async function getImages(): Promise<Image[]> {
  const imagesJson = (await API.get(
    API_NAME,
    urls.api.images,
    {},
  )) as ImageJson[];
  return imagesJson.map(jsonToImage);
}

/** Edit an image. */
export async function patchImage(
  image: ImageEditRequest,
): Promise<ImageEditResponse> {
  return API.put(API_NAME, urls.api.image(image.id), jsonBody(image));
}

/** Delete an image. */
export async function deleteImage(
  image: ImageDeleteRequest,
): Promise<ImageDeleteResponse> {
  return await API.del(API_NAME, urls.api.image(image.id), jsonBody(image));
}

/** Post a new image and recieve the upload url. */
export async function postImage(
  image: ImageCreateRequest,
): Promise<ImageCreateResponse> {
  return API.post(API_NAME, urls.api.images, jsonBody(image));
}

/** Post the image file to S3 via an upload url. */
export async function putImageUpload(
  uploadUrl: string,
  file: File,
): Promise<void> {
  await fetch(uploadUrl, { method: 'PUT', body: file });
}

/** Post to the server to notify that the image has been uploaded. */
export async function postImageUploadComplete({
  id,
}: ImageUploadCompleteRequest): Promise<ImageUploadCompleteResponse> {
  const resp = await API.post(API_NAME, urls.api.image(id), {});
  return jsonToImage(resp);
}
