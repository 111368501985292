import classNames from 'classnames';
import Markdown from 'markdown-to-jsx';
import { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Section } from '../components/Base';
import { LinkedInIconLink } from '../components/icons/LinkedInIcon';
import ResponsiveImg from '../components/ResponsiveImage';
import { getTitle } from '../utils/document';

const avatarClaire1x = require('../../assets/img/avatar-claire.jpg');
const avatarClaire2x = require('../../assets/img/avatar-claire@2x.jpg');

const avatarPeter1x = require('../../assets/img/avatar-peter.jpg');
const avatarPeter2x = require('../../assets/img/avatar-peter@2x.jpg');

const MARKDOWN_OPTIONS = { forceBlock: true };

type AboutUsPageProps = {};

const AboutUsPage: FunctionComponent<AboutUsPageProps> = () => {
  return (
    <div className="premium">
      <Helmet>
        <title>{getTitle('About Us')}</title>
      </Helmet>
      <Section className="first-sec mb-20">
        <Container>
          <div className="mx-auto max-w-3xl">
            <h1 className="mb-10">About us</h1>
            <div className="prose">
              Founded in 2012 by Peter Coles, GoFullPage is the most trusted
              tool on the internet for creating full page screenshots with a
              single click. In order to record the web exactly as you see it,
              GoFullPage scrolls and captures entire web pages from top to
              bottom, and stitches the frames into one downloadable image.
              Because of our specialized scroll-and-capture algorithm,
              GoFullPage has become the industry standard for legal
              documentation of the internet.
              <br />
              <br />
              A screenshot is a single point of truth in a constantly-changing
              internet. To that end, our users report using GoFullPage in a wide
              range of digital contexts. Whether you're using it to record
              website design changes, gather articles for an immigration
              application, or archive your portfolio as a freelance writer, we
              are proud to provide a tool that transforms ephemeral web content
              into useful digital artifacts.
              <br />
              <br />
              For over a decade, we’ve developed a trusted brand and are
              committed to running a sustainable business long term for our
              millions of users. Our business strategy: create and support a
              high-quality, high-fidelity product, while continuously improving
              and developing specialized offerings based on user feedback. We
              are dedicated to user privacy and have actively designed our
              product to ensure sensitive data is not collected. We do not and
              have never monetized or sold data.
              <br />
              <br />
              Based in NYC, we are an independently-owned company that has never
              taken outside investment. This independence allows us to steer our
              business thoughtfully, without external pressure from lenders or
              venture capital.
            </div>
          </div>
          <h1 className="mx-auto mb-10 max-w-xl">Leadership</h1>
          <div className="mb-20 flex flex-col items-center justify-center gap-10 md:flex-row">
            {PEOPLE.map((p, i) => (
              <div
                className="w-full max-w-sm space-y-2 rounded-lg bg-white p-6 text-center"
                key={i}
              >
                <ResponsiveImg
                  src={p.avatar1x}
                  src2x={p.avatar2x}
                  width={100}
                  height={100}
                  className={classNames(
                    'mx-auto h-[100px] w-[100px] rounded-full bg-gray-200 outline outline-1',
                    p.outlineClassName,
                  )}
                  style={undefined}
                />
                <h2 className="text-3xl font-medium">{p.name}</h2>
                <div className="italic">{p.role}</div>
                {/* <div className="text-left">{p.bio}</div> */}
                <Markdown
                  className="text-left [&>a:hover]:text-black [&>a]:text-inherit [&>a]:underline"
                  options={MARKDOWN_OPTIONS}
                >
                  {p.bio}
                </Markdown>
                <div className="flex-start -ml-1.5 flex flex-row">
                  <LinkedInIconLink
                    target="_blank"
                    rel="noopener noreferrer"
                    {...p.linkedIn}
                  />
                </div>
              </div>
            ))}
          </div>
        </Container>
      </Section>
    </div>
  );
};

AboutUsPage.displayName = 'AboutUsPage';

export default AboutUsPage;

// ## Data

type Person = {
  avatar1x: string;
  avatar2x: string;
  outlineClassName: string;
  name: string;
  role: string;
  bio: string;
  linkedIn: {
    href: string;
    children: string;
  };
};

const PEOPLE: Person[] = [
  {
    avatar1x: avatarPeter1x,
    avatar2x: avatarPeter2x,
    outlineClassName: 'outline-[#30C594]',
    name: 'Peter Coles',
    role: 'Founder',
    bio: `Peter is a software engineer with nearly 20 years of experience. In 2011, his team at the startup Hunch was acquired by eBay and seeded eBay's NYC office. A natural problem-solver with a relentless drive to understand how things work, Peter has since become a serial founder in not only the screenshot space, but also fitness tech. In the case of GoFullPage, Peter’s vision was to create a simple yet powerful solution for users needing high-quality full-page screenshots. You can read more about Peter’s journey and the story behind GoFullPage in <a href="https://mrcoles.com/full-page-screen-capture-chrome-extension/" target="_blank" rel="noopener noreferrer">this blog post</a> from 2012. Peter holds a Bachelor of Science in Computer Science and Engineering from the Massachusetts Institute of Technology.`,
    linkedIn: {
      href: 'https://www.linkedin.com/in/peter-coles/',
      children: 'Peter’s LinkedIn',
    },
  },
  {
    avatar1x: avatarClaire1x,
    avatar2x: avatarClaire2x,
    outlineClassName: 'outline-[#35B6E6]',
    name: 'Claire Kao',
    role: 'COO',
    bio: 'Claire joined GoFullPage in 2018 and now directs all operations. Before GoFullPage, she worked as a construction manager on the 3 World Trade Center project in New York City. Claire brings valuable skills from the construction site: whether building a skyscraper based on architectural drawings or releasing a new feature to 8 million users, her work has always involved bringing abstract designs to life and for use on a large scale. A lover of movies, art, and images in general, she relies on and values screenshots as visual currency in the digital age. Claire holds a Bachelor of Science in Civil Engineering and a Master of Business Administration, both from Columbia University.',
    linkedIn: {
      href: 'https://www.linkedin.com/in/claire-kao/',
      children: 'Claire’s LinkedIn',
    },
  },
];
