import React from 'react';
import { Redirect } from 'react-router-dom';
import Members from '../components/EnterpriseAccountMembers';
import Overview from '../components/EnterpriseAccountOverview';
import Payment from '../components/EnterpriseAccountPayments';
import { EnterpriseDashboardLayout } from '../components/templates/EnterpriseDashboardLayout';
import { useAccountContext } from '../context/AccountContext';

const EnterpriseAccountPage: React.FC = () => {
  const { account, organization, organizationError, loading } =
    useAccountContext();
  const signedOut = !loading && !account;
  const isNotAnAdmin = !loading && !organization;

  return (signedOut || isNotAnAdmin) && !organizationError ? (
    <Redirect to="/account" />
  ) : (
    <EnterpriseDashboardLayout
      routes={[
        { path: '/overview', label: 'Overview', content: Overview },
        { path: '/members', label: 'Manage members', content: Members },
        { path: '/payment', label: 'Payment history', content: Payment },
      ]}
    />
  );
};

export default EnterpriseAccountPage;
