import classNames from 'classnames';
import jump from 'jump.js';
import { FunctionComponent, useEffect, useRef } from 'react';

import { HEADER_OFFSET } from '../constants';
import { getReportLink } from '../utils/reporturl-util';

import './ErrorMessage.scss';

export const ERR_CODE_LOGIN = 'login';

export type ErrorMessageData = {
  userId?: string;
  code: string;
  error: any; // TODO(Error-type)?
};

type ErrorMessageProps = {
  error?: string;
  errorData?: ErrorMessageData;
  card?: boolean;
  scrollIntoView?: boolean;
};

const ErrorMessage: FunctionComponent<ErrorMessageProps> = ({
  error,
  errorData,
  card,
  scrollIntoView,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current && scrollIntoView) {
      jump(ref.current, {
        duration: 200,
        offset: -HEADER_OFFSET,
      });
    }
  }, [ref.current]);

  const className = classNames('error-message', { 'card-width': card });
  let extraElt;

  if (errorData) {
    const { userId, error: errorObj, code: errorCode } = errorData;
    switch (errorCode) {
      case ERR_CODE_LOGIN: {
        const reportLink = getReportLink(
          window.location.href,
          errorObj,
          userId,
        );
        extraElt = (
          <div className="pt-5">
            See{' '}
            <a
              className="text-inherit underline hover:text-inherit"
              target="_blank"
              rel="noopener noreferrer"
              href="/faq#help-i-cant-log-in"
            >
              common issues
            </a>{' '}
            or{' '}
            <a
              className="text-inherit underline hover:text-inherit"
              target="_blank"
              rel="noopener noreferrer"
              href={reportLink}
            >
              contact us
            </a>{' '}
            for help logging in.
          </div>
        );
        break;
      }
    }
  }

  return (
    <div ref={ref} className={className}>
      {error}
      {extraElt}
    </div>
  );
};

ErrorMessage.displayName = 'ErrorMessage';

export default ErrorMessage;
