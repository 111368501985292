import { useCallback, useMemo, useState } from 'react';
import { performChangeEmail } from '../api';
import { useUserProvider } from '../utils/user-provider';
import {
  CardSection,
  Form,
  FormBody,
  FormButtons,
  Input,
  Label,
  PrimaryButton,
} from './Base';
import { connectGTM } from './GTMListener';

const panelPage = {
  NA: null,
  UPDATE: 'update',
  COMPLETE: 'verify',
};

/**
 * Renders the main panel once the user is signed in.
 * It also supports updating and verifying an email.
 */
export default function AccountPanel({
  user,
  username,
  canShowUpdate, // optional boolean
  children,
  heading, //optional ReactNode
}) {
  const isSocialAccount = !!useUserProvider(user);

  const [page, setPage] = useState(panelPage.NA);
  const setDefaultPage = useCallback(() => setPage(panelPage.NA), []);
  const setUpdatePage = useCallback(() => setPage(panelPage.UPDATE), []);
  const setCompletePage = useCallback(() => setPage(panelPage.COMPLETE), []);

  const title = useMemo(
    () =>
      ({
        [panelPage.NA]: `Welcome ${username}`,
        [panelPage.UPDATE]: 'New email',
        [panelPage.COMPLETE]: 'Email updated!',
      })[page || panelPage.NA],
    [page, username],
  );

  const titleButton = useMemo(() => {
    if (canShowUpdate === false) {
      return null;
    }

    if (isSocialAccount) return null; // Social accounts can't update their emails

    // (hide) button
    if (page === panelPage.UPDATE)
      return <HideUpdateButton onClick={setDefaultPage} />;

    // (hide) button but refresh page so it updates email, verify status, etc
    if (page === panelPage.COMPLETE) return null;

    // (update) button
    return <EmailUpdateButton onClick={setUpdatePage} />;
  }, [
    user,
    isSocialAccount,
    page,
    setDefaultPage,
    setUpdatePage,
    setCompletePage,
    canShowUpdate,
  ]);

  return (
    <CardSection>
      {heading}
      <h3>
        {title} {titleButton}
      </h3>
      {page === panelPage.UPDATE && (
        <ChangeEmailForm user={user} onSuccess={setCompletePage} />
      )}
      {page === panelPage.COMPLETE && (
        <ConfirmEmailUpdatedForm user={user} onSuccess={refreshPage} />
      )}
      {page === panelPage.NA && children}
    </CardSection>
  );
}

/** Displays a text link '(Update)' if the current user account can be updated (i.e. for non-social accounts) */
function EmailUpdateButton({ onClick }) {
  return (
    <a href="#" onClick={preventDefaultWrap(onClick)}>
      (update)
    </a>
  );
}

/** Displays a text link '(Hide)'. */
function HideUpdateButton({ onClick }) {
  return (
    <a href="#" onClick={preventDefaultWrap(onClick)}>
      (hide)
    </a>
  );
}

const SubmitState = {
  idle: 0,
  submitting: 1,
  complete: 2,
  error: 3,
};

const ChangeEmailForm = connectGTM(function ChangeEmailForm({
  onSuccess,
  dataLayerPush,
}) {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState();
  const [submitState, setSubmitState] = useState(SubmitState.idle);

  const isError = submitState === SubmitState.error;
  const isSubmitting = submitState === SubmitState.submitting;

  const updateEmail = useCallback(() => {
    setErrorMessage(undefined);
    setSubmitState(SubmitState.submitting);
    performChangeEmail(email)
      .then(() => {
        setSubmitState(SubmitState.complete);
        onSuccess();
        dataLayerPush({
          event: 'gaEvent',
          event_category: 'Account',
          event_action: 'changeEmail',
        });
      })
      .catch((resp) => {
        setSubmitState(SubmitState.error);
        const error = resp.response?.data?.error;
        if (error) setErrorMessage(error);
      });
  }, [email]);

  return (
    <div>
      <Form onSubmit={preventDefaultWrap(updateEmail)} stacked>
        <FormBody>
          <Label htmlFor="email" style={{ textAlign: 'left' }}>
            Email
          </Label>
          <Input
            name="email"
            type="email"
            placeholder="you@example.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ width: '100%' }}
          />
        </FormBody>
        {isError && (
          <p style={{ color: 'red', textAlign: 'center' }}>
            {errorMessage || 'An error occurred!'}
          </p>
        )}
        <FormButtons>
          <PrimaryButton type="submit" size="small" disabled={isSubmitting}>
            {isSubmitting ? 'Updating…' : 'Update'}
          </PrimaryButton>
        </FormButtons>
      </Form>
    </div>
  );
});

function ConfirmEmailUpdatedForm({ onSuccess }) {
  return (
    <div>
      <p style={{ textAlign: 'center' }}>
        Your email address was updated successfully. Please check your email.
      </p>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <PrimaryButton type="submit" size="small" onClick={onSuccess}>
          Go back
        </PrimaryButton>
      </div>
    </div>
  );
}

/**
 * Returns a function wrapper that calls 'prevent default' on the event.
 * Useful for treating links as buttons for example.
 */
function preventDefaultWrap(callback) {
  return (e) => {
    e.preventDefault();
    return callback();
  };
}

function refreshPage() {
  window.location.reload();
}
