import React, { FunctionComponent } from 'react';

import { Auth } from '@aws-amplify/auth';

import { handleSignInUser, handleSignOutUser } from './AuthProvider';

export const SignInButton = ({ children, ...props }) => {
  return (
    <button {...props} onClick={handleSignInUser}>
      {children || 'Sign in'}
    </button>
  );
};

export const SignOutButton = ({ children, ...props }) => {
  return (
    <button {...props} onClick={handleSignOutUser}>
      {children || 'Sign out'}
    </button>
  );
};
