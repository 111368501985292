import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { extractProps } from '../../utils/props-util';

import './BTFArrow.scss';

const DELAY = 5000;

export default class BTFArrow extends Component {
  static propTypes = {
    className: PropTypes.string,
    animate: PropTypes.bool.isRequired,
    fill: PropTypes.string.isRequired,
  };

  static defaultProps = {
    animate: false,
    fill: '#666666',
  };

  constructor(props) {
    super(props);
    this.state = { showAnimateClass: false };
  }

  componentDidMount() {
    this._mounted = true;
    this.intervalId = window.setInterval(() => {
      if (this._mounted && this.props.animate) {
        this.setState({ showAnimateClass: true });
        window.setTimeout(() => {
          this._mounted && this.setState({ showAnimateClass: false });
        }, DELAY - 1000);
      }
    }, DELAY);
  }

  componentWillUnmount() {
    this._mounted = false;
    window.clearInterval(this.intervalId);
  }

  render() {
    const { spreadProps, customProps } = extractProps(this.props, [
      'animate',
      'fill',
    ]);
    const { animate, fill } = customProps;
    const { showAnimateClass } = this.state;

    const style = this.props.style || {};
    if (spreadProps.onClick && animate) {
      style.cursor = 'pointer';
    }

    return (
      <svg
        {...spreadProps}
        className={classNames('btfarrow', this.props.className, {
          bounce: animate && showAnimateClass,
        })}
        style={style}
        width="8"
        height="41"
        viewBox="0 0 8 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.64644 40.3536C3.84171 40.5488 4.15829 40.5488 4.35355 40.3536L7.53553 37.1716C7.73079 36.9763 7.73079 36.6597 7.53553 36.4645C7.34027 36.2692 7.02369 36.2692 6.82843 36.4645L4 39.2929L1.17157 36.4645C0.976309 36.2692 0.659727 36.2692 0.464465 36.4645C0.269202 36.6597 0.269202 36.9763 0.464464 37.1716L3.64644 40.3536ZM3.5 -2.18557e-08L3.5 40L4.5 40L4.5 2.18557e-08L3.5 -2.18557e-08Z"
          fill={fill}
        />
      </svg>
    );
  }
}
