import { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Container, Section } from '../components/Base';
import { getTitle } from '../utils/document';

type VariableLink =
  | { text: string; type: 'external'; href: string }
  | { text: string; type: 'internal'; to: string };

type ContactItem = {
  emoji: string;
  title: string;
  blurb: string;
  links: VariableLink[];
};

type Content = {
  items: ContactItem[];
};

const CONTENT: Content = {
  items: [
    {
      emoji: '📰',
      title: 'Press & Blog',
      blurb:
        'Explore media coverage of GoFullPage from trusted outlets. Visit our blog for detailed use cases, expert tips, and helpful tricks!',
      links: [
        {
          text: 'Visit Press page',
          type: 'internal',
          to: '/press',
        },
        {
          text: 'Visit GoFullPage Blog',
          type: 'external',
          href: 'https://blog.gofullpage.com/',
        },
      ],
    },
    {
      emoji: 'ℹ️',
      title: 'Help & Support',
      blurb:
        'Having trouble with your captures or need account help? Visit our FAQs or reach out to us to get back on track with seamless screenshots.',
      links: [
        { text: 'Visit FAQs Page', type: 'internal', to: '/faq' },
        {
          text: 'Submit a support ticket',
          type: 'external',
          href: 'https://screencapture.mrcoles.com/',
        },
      ],
    },
    {
      emoji: '🛒',
      title: 'Sales',
      blurb:
        'Connect with our Sales team to get GoFullPage Enterprise for your team. Or start your 7-day free trial of GoFullPage Premium today!',
      links: [
        { text: 'Contact sales', type: 'internal', to: '/sales' },
        { text: 'Go Premium', type: 'internal', to: '/premium' },
      ],
    },
  ],
};

type ContactUsPageProps = {};

const ContactUsPage: FunctionComponent<ContactUsPageProps> = () => {
  return (
    <div>
      <Helmet>
        <title>{getTitle('Contact us')}</title>
      </Helmet>
      <Section className="first-sec mb-20">
        <Container>
          <div className="gfp-row">
            <div className="col-8 offset-2 mb-20">
              <h1 className="mb-0">Contact us</h1>
              <div>and other resources to learn more about GoFullPage</div>
            </div>
          </div>
          <div className="space-y-10 text-left lg:flex lg:items-stretch lg:gap-10 lg:space-y-0">
            {CONTENT.items.map((item, i) => (
              <div
                className="flex flex-col gap-2 rounded-lg bg-white p-5 lg:basis-full"
                key={i}
              >
                <div className="text-xl font-bold md:text-3xl md:font-medium">
                  {item.emoji} {item.title}
                </div>
                <div className="text-pretty text-sm">{item.blurb}</div>
                <ul className="m-0 mt-auto pt-2">
                  {item.links.map((link, j) => (
                    <li key={j}>
                      {link.type === 'internal' ? (
                        <Link to={link.to} className="font-bold leading-8">
                          {link.text}
                        </Link>
                      ) : (
                        <a href={link.href} className="font-bold leading-8">
                          {link.text}
                        </a>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </Container>
      </Section>
    </div>
  );
};

ContactUsPage.displayName = 'ContactUsPage';

export default ContactUsPage;
