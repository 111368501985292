import classNames from 'classnames';
import { FunctionComponent } from 'react';

type ToggleProps = Pick<
  JSX.IntrinsicElements['input'],
  'checked' | 'className' | 'onBlur' | 'onChange' | 'onClick'
>;

const Toggle: FunctionComponent<ToggleProps> = ({
  checked,
  className,
  ...props
}) => {
  return (
    <label className={classNames(className, 'cursor-pointer')}>
      <input
        type="checkbox"
        checked={checked}
        className="absolute h-0 w-0 appearance-none"
        {...props}
      />
      <div
        className={classNames(
          'relative h-[20px] w-[32px] rounded-full p-[2px] transition-all duration-[.4s]',
          checked ? 'bg-[#30C594]' : 'bg-[#C73131]',
        )}
      >
        <div
          className={classNames(
            'absolute h-[16px] w-[16px] rounded-full border border-solid border-gray-200 bg-white shadow-sm shadow-gray-700 transition-all duration-[.4s]',
            checked ? 'left-[14px]' : 'left-[2px]',
          )}
        ></div>
      </div>
    </label>
  );
};

Toggle.displayName = 'Toggle';

export default Toggle;
