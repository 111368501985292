type ThemeVariant = 'main' | 'enterprise-dash';

/**
 * Util function for identifying which
 * theme variant we should be rendering...
 * TODO - bake this more directly into
 * the components instead of looking at pathname
 */
export const getThemeVariant = (pathname: string): ThemeVariant =>
  pathname.startsWith('/enterprise/') ? 'enterprise-dash' : 'main';
