import { Container, PrimaryButton, Section } from '../components/Base';
import {
  LOGO_BUILTIN,
  LOGO_BUSINESSINSIDER,
  LOGO_FASTCOMPANY,
  LOGO_GIZMODO,
  LOGO_GUIDINGTECHMEDIA,
  LOGO_LIFEHACKER,
  LOGO_MAKEUSEOF,
  LOGO_MINT,
  LOGO_PCMAG,
  LOGO_SEARCHENGINELAND,
  LOGO_TECHRADAR,
  LOGO_USATODAY,
  LOGO_ZDNET,
} from '../components/Logos';
import ResponsiveImg from '../components/ResponsiveImage';
import './PressPage.scss';

const DATA = [
  {
    logo: LOGO_MINT,
    name: 'Mint',
    date: 'Aug 7, 2024',
    caption: '10 Google Chrome extensions to help your work and productivity',
    url: 'https://www.livemint.com/mint-lounge/business-of-life/google-chrome-extensions-for-work-productivity-technology-11722954112128.html',
  },
  {
    logo: LOGO_MAKEUSEOF,
    name: 'MakeUseOf',
    date: 'Aug 17, 2023',
    caption: 'The 7 Best Screenshot Add-ons for Vivaldi',
    url: 'https://www.makeuseof.com/vivaldi-screenshot-add-ons/',
  },
  {
    logo: LOGO_SEARCHENGINELAND,
    name: 'SearchEngineLand',
    date: 'Jul 19, 2023',
    caption: '5 free SEO tools and plugins to try',
    url: 'https://searchengineland.com/free-seo-tools-plugins-429536',
  },
  {
    logo: LOGO_GUIDINGTECHMEDIA,
    name: 'GuidingTech',
    date: 'June 27, 2023',
    caption: 'How to Take a Full Page Screenshot on Microsoft Edge',
    url: 'https://www.guidingtech.com/take-full-page-screenshots-in-microsoft-edge/',
  },
  // {
  //   logo: LOGO_MAKEUSEOF,
  //   name: 'MakeUseOf',
  //   date: 'Jan 6, 2022',
  //   caption: '3 Chrome Extensions That Let You Screenshot Streaming Services',
  //   url: 'https://www.makeuseof.com/chrome-extensions-screenshot-streaming-services/',
  // },
  {
    logo: LOGO_ZDNET,
    name: 'ZDNet',
    date: 'Feb 16, 2023',
    caption:
      'How to take a full-page screenshot in Google Chrome: Four different ways',
    url: 'https://www.zdnet.com/article/how-to-take-a-full-page-screenshot-in-google-chrome/',
  },
  {
    logo: LOGO_FASTCOMPANY,
    name: 'FastCompany',
    date: 'May 9, 2022',
    caption: 'These 4 free Chrome extensions each do one thing incredibly well',
    url: 'https://www.fastcompany.com/90749619/these-4-free-chrome-extensions-each-do-one-thing-incredibly-well',
  },
  {
    logo: LOGO_MAKEUSEOF,
    name: 'Make Use Of',
    date: 'May 8, 2022',
    caption: 'How to Take a Full-Page Screenshot in Chrome and Firefox',
    url: 'https://www.makeuseof.com/how-to-full-page-screenshot-chrome-firefox/',
  },
  {
    logo: LOGO_BUILTIN,
    name: 'builtin',
    date: 'Feb 8, 2022',
    caption: 'The 13 Best Chrome Extensions for Developers',
    url: 'https://builtin.com/software-engineering-perspectives/chrome-developer-tools',
  },
  {
    logo: LOGO_TECHRADAR,
    name: 'techradar',
    date: 'Feb 1, 2022',
    caption:
      'The best Google Chrome extensions in 2022: do more with your browser',
    url: 'https://www.techradar.com/news/the-best-google-chrome-extensions',
  },
  {
    logo: LOGO_BUSINESSINSIDER,
    name: 'Business Insider',
    date: 'Jan 28, 2022',
    caption: 'The 15 best Chrome extensions for improving your productivity',
    url: 'https://www.businessinsider.com/chrome-extensions',
  },
  {
    logo: LOGO_GIZMODO,
    name: 'Gizmodo',
    date: 'Jan 13, 2022',
    caption: 'How to Take Scrolling Screenshots on Any Device',
    url: 'https://gizmodo.com/how-to-take-scrolling-screenshots-on-any-device-1848335524',
  },
  {
    logo: LOGO_PCMAG,
    name: 'PCMag',
    date: 'Mar 29. 2021',
    caption: 'The Best Free Google Chrome Extensions',
    url: 'https://www.pcmag.com/news/the-100-best-free-google-chrome-extensions',
  },
  {
    logo: LOGO_USATODAY,
    name: 'USA Today',
    date: 'Mar 21, 2021',
    caption: '5 smart Windows 10 tricks to save time and space',
    url: 'https://www.usatoday.com/story/tech/columnist/komando/2021/03/21/5-handy-windows-10-shortcuts-help-save-time-and-space/4741428001/',
  },
  {
    logo: LOGO_LIFEHACKER,
    name: 'lifehacker',
    date: 'Jan 2, 2019',
    caption: 'How to Fix Full-Page Browser Screenshots That Render Black',
    url: 'https://lifehacker.com/how-to-fix-full-page-browser-screenshots-that-render-bl-1831429544',
  },
];

const PressPage = ({}) => {
  return (
    <div className="presspage">
      <Section className="hero-sec first-sec">
        <Container>
          <h1>Press</h1>
          <ul className="grid">
            {DATA.map((pub, i) => (
              <li key={i}>
                <a href={pub.url} className="press-item">
                  <ResponsiveImg
                    {...pub.logo}
                    width={pub.logo.width / 2}
                    height={pub.logo.height / 2}
                    src={pub.logo.src2x}
                    src2x={null}
                  />
                  <strong>{pub.date}</strong>
                  <p>{pub.caption}</p>
                </a>
              </li>
            ))}
          </ul>
        </Container>
      </Section>
      <Section>
        <Container>
          <p>Try it for yourself…</p>
          <div className="next-buttons">
            <PrimaryButton className="btn-standout" type="route" to="/">
              Get the extension
            </PrimaryButton>
            <PrimaryButton
              className="btn-standout btn-convert"
              type="route"
              to="/premium"
            >
              Go premium
            </PrimaryButton>
          </div>
        </Container>
      </Section>
    </div>
  );
};

// PressPage.propTypes = Object.assign({}, EXTENSION_PROPS);

export default PressPage;
