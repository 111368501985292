import PropTypes from 'prop-types';
import { Component } from 'react';
import { Link } from 'react-router-dom';

import { Container, Section } from '../components/Base';
import { connectGTM } from '../components/GTMListener';
import Video from '../components/Video';
import VideoYoutube from '../components/VideoYoutube';
import { CAPTURE_VIDEO, EDITOR_VIDEO } from '../constants';
import { getTitle } from '../utils/document';

import { Helmet } from 'react-helmet';
import './DemoPage.scss';

const VIDEOS = [
  {
    title: 'Capture Example',
    slug: 'capture',
    props: CAPTURE_VIDEO,
  },
  {
    title: 'Editor Example',
    slug: 'editor',
    props: EDITOR_VIDEO,
  },
];

class DemoPage extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired, // via react-router
  };

  // Render
  render() {
    const { history } = this.props;

    let slug = history.location.hash.substring(1);
    const curVideo = VIDEOS.find((v) => v.slug === slug) || VIDEOS[0];
    slug = curVideo.slug;

    return (
      <div className="premium">
        <Helmet>
          <title>{getTitle('Demo Videos')}</title>
        </Helmet>
        <Section className="first-sec">
          <Container>
            <div className="gfp-row">
              <div className="col-8 offset-2">
                <h1>Demo Videos</h1>
              </div>
            </div>
          </Container>
        </Section>
        <Section className="blue-sec">
          <Container>
            <ul className="divided-elts large">
              {VIDEOS.map((v) => (
                <li key={v.slug}>
                  {v.slug === slug ? (
                    <span className="strong">{v.title}</span>
                  ) : (
                    <Link to={{ hash: `#${v.slug}` }}>{v.title}</Link>
                  )}
                </li>
              ))}
            </ul>
            <br />
            {curVideo.props.youtube ? (
              <VideoYoutube
                key={curVideo.slug}
                width={curVideo.props.width}
                height={curVideo.props.height}
                url={curVideo.props.youtube}
              />
            ) : (
              <Video
                key={curVideo.slug}
                {...curVideo.props}
                autoPlay={false}
                controls={true}
                loop={false}
              />
            )}
            <span>
              <br />
              (best viewed in full-screen mode)
            </span>
          </Container>
        </Section>
      </div>
    );
  }
}

export default connectGTM(DemoPage);
