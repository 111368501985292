import { tr } from '../utils/e7n-util';

const data = {
  benefits: [
    {
      title: tr('Fast & Simple'),
      text: tr('one click to capture a full webpage'),
    },
    {
      title: tr('Privacy'),
      text: tr('no extra permissions are required to install'),
    },
    {
      title: tr('Accuracy'),
      text: tr('the most advanced capturing of difficult pages'),
    },
    {
      title: tr('Trusted'),
      text: tr('the highest rated screenshot tool with {STATS.users} users'),
    },
    {
      title: tr('More'),
      text: tr('download as PNG, JPG, PDF, etc…'),
    },
    {
      title: tr('Edit'),
      text: tr('Add shapes, emojis, annotations, and more!'),
      linkText: tr('Go Premium!'),
      type: tr('premium'),
    },
  ],
  reviews: [
    {
      name: 'Akash D.',
      date: 'Nov 16, 2023',
      text: "Loved it and loving it. As a UX designer, taking screenshots of long pages is really helpful. But, here's the ,kicker - it even scrolls the correct parts of certain websites like GMail where I'd only like it to scroll the open email instead of scrolling everything. I don't know how y'all do this or if it's luck but it's amazing. It literally made me write this review, thank you.",
      img: '/static/reviews/ad.jpg',
      img2x: '/static/reviews/ad@2x.jpg',
    },
    {
      name: 'Rebecca R.',
      date: 'Nov 12, 2023',
      text: 'A Straight Forward App That Works Great! This app does what it says and says what it does with no extra distractions. LOVE IT! Easy to set up and to use. Saves me so much time from prior cutting and pasting of information into another document just so I could have all the information on a page below the fold. Thank you to the developer - from those of us who like simple.',
      img: '/static/reviews/rr.jpg',
      img2x: '/static/reviews/rr@2x.jpg',
    },
    {
      name: 'Neal B.',
      date: 'May 11, 2022',
      text: "We’ve used several third-party, stand alone screen cap applications but GoFullPage is the simplest to use, integrates easily into our workflow, and delivers exceptional results every time. We take screen cap pictures of EVERYTHING now and it's all thanks to this single extension.",
      img: '/static/reviews/nb.jpg',
      img2x: '/static/reviews/nb@2x.jpg',
    },
    // {
    //   name: 'Melissa W.',
    //   date: 'Dec 2, 2021',
    //   text: "I am a Sales Engineer for a software company and this tool is so useful when seeking to quickly create a full length snapshot of the custom views we've built in our software so we can share them with customers in emails. It helps us communicate the full page value of our software quickly and easily. Easy to download and use so can promote it across the team. Great stuff.",
    //   img: '/static/reviews/mw.jpg',
    //   img2x: '/static/reviews/mw@2x.jpg',
    // },
    // {
    //   name: 'John A.',
    //   date: 'July 9, 2021',
    //   text: "Many times, I find that Chrome extensions aren't very well designed and do either more or less than what is described or what I need it to do.  GoFullPage is by far, the best extension I have and is the one I use most often.  It does exactly what it's designed to do and does it right every time.  I can't believe I'm giving a review of an extension, but for me, GFP is worth the time to recommend this very useful tool.",
    //   img: '/static/reviews/ja.jpg',
    //   img2x: '/static/reviews/ja@2x.jpg',
    // },
    {
      name: 'Sean G.',
      date: 'May 28, 2021',
      text: 'Fantastic app and works better than most paid software to do the same task. Probably even more surprisingly is that once when i had an issue with a reaaaaaaallyyy long page i submitted a bug report to them and they got back to me within 24 hours with a solution!',
      img: '/static/reviews/sg.jpg',
      img2x: '/static/reviews/sg@2x.jpg',
    },
    // {
    //   name: 'Leisl B.',
    //   date: 'April 22, 2021',
    //   text: "I use this all the time to take pictures of websites and sales pages. I use the pro version to audit clients' sales pages and LOVE it. It's super professional, easy to use, and I recommend it to anyone who needs to take pictures of full websites or images.",
    //   img: '/static/reviews/lb.jpg',
    //   img2x: '/static/reviews/lb@2x.jpg',
    // },
    {
      name: 'Areeta M.',
      date: 'Apr 12, 2021',
      text: "A life saver and game changer! Been using this for about a year and I've never had any problems. I use it almost every day as a web designer and it's never let me down. Don't know where I would be without it! Thank you!",
      img: '/static/reviews/am.jpg',
      img2x: '/static/reviews/am@2x.jpg',
    },
    // {
    //   name: 'Soheil A.',
    //   date: 'Mar 24, 2021',
    //   text: 'Tried many many extension. This one is the best. Easy to copy to clipboard and then paste into Miro, Framer, Figma. It also automatically reduces the size so it fit in Google drive. Well done 🎉',
    //   img: '/static/reviews/soa.jpg',
    //   img2x: '/static/reviews/soa@2x.jpg',
    // },
    {
      name: 'Gabriel R.',
      date: 'Dec 7, 2020',
      text: "I've been using this extension for a couple of years now, and I love it. At work, I do a lot of competitor research. Being able to capture a full screen in a single click saves me a ton of time and hassle. I can line up the page that I want, click, change tab, click, change tab... Then download. It's great!",
      img: '/static/reviews/gr.jpg',
      img2x: '/static/reviews/gr@2x.jpg',
    },
  ],
};

export default data;
