import { FunctionComponent } from 'react';

import AirtableForm from '../components/AirtableForm';
import { Container, PrimaryButton, Section } from '../components/Base';
import { getTitle } from '../utils/document';

import { Helmet } from 'react-helmet';

type EnterprisePageProps = {};

const SalesPage: FunctionComponent<EnterprisePageProps> = () => {
  return (
    <div className="premium">
      <Helmet>
        <title>{getTitle('Sales')}</title>
      </Helmet>
      <Section className="first-sec">
        <Container>
          <h1 className="mb-10">Contact Sales</h1>
          <div className="mx-auto mt-5 min-h-[1100px] max-w-3xl rounded-md bg-gray-100">
            <AirtableForm />
          </div>
          <div className="large mx-auto max-w-[760px] py-10 text-center">
            Trusted by leading institutions in <strong>government</strong>,{' '}
            <strong>legal and financial services</strong>,{' '}
            <strong>insurance</strong>, and <strong>higher education</strong>{' '}
            across the US and internationally.
          </div>
          <p>
            <PrimaryButton
              className="btn-standout btn-convert min-w-[220px] !px-10"
              type="route" // {Button.TYPE_ROUTE}
              to="/enterprise"
              size="medium"
            >
              Learn more
            </PrimaryButton>
          </p>
          <div className="large mx-auto max-w-[760px] pb-5 pt-10 text-center">
            Looking for support instead?
          </div>
          <p>
            <PrimaryButton
              className="btn-standout min-w-[220px] !px-10"
              type="link" // {Button.TYPE_LINK}
              href="https://screencapture.mrcoles.com/"
              target="_blank"
              rel="noopener noreferrer"
              size="medium"
            >
              Submit a support ticket
            </PrimaryButton>
          </p>
        </Container>
      </Section>
    </div>
  );
};

SalesPage.displayName = 'SalesPage';

export default SalesPage;
