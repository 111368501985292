import PropTypes from 'prop-types';
import React from 'react';

import './PushdownMessage.scss';

const PushdownMessage = ({ text, url }) => {
  const eltProps = { className: 'pushdown-msg' };
  const innerElt = text;

  return innerElt ? (
    url ? (
      <a href={url} {...eltProps}>
        {innerElt}
      </a>
    ) : (
      <div {...eltProps}>{innerElt}</div>
    )
  ) : null;
};

PushdownMessage.propTypes = {
  text: PropTypes.any,
  url: PropTypes.string,
};

export default PushdownMessage;
