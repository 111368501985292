import { Severity } from '../components/molecules/alert';
import React, { useState } from 'react';
import { noop } from '../utils/utils';

type Alert = {
  title?: string;
  message: string;
  severity?: Severity;
};

type AlertManagerProps = {
  alert?: Alert;
  setAlert: (alert: Alert | undefined) => void;
};

export const AlertManager = React.createContext<AlertManagerProps>({
  setAlert: noop,
});

export const AlertManagerProvider: React.FC = ({ children }) => {
  const [alert, setAlert] = useState<Alert>();

  return (
    <AlertManager.Provider value={{ alert, setAlert }}>
      {children}
    </AlertManager.Provider>
  );
};
