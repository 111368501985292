import React from 'react';
import { ENTERPRISE_SUPPORT_EMAIL } from '../../constants';
import { Organization } from '../../types/Account';

type Props = {
  email?: string;
  subject?: string;
  organization?: Organization;
};
export const EnterpriseSupportEmailLink: React.FC<Props> = ({
  email = ENTERPRISE_SUPPORT_EMAIL,
  subject,
  organization,
}) => {
  let url = `mailto:${email}`;

  if (!subject && organization) {
    subject = organization.name
      ? `Enterprise question for ${organization?.name}`
      : `Enterprise question`;
  }

  if (subject) {
    url += `?subject=${encodeURIComponent(subject)}`;
  }

  return (
    <a href={url} target="_blank" rel="noreferrer noopener">
      {email}
    </a>
  );
};
