import { FunctionComponent, useEffect, useRef, useState } from 'react';

import AirtableForm from '../components/AirtableForm';
import { Container, PrimaryButton, Section } from '../components/Base';
import BlogPostLink from '../components/BlogPostLink';
import Emojis, { TYPE_LEGAL } from '../components/Emojis';
import SupportsFeature from '../components/SupportsFeature';
import { getTitle } from '../utils/document';

import { Helmet } from 'react-helmet';
import page from './EnterprisePageData';

const leeBSrc = require('../../assets/img/leeb.jpg') as string;

type EnterprisePageProps = {};

const EnterprisePage: FunctionComponent<EnterprisePageProps> = () => {
  useEffect(() => {
    // const prodDetail = getEcommProductDetail('Enterprise Page');
    // log(`ECOM PRODUCT VIEW: ${JSON.stringify(prodDetail)}`);
    // this.props.dataLayerPush(prodDetail);
    // TODO - move all these to Helmet instead...
  }, []);

  const [showForm, setShowForm] = useState(false);

  // TODO - on click?
  // const ecommAddToCart = getEcommAddToCart('Enterprise Page');
  // log(`ECOM ADD TO CART: ${JSON.stringify(ecommAddToCart)}`);
  // this.props.dataLayerPush(ecommAddToCart);

  const scrollToRef = useRef<HTMLParagraphElement>(null);

  const highlightClassName = 'bg-sky-100';

  return (
    <div className="premium">
      <Helmet>
        <title>{getTitle('GoFullPage Enterprise')}</title>
      </Helmet>
      <Section className="first-sec">
        <Container>
          <div className="gfp-row mb-8">
            <div className="col-8 offset-2">
              <h1>
                GoFullPage Enterprise
                <br />
                <strong>best-in-class</strong> screenshots for{' '}
                <span className="nowrap">
                  <strong>best-in-class</strong> firms
                </span>
              </h1>
              <Emojis type={TYPE_LEGAL} space={true} />
              <p className="large mx-auto max-w-2xl" ref={scrollToRef}>
                Sign up for the best screenshots for your legal, compliance, or
                archival needs—and easier user management for your organization.
              </p>
              {showForm ? (
                <AirtableForm />
              ) : (
                <p>
                  <PrimaryButton
                    className="btn-standout btn-convert !px-[60px]"
                    type="route" // {Button.TYPE_ROUTE}
                    to="#"
                    size="large"
                    onClick={(evt: React.MouseEvent) => {
                      evt.preventDefault();
                      setShowForm(true);
                    }}
                  >
                    Contact us
                  </PrimaryButton>
                </p>
              )}
              <p className="hero-info mx-auto max-w-3xl">
                The easiest, most pain-free way to capture a webpage, exactly as
                you see it: now supported for group accounts.
              </p>
            </div>
          </div>
          <div className="gfp-row mb-16">
            <div className="col-8 offset-2">
              <table className="features-table dark">
                <thead>
                  <tr>
                    <th className="name-col">Features</th>
                    <th>Premium</th>
                    <th className={highlightClassName + ' rounded-t-lg'}>
                      Enterprise
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {page.features.map((f) => (
                    <tr key={f.name}>
                      <td className="name-col">{f.name}</td>
                      <td>
                        <SupportsFeature supports={f.premium} />
                      </td>
                      <td className={highlightClassName}>
                        <SupportsFeature supports={f.enterprise} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="large mx-auto max-w-[760px] py-[40px] text-center">
            Trusted by leading institutions in <strong>government</strong>,{' '}
            <strong>legal and financial services</strong>,{' '}
            <strong>insurance</strong>, and <strong>higher education</strong>{' '}
            across the US and internationally.
          </div>
          <div className="pb-[80px] pt-[40px]">
            <div className="mx-auto max-w-[615px] space-y-[10px] rounded-[10px] border border-solid border-dim-e00 bg-white p-6 sm:flex sm:items-center sm:justify-center sm:gap-10 sm:space-y-0">
              <img
                src={leeBSrc}
                className="mx-auto block rounded-lg"
                alt="Lee B."
                width="121"
                height="121"
              />
              {/*
              Just started back at Jones Day after almost three years away. Different office in a different part of the country, but the same Jones Day culture with kind, friendly people. I feel very fortunate. The new tech is really exciting … especially add-on to Chrome for capturing websites (GoFullPage) … it’s a game changer. No more Snag-it ever! It even has smart page breaks. I’m posting my favorite Gene Autry song to commemorate my job change.
              */}
              <div className="msmall space-y-[10px] text-center sm:max-w-[360px] sm:text-left">
                <div>
                  The new tech is really exciting … especially the add-on to
                  Chrome for capturing websites (GoFullPage) … it’s a game
                  changer. It even has smart page breaks.
                </div>
                <div>
                  <a
                    href="https://www.linkedin.com/posts/activity-7204431489780875266-GHto/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Lee B., Paralegal
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div>
            <p className="hero-info mx-auto mb-[32px] max-w-3xl">
              See how our premium product currently supports legal and
              accounting uses on our blog:
            </p>
            <div>
              <div className="flex flex-col items-center justify-center space-y-[24px] sm:flex-row sm:items-stretch sm:space-x-[24px] sm:space-y-0">
                <BlogPostLink
                  variant="vertical"
                  title="How to Screenshot your Webpage for a Trademark"
                  description="The most pain-free, low-cost way of creating a specimen for your trademark submission!"
                  href="https://blog.gofullpage.com/2020/10/02/how-to-screenshot-your-webpage-for-a-trademark/"
                  image="/static/blog/2020-10-02-screenshot-webpage.jpg"
                />
                <BlogPostLink
                  variant="vertical"
                  title="Why the Most Effective Accounting Teams use GoFullPage"
                  description="Ensuring your accounting is always verifiable has never been easier or more reliable"
                  href="https://blog.gofullpage.com/2023/10/04/why-the-most-effective-accounting-teams-use-gofullpage/"
                  image="/static/blog/2023-10-04-screenshot-webpage.png"
                />
              </div>
              <p className="hero-info mx-auto mb-[24px] mt-[48px] max-w-[672px]">
                Get in touch to learn more about{' '}
                <span className="nowrap">GoFullPage Enterprise:</span>
              </p>
              <p>
                <PrimaryButton
                  className="btn-standout btn-convert"
                  type="route" // {Button.TYPE_ROUTE}
                  to="#"
                  size="medium"
                  onClick={(evt: React.MouseEvent) => {
                    evt.preventDefault();
                    setShowForm(true);
                    const elt = scrollToRef.current;
                    if (elt?.scrollIntoView) {
                      elt.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                      });
                    }
                  }}
                >
                  Request a demo
                </PrimaryButton>
              </p>
            </div>
          </div>
        </Container>
      </Section>
    </div>
  );
};

EnterprisePage.displayName = 'EnterprisePage';

export default EnterprisePage;
