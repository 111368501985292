import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { classes } from '../common/utils';

function styleElement(element: JSX.Element, classNames: string) {
  return React.cloneElement(element, {
    className: classes(element.props.className, classNames),
  });
}

export function useStyledElement(element: JSX.Element, classNames: string) {
  return useMemo(
    () => styleElement(element, classNames),
    [element, classNames],
  );
}

export function useStyledChildren(children: ReactNode, classNames: string) {
  return useMemo(
    () =>
      React.Children.map(children as any, (child: JSX.Element) =>
        styleElement(child, classNames),
      ),
    [children, classNames],
  );
}

export function useToggle(init: boolean): [boolean, () => void] {
  const [value, setValue] = useState(init);
  const toggleValue = useCallback(() => setValue((s) => !s), []);
  return [value, toggleValue];
}
