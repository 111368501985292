import classNames from 'classnames';
import { FunctionComponent } from 'react';

type LinkedInIconProps = {};

const LinkedInIcon: FunctionComponent<LinkedInIconProps> = () => {
  return (
    <svg
      width="32"
      height="28"
      viewBox="0 0 32 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* <rect width="32" height="28" rx="4" fill="#E5E7EB" /> */}
      <path
        className="fill-current"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7077 23V10.1801H6.26276V23H10.7077ZM8.48521 8.42973C10.0353 8.42973 11 7.4453 11 6.21506C10.9712 4.95712 10.0353 4 8.51462 4C6.99414 4 6 4.95712 6 6.21506C6 7.4453 6.96457 8.42973 8.45624 8.42973H8.48521Z"
        fill="#4B5563"
      />
      <path
        className="fill-current"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 23H17.2588V15.9067C17.2588 15.5271 17.2864 15.1479 17.3989 14.8764C17.7068 14.1179 18.4076 13.3324 19.584 13.3324C21.1251 13.3324 21.7416 14.4972 21.7416 16.2047V23H26V15.7169C26 11.8154 23.8989 10 21.097 10C18.7994 10 17.7908 11.273 17.2304 12.1402H17.2589V10.2981H13.0001C13.056 11.4899 13 23 13 23Z"
        fill="#4B5563"
      />
    </svg>
  );
};

LinkedInIcon.displayName = 'LinkedInIcon';

export default LinkedInIcon;

// ##

export const LinkedInIconLink: FunctionComponent<
  JSX.IntrinsicElements['a']
> = ({ children, className, ...props }) => {
  return (
    <a
      {...props}
      className={classNames(
        className,
        'h-[28px] w-8 rounded-md text-gray-400 hover:bg-gray-200 hover:text-gray-600',
      )}
    >
      <LinkedInIcon />
      <span className="sr-only">{children || 'LinkedIn'}</span>
    </a>
  );
};

LinkedInIconLink.displayName = 'LinkedInIconLink';
