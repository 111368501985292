import React, { useEffect } from 'react';

import { loadScript } from '../utils/load-script';

const SCRIPT_URL = 'https://static.airtable.com/js/embed/embed_snippet_v1.js';
const SCRIPT_ID = '_airtable_embed';

const AirtableForm = () => {
  useEffect(() => {
    loadScript(SCRIPT_ID, SCRIPT_URL);
  }, []);

  return (
    <iframe
      className="airtable-embed airtable-dynamic-height"
      src="https://airtable.com/embed/app0kqkfRWnxN4HCj/shr6jhBksXh4RuDhg?backgroundColor=red"
      frameBorder={0}
      width="100%"
      height="973"
      style={{ background: 'transparent', border: '1px solid #ccc' }}
    ></iframe>
  );
};

AirtableForm.displayBName = 'AirtableForm';

export default AirtableForm;
