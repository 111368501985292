// # UpdateCardWidget

import PropTypes from 'prop-types';
import { Component } from 'react';

import { log } from '../../logger';

import { ACCOUNT_TYPES } from '../../types/Account';
import ExpiredSubscription from './ExpiredSubscription';
import Loading from './Loading';
import NoCard from './NoCard';
import UpdateCard from './UpdateCard';

class UpdateCardWidget extends Component {
  static propTypes = {
    account: PropTypes.object.isRequired, // TODO - just specific vals?
    accountType: PropTypes.oneOf(ACCOUNT_TYPES),
    // TODO - see notes in UpdateCard about updateAccount vs updateOrganization
    updateAccount: PropTypes.func,
    updateOrganization: PropTypes.func,
    setError: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this._mounted = true;
  }

  // Life-cycle
  componentWillUnmount() {
    this._mounted = false;
  }

  // Render
  render() {
    const { account, setError } = this.props;

    let elt = null;

    log('[UpdateCardWidget] account?', account);

    if (!account) {
      // waiting to load card info
      elt = <Loading />;
    } else {
      // finished loading info...
      const { card, customerId, subscription } = account;

      if (!customerId || !subscription) {
        // does not have user sub (current or canceled)
        elt = <NoCard />;
      } else if (subscription.status === 'canceled') {
        // has user sub, but sub is status canceled
        elt = <ExpiredSubscription />;
      } else {
        // has user sub, and is not canceled
        elt = (
          <UpdateCard
            account={account}
            accountType={this.props.accountType}
            updateAccount={this.props.updateAccount}
            updateOrganization={this.props.updateOrganization}
            setError={setError}
            history={this.props.history}
          />
        );
      }
    }

    return elt;
  }
}

export default UpdateCardWidget;
