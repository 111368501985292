import { tr } from '../utils/e7n-util';

const data = {
  features: [
    {
      name: tr('Unlimited screenshots'),
      basic: true,
      premium: true,
    },
    {
      name: tr('Advanced scroll capture'),
      basic: true,
      premium: true,
    },
    {
      name: tr('Image cropping'),
      basic: false,
      premium: true,
    },
    {
      name: tr('URL and date/timestamp'),
      basic: false,
      premium: true,
    },
    {
      name: tr('Smart PDF page splitting'),
      basic: false,
      premium: true,
    },
    {
      name: tr('Annotations: blur, text, highlight'),
      basic: false,
      premium: true,
    },
    {
      name: tr('Priority customer support'),
      basic: false,
      premium: true,
    },
  ],
};

export default data;
