import { AxiosError } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { getOrganizationMembers } from '../api';
import { useAccountContext } from '../context/AccountContext';
import {
  OrganizationMember,
  OrganizationMemberStatuses,
} from '../types/Account';
import { useBoolean } from './useBoolean';

export const useEnterpriseAccountMembers = () => {
  const { organization } = useAccountContext();
  const [error, setError] = useState<Error | AxiosError>();
  const [loading, toggleLoading] = useBoolean(true);
  const [members, setMembers] = useState<OrganizationMember[]>([]);

  useEffect(() => {
    if (organization) {
      fetch().finally(toggleLoading);
    }
  }, [organization]);

  const fetch = () =>
    getOrganizationMembers(organization!.orgId)
      .then(setMembers)
      .catch(setError);

  const usedSeats = useMemo(() => getUsedSeatsCount(members), [members]);

  return { loading, members, refetch: fetch, error, usedSeats };
};

/**
 * count the # of used seats from a list of members
 */
const getUsedSeatsCount = (members: OrganizationMember[]) => {
  return members.reduce(
    (count, m) =>
      m.orgStatus === OrganizationMemberStatuses.Active ||
      m.orgStatus === OrganizationMemberStatuses.Invited
        ? count + 1
        : count,
    0,
  );
};
