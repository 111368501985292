import { Container, Section } from '../components/Base';
import { getTitle } from '../utils/document';

import { Helmet } from 'react-helmet';
import PrivacyPage from './PrivacyPage.mdx';

export default () => {
  return (
    <Section className="privacy">
      <Helmet>
        <title>{getTitle('Privacy')}</title>
      </Helmet>
      <Container>
        <div className="gfp-row">
          <div className="col-8 offset-2">
            <h1>Privacy Policy</h1>
            <div className="prose legal-text">
              <PrivacyPage />
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
};
