import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { getAbPlanData } from '../../abtest';
import { PATH_CHECKOUT } from '../../constants';
import connectExtension, {
  getExtensionLanding,
} from '../../extension/Provider';
import { log } from '../../logger';
import { asAbsoluteUrl } from '../../utils/url-util';

import { Button, CardSection, FormBody, PrimaryButton } from '../Base';
import Emojis, { TYPE_PREMIUM } from '../Emojis';
import { connectGTM } from '../GTMListener';
import LoaderDots from '../LoaderDots';

import { asErrorMsg } from '../../api';
import CheckoutStage from './CheckoutStage';
import StageButtons from './StageButtons';

// Implemented in CheckoutStages/index.js
const PropTypeStage = PropTypes.shape({
  slug: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
  nextIsLast: PropTypes.bool,
  logAb: PropTypes.func.isRequired,
});

class CheckoutPayment extends CheckoutStage {
  static propTypes = {
    account: PropTypes.object,
    extensionIsConnected: PropTypes.bool.isRequired,
    prevStage: PropTypeStage,
    nextStage: PropTypeStage,
    goToStage: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired,
    dataLayerPush: PropTypes.func.isRequired,
    alreadyHasSubscription: PropTypes.bool.isRequired,
    createCheckoutSessionUrl: PropTypes.func.isRequired,
    isStripeCheckout: PropTypes.bool,
    urlPathPrefix: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      HACKdoCheckout: false, // cruft from testing forced checkouts
      taxPercent: 0,
      isCalculatingTax: false,
      showTaxDetail: false,
      stripeCheckoutErrorMsg: '',
    };
    this.plan = getAbPlanData();
    this._mounted = true;

    this.postalCode = '';
    this.postalCodeToRate = {};

    this.postTrialRef = React.createRef();

    const params = new URLSearchParams(window.location.search);
  }

  // life-cycle
  componentDidMount() {}

  componentWillUnmount() {
    this._mounted = false;
  }

  // handlers
  handleStripeCheckout = () => {
    log('[CheckoutPayment.handleStripeCheckout]');
    this.setState({ stripeCheckoutErrorMsg: '' });

    const urlPathPrefx = this.props.urlPathPrefix || PATH_CHECKOUT;

    const cancel_url = asAbsoluteUrl(
      urlPathPrefx +
        (this.props.prevStage ? `/${this.props.prevStage.slug}` : ''),
    );
    const success_url = asAbsoluteUrl(
      urlPathPrefx +
        (this.props.nextStage ? `/${this.props.nextStage.slug}` : '/success'),
    );

    const checkoutParams = {
      plan: this.plan.planId,
      cancel_url,
      success_url,
    };
    if (window.location.hash === '#test') {
      checkoutParams.quick_trial = true; // if window.location.hash === '#test'
    }

    return this.props
      .createCheckoutSessionUrl(checkoutParams)
      .then((url) => {
        if (this._mounted) {
          window.location.href = url;
        }
      })
      .catch((err) => {
        this.setState({ stripeCheckoutErrorMsg: asErrorMsg(err) });
      });
  };

  // render
  _buttonElt(nextProps) {
    const { isSubmitting } = this.state;
    return (
      <StageButtons
        {...this.props}
        nextProps={nextProps}
        nextStage={nextProps ? this.props.nextStage : null}
        isSubmitting={isSubmitting}
      />
    );
  }

  _loadingElt() {
    return (
      <>
        <FormBody>
          <LoaderDots />
        </FormBody>
        {this._buttonElt()}
      </>
    );
  }

  showComponent() {
    const { account, alreadyHasSubscription, extensionOrigin } = this.props;

    const nextUrl = this.props.nextUrl || getExtensionLanding(extensionOrigin);

    const nextProps = {
      onClick: () => {},
      type: 'submit',
    };

    const hadPreviousTrial = false;

    let mainElt;
    if (hadPreviousTrial) {
      //
      // TODO(trial) - read started trial (so we can block repeats) and show checkout without trial
    } else if (!account) {
      // loading...
      mainElt = this._loadingElt();
    } else if (alreadyHasSubscription && !this.state.HACKdoCheckout) {
      // existing subscription...
      // account.subscription.status is one of [ trialing, active, past_due, unpaid ]
      mainElt = (
        <>
          <FormBody>
            <Emojis type={TYPE_PREMIUM} space={true} />
            <p>
              You already have a subscription.
              <br />
              You can review the details on your{' '}
              <Link to="/account">account page</Link> or
              <br />
              <br />
              <PrimaryButton
                className="btn-standout btn-convert"
                type="link"
                href={nextUrl}
              >
                Go to editor
              </PrimaryButton>
              <br />
              <br />
            </p>
            {null && (
              <p>
                <PrimaryButton
                  type={Button.TYPE_ROUTE}
                  to="/account"
                  className="btn-standout"
                >
                  Go to account
                </PrimaryButton>
              </p>
            )}
          </FormBody>
          {this._buttonElt()}
        </>
      );
    } else {
      // previously this was `if (this.props.isStripeCheckout) { ... }`
      // but now we always do the hosted checkout
      this.doOnce('handleStripeCheckout', this.handleStripeCheckout, true);
      mainElt = this._loadingElt();
    }

    return (
      <div className="checkoutdetails">
        <h3>Enter billing information</h3>
        <p className="dim">
          GoFullPage Premium{' '}
          <span className="nowrap">1-year subscription.</span>
          <br />
          Try for 7 days with no-hassle, easy cancellation.
        </p>
        <CardSection className="checkoutdetails">
          {this.state.stripeCheckoutErrorMsg && (
            <p className="br error">{this.state.stripeCheckoutErrorMsg}</p>
          )}
          {mainElt}
        </CardSection>
      </div>
    );
  }
}

// anything .5 or up rounds up, everything else rounds down
const roundForTaxes = (cents) => parseInt(cents + 0.5, 10);

export default connectExtension(connectGTM(CheckoutPayment));
