import PropTypes from 'prop-types';
import { Component } from 'react';

import { Helmet } from 'react-helmet';
import { getAbPlanData } from '../abtest';
import AirtableForm from '../components/AirtableForm';
import { Button, Container, PrimaryButton, Section } from '../components/Base';
import BlogPostLink from '../components/BlogPostLink';
import Emojis, { TYPE_LEGAL } from '../components/Emojis';
import { connectGTM } from '../components/GTMListener';
import { getTitle } from '../utils/document';

class PremiumPage extends Component {
  static propTypes = {
    dataLayerPush: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
    };
  }

  // Life-cycle
  componentDidMount() {
    // const prodDetail = getEcommProductDetail('Premium Page');
    // log(`ECOM PRODUCT VIEW: ${JSON.stringify(prodDetail)}`);
    // this.props.dataLayerPush(prodDetail);
  }

  // Handlers
  handleSignUp = (evt) => {
    evt.preventDefault();
    this.setState({ showForm: true });
    // const ecommAddToCart = getEcommAddToCart('Premium Page');
    // log(`ECOM ADD TO CART: ${JSON.stringify(ecommAddToCart)}`);
    // this.props.dataLayerPush(ecommAddToCart);
  };

  // Render
  render() {
    const { showForm } = this.state;

    const plan = getAbPlanData();

    const ctaButton = (
      <PrimaryButton
        className="btn-standout btn-convert"
        type={Button.TYPE_ROUTE}
        to="#"
        size="large"
        onClick={this.handleSignUp}
      >
        Sign up here
      </PrimaryButton>
    );
    return (
      <div className="premium">
        <Helmet>
          <title>{getTitle('GoFullPage Legal')}</title>
        </Helmet>
        <Section className="first-sec">
          <Container>
            <div className="gfp-row">
              <div className="col-8 offset-2">
                <h1>
                  GoFullPage Legal:
                  <br />
                  Screenshots of Webpages for Legal Professionals
                </h1>
                <Emojis type={TYPE_LEGAL} space={true} />
                <p className="large">Be the first to know</p>
                {showForm ? <AirtableForm /> : <p>{ctaButton}</p>}
                <p className="hero-info">
                  Launching soon: a new product to support legal professionals
                  with all of their screenshot needs. The easiest, most
                  pain-free way to capture a webpage, exactly as you see it.
                </p>
                <p className="hero-info">
                  See how our current premium product currently supports legal
                  uses on our blog:
                </p>
                <div className="row-center gfp-row">
                  <BlogPostLink
                    title="How to Screenshot your Webpage for a Trademark"
                    description="The most pain-free, low-cost way of creating a specimen for your trademark submission!"
                    href="https://blog.gofullpage.com/2020/10/02/how-to-screenshot-your-webpage-for-a-trademark/"
                    image="/static/blog/2020-10-02-screenshot-webpage.jpg"
                  />
                </div>
              </div>
            </div>
          </Container>
        </Section>
      </div>
    );
  }
}

export default connectGTM(PremiumPage);
