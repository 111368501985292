// # Google Tag Manager and Enhanced Ecommerce
//
// Possible values to pass into the GTM:
//
// GTMObject {
//   ga_id: string; // this is set at the very beginning
//   page: string; // something like path and search of URL
//   page_name: string; // name of current page (for events)
//   checkout_step: number; // 1, 2, ...
//   checkout_amount: number; // in cents
//   checkout_tax: number; // in cents
//   checkout_shipping: number; // in cents
//   checkout_coupon: string;
//   checkout_seconds_ago: number;
//   order_id: string; // transaction id for an order
//   products: {
//     name: string | numberany;
//     id: string | number;
//     price: number;
//     brand: string;
//     category: string;
//     variant: string | number;
//     list: string;
//     position: number;
//     quantity: number;
//   }
// }

import assert from 'minimalistic-assert';

import { getAbPlanData } from '../abtest';

const PRODUCT_NAME = 'Full Page Premium'; // keep name the same as before for consistency purposes

const _assertType = (key, val, type) =>
  assert(typeof val === type, `bad ${key}: ${val} (${typeof val})`);

const getProducts = () => {
  const abPlanData = getAbPlanData();

  return [
    {
      name: PRODUCT_NAME,
      id: abPlanData.planId,
      price: abPlanData.amount / 100,
      position: 1,
    },
  ];
};

export const getEcommProductImpressions = (page_name = 'Home Page') => {
  _assertType('page_name', page_name, 'string');
  return {
    event: 'productImpressions',
    event_category: 'Ecommerce',
    event_action: 'productImpressions',
    event_label: page_name,
    products: getProducts(),
    page_name,
  };
};

export const getEcommProductDetail = (page_name) => {
  _assertType('page_name', page_name, 'string');
  return {
    event: 'viewProduct',
    event_category: 'Ecommerce',
    event_action: 'viewProduct',
    event_label: page_name,
    products: getProducts(),
    page_name,
  };
};

export const getEcommAddToCart = (page_name) => {
  _assertType('page_name', page_name, 'string');
  return {
    event: 'addToCart',
    event_category: 'Ecommerce',
    event_action: 'addToCart',
    event_label: page_name,
    products: getProducts(),
    page_name,
  };
};

// ### Get Ecomm Checkout Account
//
// returns a dataLayer event object to track viewing checkout account
//
export const getEcommCheckoutAccount = () => {
  return _getEcommCheckout(1, 'Checkout Account');
};

// ### Get Ecomm Checkout Payment
//
// returns a dataLayer event object to track viewing checkout payment
//
export const getEcommCheckoutPayment = () => {
  return _getEcommCheckout(2, 'Checkout Account');
};

const _getEcommCheckout = (checkout_step, page_name) => {
  return {
    event: 'checkoutStep',
    event_category: 'Ecommerce',
    event_action: `checkoutStep${checkout_step}`,
    event_label: page_name,
    products: getProducts(),
    checkout_step,
    page_name,
  };
};

// ### Get Ecomm Checkout Success
//
// Just sets data. Run a pageView afterwards (make sure to not overwrite ecomm)
//
// @param {string} transId - the transaction id
// @param {number} totalRevenueCents - total transaction value (incl. tax and shipping)
// @param {number} taxCents
// @returns {object} an object that can be passed to the GA dataLayer
//
export const getEcommCheckoutSuccess = (order_id, amount, tax, seconds_ago) => {
  _assertType('order_id', order_id, 'string');
  _assertType('amount', amount, 'number');
  _assertType('tax', tax, 'number');
  _assertType('seconds_ago', seconds_ago, 'number');

  const page_name = 'Checkout Success';

  return {
    event: 'checkoutSuccess',
    event_category: 'Ecommerce',
    event_action: 'checkoutSuccess',
    event_label: page_name,
    products: getProducts(),
    page_name,
    order_id,
    checkout_amount: amount,
    checkout_tax: tax,
    checkout_shipping: 0,
    checkout_seconds_ago: seconds_ago,
  };
};
