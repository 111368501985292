import React from 'react';

const CloseIcon: React.FC<React.HtmlHTMLAttributes<HTMLOrSVGElement>> = (
  props,
) => (
  <svg
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.34801 10.8489C9.12297 11.0739 8.81776 11.2003 8.49951 11.2003C8.18127 11.2003 7.87605 11.0739 7.65101 10.8489L5.00001 7.81887L2.34901 10.8479C2.23792 10.9608 2.10556 11.0506 1.95959 11.1121C1.81361 11.1736 1.6569 11.2056 1.49849 11.2063C1.34008 11.2069 1.18311 11.1762 1.03664 11.1159C0.890167 11.0555 0.757086 10.9668 0.645074 10.8548C0.533063 10.7428 0.444337 10.6097 0.384015 10.4632C0.323692 10.3168 0.292967 10.1598 0.293612 10.0014C0.294257 9.84298 0.326259 9.68627 0.387772 9.54029C0.449286 9.39432 0.539093 9.26196 0.652013 9.15087L3.41001 6.00087L0.651013 2.84887C0.538093 2.73777 0.448286 2.60542 0.386773 2.45944C0.325259 2.31346 0.293258 2.15675 0.292612 1.99834C0.291967 1.83994 0.322692 1.68297 0.383015 1.5365C0.443337 1.39002 0.532063 1.25694 0.644074 1.14493C0.756086 1.03292 0.889167 0.944191 1.03564 0.883869C1.18211 0.823547 1.33908 0.792822 1.49749 0.793467C1.6559 0.794112 1.81261 0.826114 1.95859 0.887627C2.10456 0.94914 2.23692 1.03895 2.34801 1.15187L5.00001 4.18287L7.65101 1.15187C7.76211 1.03895 7.89446 0.94914 8.04044 0.887627C8.18642 0.826114 8.34313 0.794112 8.50154 0.793467C8.65994 0.792822 8.81691 0.823547 8.96338 0.883869C9.10986 0.944191 9.24294 1.03292 9.35495 1.14493C9.46696 1.25694 9.55569 1.39002 9.61601 1.5365C9.67633 1.68297 9.70706 1.83994 9.70641 1.99834C9.70577 2.15675 9.67377 2.31346 9.61225 2.45944C9.55074 2.60542 9.46093 2.73777 9.34801 2.84887L6.59001 6.00087L9.34801 9.15087C9.45958 9.26232 9.5481 9.39466 9.60848 9.54034C9.66887 9.68602 9.69996 9.84217 9.69996 9.99987C9.69996 10.1576 9.66887 10.3137 9.60848 10.4594C9.5481 10.6051 9.45958 10.7374 9.34801 10.8489V10.8489Z"
      fill={props.color}
    />
  </svg>
);

export default CloseIcon;
