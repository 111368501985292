import './BlogPostLink.scss';

export default function BlogPostLink({
  href,
  image,
  title,
  description,
  variant,
}: {
  href: string;
  image: string;
  title: string;
  description: string;
  variant?: 'vertical';
}) {
  if (variant === 'vertical') {
    return (
      <a
        href={href}
        className="msmall group block max-w-[300px] space-y-2 rounded-xl border border-solid border-dim-e00 bg-white p-5 text-left"
      >
        <div className="flex items-center justify-start space-x-2 text-black">
          <img src="/static/blog/logo.png" width="51" height="22" alt="Logo" />
          <span className="msmall strong">GoFullPage Blog</span>
        </div>
        <div className="text-primary group-hover:underline">{title}</div>
        <div className="small text-dim-600">{description}</div>
        <img className="max-w-full" src={image} />
      </a>
    );
  } else {
    return (
      <a href={href} className="blog-link">
        <div className="blog-link--content">
          <p className="blog-link--logo">
            <img src="/static/blog/logo.png" />
            <span>GoFullPage Blog</span>
          </p>
          <h3 className="blog-link--title">{title}</h3>
          <p className="blog-link--content">{description}</p>
        </div>
        <img className="blog-link--image" src={image} />
      </a>
    );
  }
}
