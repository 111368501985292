import create, { GetState, SetState, State } from 'zustand';
import { devtools } from 'zustand/middleware';
import {
  deleteImage,
  getImages,
  patchImage,
  postImage,
  postImageUploadComplete,
  putImageUpload,
} from './api';
import {
  Image,
  ImageCreateRequest,
  ImageDeleteRequest,
  ImageEditRequest,
} from './types';

/** Creates a zustand store pre-configured to use with redux dev tools. */
export function zustand<T extends State>(
  creator: (
    get: GetState<T>,
    set: (name: string, partial: Partial<T>) => void,
    setRaw: SetState<T>,
  ) => T,
) {
  return create<T>(
    devtools((setRaw, get) => {
      const set = (name: string, partial: Partial<T>) =>
        setRaw(partial as any, false, name);
      return creator(get, set, setRaw);
    }),
  );
}

/** Filter state to manage snap search and filtering */
type AppImageState = {
  images: Image[];
  imageIds: string[];
  index: { [id: string]: Image };
  fetchImages: () => Promise<void>;
  indexImages: () => void;
  editImage: (request: ImageEditRequest) => Promise<void>;
  deleteImage: (request: ImageDeleteRequest) => Promise<void>;
  uploadImage: (request: ImageCreateRequest, file: File) => Promise<void>;
};

/** zustand state for filter management  */
const useImagesStore = zustand<AppImageState>((get, set) => ({
  images: [],
  imageIds: [],
  index: {},
  fetchImages: async () => {
    const { index } = get();
    const imageIds = [];
    const response = await getImages();
    for (const image of response) {
      const id = image.id;
      index[id] = image;
      imageIds.push(id);
    }
    set('Fetch Image', { imageIds, index });
  },
  indexImages: () => {
    const { imageIds, index } = get();
    set('Index Images', {
      images: imageIds.map((id) => index[id]).filter((image) => !!image),
    });
  },
  editImage: async (request: ImageEditRequest) => {
    const { index } = get();
    index[request.id] = await patchImage(request);
    set('Edit Image', { index });
  },
  deleteImage: async (request: ImageDeleteRequest) => {
    const { index, imageIds: _imageIds } = get();
    await deleteImage(request);
    delete index[request.id];
    const imageIds = _imageIds.filter((id) => id !== request.id);
    set('Delete Image', { index, imageIds });
  },
  uploadImage: async (request: ImageCreateRequest, file: File) => {
    const { index, imageIds } = get();

    // Create image record
    const postImageResp = await postImage(request);
    const imageId = postImageResp.id;

    // Update image to S3
    await putImageUpload(postImageResp.uploadUrl, file);

    // Notify server that upload is complete
    const postImageUploadCompleteResp = await postImageUploadComplete({
      id: imageId,
    });

    // Add image to internal state
    index[imageId] = postImageUploadCompleteResp;
    const _imageIds = [imageId, ...imageIds];
    set('Upload Image', { index, imageIds: _imageIds });
  },
}));

// Auto-reindex when image IDs change
useImagesStore.subscribe(
  () => useImagesStore.getState().indexImages(),
  (state) => state.imageIds,
);

export default useImagesStore;
