import PropTypes from 'prop-types';
import {
  STATE_LOADING,
  STATE_SIGNEDIN,
  STATE_SIGNIN,
} from '../../auth/AuthProvider';
import { getEcommCheckoutAccount } from '../../utils/ga-ecomm';

import { CardSection, FormBody, PrimaryButton } from '../Base';
import { connectGTM } from '../GTMListener';
import LoaderDots from '../LoaderDots';

import CheckoutStage from './CheckoutStage';
import StageButtons from './StageButtons';

class CheckoutAccount extends CheckoutStage {
  static propTypes = {
    dataLayerPush: PropTypes.func.isRequired,
  };

  showComponent() {
    let infoNode;

    const { authState, user, username, signInUser, signUpUser, signOutUser } =
      this.props;

    if (authState !== STATE_LOADING) {
      // do gtm logging
      this.doOnce('logAccount', () => {
        this.props.dataLayerPush(getEcommCheckoutAccount());
      });
    }

    switch (authState) {
      case STATE_LOADING: {
        infoNode = (
          <CardSection>
            <FormBody>
              <LoaderDots />
            </FormBody>
          </CardSection>
        );
        break;
      }
      case STATE_SIGNEDIN: {
        const email = user.attributes.email;

        infoNode = (
          <CardSection>
            <FormBody>
              You are logged in as {username}
              {username !== email ? ` (${email}) ` : ' '}
              <a href="#" onClick={signOutUser} className="nowrap">
                (switch user)
              </a>
            </FormBody>
            <StageButtons {...this.props} />
          </CardSection>
        );
        break;
      }
      case STATE_SIGNIN: {
        infoNode = (
          <CardSection>
            <FormBody>
              <p>Get started by creating an account:</p>
              <p>
                <PrimaryButton className="btn-standout" onClick={signUpUser}>
                  Sign up
                </PrimaryButton>
              </p>
              <p>
                Already have an account?{' '}
                <a
                  href="#"
                  onClick={(evt) => {
                    evt.preventDefault();
                    signInUser();
                  }}
                >
                  Sign in
                </a>
              </p>
            </FormBody>
          </CardSection>
        );

        // <p className="dim small br-top">
        //   User accounts are managed by Amazon Web Services Cognito, which
        //   uses industry leading best practices for security and
        //   encryption.
        // </p>

        break;
      }
      default: {
        throw new Error(`Unknown authState: "${authState}"`);
      }
    }

    return (
      <div className="checkoutaccount">
        <h3>Creating an account is as easy as 1-2-3</h3>
        <p className="dim">
          GoFullPage Premium 1-year subscription.
          <br />
          Try for 7 days with no-hassle easy cancellation.
        </p>
        {infoNode}
      </div>
    );
  }
}

export default connectGTM(CheckoutAccount);
