import React, { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  revokeEnterpriseMemberInvite,
  sendEnterpriseMembersInvitations,
  updateEnterpriseMemberStatus,
} from '../api';
import { ENTERPRISE_SUPPORT_EMAIL } from '../constants';
import { useAccountContext } from '../context/AccountContext';
import { AlertManager } from '../context/AlertManager';
import ApiErrorMessage from '../hooks/ApiErrorMessage';
import { useBoolean } from '../hooks/useBoolean';
import { useEnterpriseAccountMembers } from '../hooks/useEnterpriseAccountMembers';
import {
  OrganizationMember,
  OrganizationMemberRoles,
  OrganizationMemberStatuses,
} from '../types/Account';
import { capitalize, isValidEmail } from '../utils/utils';
import Button from './atoms/button';
import Textarea from './atoms/textarea';
import { Disclaimer, Text } from './atoms/typography';
import DotsMenuIcon from './icons/dots-menu-icon';
import Dropdown, { DropdownOption } from './molecules/dropdown';
import Modal from './molecules/modal';
import ProcessingLoader from './molecules/processing-loader';
import Table from './molecules/table';
import { EnterpriseSupportEmailLink } from './organisms/support-email-link';
import { EnterpriseDashboardPage } from './templates/EnterpriseDashboardLayout';

const ContentContainer = styled.div`
  > * {
    margin-bottom: 24px;
  }
  > table {
    margin-left: 8px;
  }
`;

const MenuIconButton = styled.div`
  text-align: center;
  cursor: pointer;
  height: 24px;
  :hover {
    > svg {
      fill: black;
    }
  }
  > svg {
    height: inherit;
    display: block;
    margin: auto;
    fill: #999999;
  }
`;

const ActionLabels = {
  sendInvite: 'Send invite',
  resendInvitation: 'Resend invitation',
  deleteInvitation: 'Delete invitation',
  deactivateAccount: 'Deactivate account',
  reactivateAccount: 'Reactivate account',
  archiveAccount: 'Archive account',
};

type InviteInput = { text: string; emails: string[] };

const EMPTY_INVITE_INPUT: InviteInput = { text: '', emails: [] };

const EnterpriseAccountMembers: React.FC = () => {
  const { setAlert } = useContext(AlertManager);
  const { loading: loadingAccount, organization } = useAccountContext();

  const {
    error,
    loading: loadingMembers,
    members,
    refetch,
    usedSeats,
  } = useEnterpriseAccountMembers();
  const orgId = organization?.orgId;
  const [addMemberModalOpened, toggleAddMemberModal] = useBoolean();

  const [inviteInput, setInviteInput] =
    useState<InviteInput>(EMPTY_INVITE_INPUT);

  const [xhrPending, toggleXhrPending] = useBoolean();
  const [validEmails, setValidEmails] = useState<string[]>([]);
  const loading = loadingAccount || loadingMembers;
  const allocated = organization?.seats ?? members.length;
  const remaining = Math.max(allocated - usedSeats, 0);
  const exceedsRemainingSeats = inviteInput.emails.length > remaining;
  const hasInvalidEmail = useMemo(
    () => inviteInput.emails.length !== validEmails.length,
    [validEmails],
  );

  const canSendInvite =
    !xhrPending &&
    validEmails.length > 0 &&
    !hasInvalidEmail &&
    !exceedsRemainingSeats;

  const sendInvite = () => {
    if (canSendInvite) {
      invoke(
        sendEnterpriseMembersInvitations(
          orgId,
          validEmails.map((email) => ({ email })),
        )
          .then(() => setInviteInput(EMPTY_INVITE_INPUT))
          .finally(toggleAddMemberModal),
        ActionLabels.sendInvite,
      );
    }
  };

  const invoke = (promise: Promise<any>, action: string) => {
    toggleXhrPending();
    promise
      .catch((_) =>
        setAlert({
          title: `Unable to ${action.toLowerCase()}.`,
          message: `Please retry later or contact us at ${ENTERPRISE_SUPPORT_EMAIL} if the problem persists`,
          severity: 'error',
        }),
      )
      .then(refetch)
      .finally(toggleXhrPending);
  };

  const actionResendInvitation = (member: OrganizationMember) => ({
    label: ActionLabels.resendInvitation,
    onClick: () =>
      invoke(
        sendEnterpriseMembersInvitations(orgId, [member]),
        ActionLabels.resendInvitation,
      ),
  });

  const actionDeleteInvitation = (member: OrganizationMember) => ({
    label: ActionLabels.deleteInvitation,
    onClick: () =>
      invoke(
        revokeEnterpriseMemberInvite(orgId, member.userId),
        ActionLabels.deleteInvitation,
      ),
  });

  const actionDeactivateAccount = (member: OrganizationMember) => ({
    label: ActionLabels.deactivateAccount,
    onClick: () =>
      invoke(
        updateEnterpriseMemberStatus(
          orgId,
          member.userId,
          OrganizationMemberStatuses.Deactivated,
        ),
        ActionLabels.deactivateAccount,
      ),
  });

  const actionReactivateAccount = (member: OrganizationMember) => ({
    label: ActionLabels.reactivateAccount,
    onClick: () =>
      invoke(
        updateEnterpriseMemberStatus(
          orgId,
          member.userId,
          OrganizationMemberStatuses.Active,
        ),
        ActionLabels.reactivateAccount,
      ),
  });

  // const actionArchiveAccount = (member: OrganizationMember) => ({
  //   label: ActionLabels.archiveAccount,
  //   onClick: () =>
  //     confirm(`Are you sure you want to archive ${member.email}?`)
  //       ? invoke(
  //           updateEnterpriseMemberStatus(
  //             orgId,
  //             member.userId,
  //             OrganizationMemberStatuses.Archived
  //           ),
  //           ActionLabels.archiveAccount
  //         )
  //       : Promise.resolve(),
  // });

  const actions = (member: OrganizationMember): DropdownOption[] =>
    ({
      invited: [
        actionResendInvitation,
        actionDeleteInvitation,
        // actionArchiveAccount
      ],
      active: [
        actionDeactivateAccount,
        // actionArchiveAccount
      ],
      deactivated: [
        actionReactivateAccount,
        // actionArchiveAccount
      ],
      archived: [],
    })[member.orgStatus].map((builderFor) => builderFor(member));

  return (
    <EnterpriseDashboardPage
      title="🎨 Account"
      subTitle="Manage members"
      loading={loading}
      toolbar={
        <Button onClick={toggleAddMemberModal} disabled={remaining === 0}>
          Add member
        </Button>
      }
    >
      <ProcessingLoader opened={xhrPending && !addMemberModalOpened} />
      <ContentContainer>
        {error ? (
          <ApiErrorMessage error={error} />
        ) : (
          <>
            <Table
              headers={['Email', 'Role', 'Status', '']}
              rows={members.map((member) => ({
                id: member.userId,
                cells: [
                  member.email,
                  capitalize(member.orgRole),
                  capitalize(member.orgStatus),
                  member.orgRole === OrganizationMemberRoles.Admin ||
                  member.orgStatus ===
                    OrganizationMemberStatuses.Archived ? undefined : (
                    <Dropdown
                      className="align-middle"
                      trigger={
                        <MenuIconButton>
                          <DotsMenuIcon />
                        </MenuIconButton>
                      }
                      options={actions(member)}
                    />
                  ),
                ],
              }))}
            />
            <Text style={{ fontSize: '14px' }}>
              <b>
                {usedSeats} out of {allocated}
              </b>{' '}
              user licenses in use; <b>{remaining}</b> licenses remaining
            </Text>
            <Disclaimer>
              Contact us at{' '}
              <EnterpriseSupportEmailLink organization={organization} /> for
              further assistance.
            </Disclaimer>
          </>
        )}
      </ContentContainer>
      <Modal
        title={
          <>
            Invite members
            <br />
            <span style={{ fontSize: '14px', fontWeight: 'normal' }}>
              <b>
                {usedSeats} out of {allocated}
              </b>{' '}
              active user licenses; <b>{remaining}</b> seats remaining
            </span>
          </>
        }
        opened={addMemberModalOpened}
        onClose={toggleAddMemberModal}
      >
        <form
          onSubmit={(event) => {
            event.preventDefault();
            sendInvite();
          }}
        >
          <Text className="mb-[4px]">
            Email address (for multiple, separate with commas):
          </Text>
          <Textarea
            disabled={xhrPending}
            placeholder="name@example.com"
            value={inviteInput.text}
            onValueChange={(val) => {
              const newInviteInput = {
                text: val,
                emails: val
                  .split(',')
                  .map((v) => v.trim())
                  .filter((v) => v),
              };
              setInviteInput(newInviteInput);

              // see if we can reset invalid if currently is invalid
              if (hasInvalidEmail) {
                setValidEmails(newInviteInput.emails.filter(isValidEmail));
              }
            }}
            onBlur={() => {
              setValidEmails(inviteInput.emails.filter(isValidEmail));
            }}
            error={
              hasInvalidEmail
                ? 'Contains invalid emails'
                : inviteInput.emails.length > remaining
                  ? `Error: too many emails specified (${inviteInput.emails.length}), there are only ${remaining} seats remaining.`
                  : undefined
            }
          />
          <div className="mt-[16px] flex flex-row-reverse justify-start gap-[16px]">
            <Button
              type="submit"
              loading={xhrPending}
              disabled={xhrPending || exceedsRemainingSeats}
            >
              Send
            </Button>
            <Button
              color="grey"
              onClick={toggleAddMemberModal}
              disabled={xhrPending}
            >
              Cancel
            </Button>
          </div>
        </form>
      </Modal>
    </EnterpriseDashboardPage>
  );
};

export default EnterpriseAccountMembers;
