import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import './AsSeenInWidget.scss';
import { Container, Section } from './Base';
import {
  LOGO_BUSINESSINSIDER,
  LOGO_FASTCOMPANY,
  LOGO_GIZMODO,
  LOGO_PCMAG,
  LOGO_TECHRADAR,
} from './Logos';

const MAX_CONTAINER = 1180;

const LOGOS = [
  LOGO_TECHRADAR,
  LOGO_PCMAG,
  LOGO_BUSINESSINSIDER,
  // LOGO_USATODAY,
  LOGO_FASTCOMPANY,
  LOGO_GIZMODO,
];

const AsSeenInWidget = (props) => {
  return (
    <Section className="gray-sec asi-widget">
      <h2>As seen in</h2>
      <p className="subheader">
        Take a look at our{' '}
        <Link className="sly" to="/press">
          press
        </Link>
        .
      </p>
      <Container className="br2-top">
        <div className="logo-cloud">
          <div className="logo-cloud-inner">
            {LOGOS.map((logo, i) => (
              <StretchyImg key={i} className={`logo-${i} logo`} {...logo} />
            ))}
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default AsSeenInWidget;

//

const StretchyImg = ({ src, src2x, width, height, style, ...props }) => {
  const _src = window.devicePixelRatio || 1 > 1 ? src2x : src;
  const _style = useMemo(() => {
    return {
      ...style,
      width: `${(width / MAX_CONTAINER) * 100}%`,
      // height: `${(height / MAX_CONTAINER) * 100}%`,
      aspectRatio: `auto ${width} / ${height}`,
    };
  }, [style, width, height]);

  return <img src={_src} style={_style} {...props} />;
};
