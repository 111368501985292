import { useMemo } from 'react';

/**
 * A hook that extracts the social sign-in provider from the user login data if it exists.
 * @param user - The AWS Amplify user object.
 * @returns The provider name string if found otherwise 'undefined'.
 */
export function useUserProvider(user) {
  return useMemo(() => getUserProvider(user), [user]);
}

/**
 * A function extracts the social sign-in provider from the user login data if it exists.
 * @param user - The AWS Amplify user object.
 * @returns The provider name string if found otherwise 'undefined'.
 */
export function getUserProvider(user) {
  if (!user || !user.attributes) return;
  const identitiesString = user.attributes.identities;
  if (identitiesString) {
    const identities = JSON.parse(identitiesString);
    if (identities.length) {
      const provider = identities[0].providerName;
      return provider;
    }
  }
}

/** Returns the user facing text describing the user provider. */
export function getUserProviderText(userProvider) {
  return userProvider ? `Continue with ${userProvider}` : 'Email & Password';
}
