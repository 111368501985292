import { FunctionComponent, useState } from 'react';
import { SUPPORT_EMAIL } from '../constants';
import { log } from '../logger';
import { Account, Subscription } from '../types/Account';

type SubscriptionWillCancelHeaderProps = {
  subscription?: Subscription;
  updateAccountCancelAtPeriodEnd: (
    cancelAtPeriodEnd: boolean,
  ) => Promise<Account>;
};

const SubscriptionWillCancelHeader: FunctionComponent<
  SubscriptionWillCancelHeaderProps
> = ({ subscription, updateAccountCancelAtPeriodEnd }) => {
  const [loading, setLoading] = useState(false);

  if (
    !subscription ||
    subscription.status !== 'active' ||
    !subscription.cancel_at_period_end ||
    !subscription.current_period_end
  ) {
    return null;
  }

  return (
    <div className="error mb-[20px] rounded-lg bg-red-100 p-[10px]">
      Your subscription will cancel on{' '}
      {subscription.current_period_end.format('LLL')}. To continue your
      subscription,{' '}
      <a
        href="#"
        className="text-inherit underline hover:text-inherit"
        onClick={(evt) => {
          evt.preventDefault();
          if (loading) {
            log.warn(
              '[Account.updateAccountCancelAtPeriodEnd] Already loading',
            );
            return;
          }
          setLoading(true);
          updateAccountCancelAtPeriodEnd(false)
            .then(() => {
              setLoading(false);
            })
            .catch((err) => {
              log.error('[Account.updateAccountCancelAtPeriodEnd]', err);
              setLoading(false);
              alert(
                `Error updating. Please refresh and try again. If this problem persists, contact ${SUPPORT_EMAIL}`,
              );
            });
        }}
      >
        {loading ? 'loading…' : 'click here'}
      </a>
      {loading ? '' : '.'}
    </div>
  );
};

SubscriptionWillCancelHeader.displayName = 'SubscriptionWillCancelHeader';

export default SubscriptionWillCancelHeader;
