categories:
  - name: Introduction
    questions:
      - question: What is a browser extension?
        answer: A browser extension is an application that adds extra features to your web browser (such as a [password manager](https://chrome.google.com/webstore/detail/lastpass-free-password-ma/hdokiejnpimakedhajhdlcegeplioahd?hl=en-US), [grammar checker](https://chrome.google.com/webstore/detail/grammarly-for-chrome/kbfnbcaeplbcioakkpcpgfkobkghlhen?hl=en), [ad blocker](https://chrome.google.com/webstore/detail/ublock-origin/cjpalhdlnbpafiamejdnhcphjbkeiagm?hl=en), or [screenshot tool](https://chrome.google.com/webstore/detail/gofullpage-full-page-scre/fdpohaocaechififmbbbbbknoalclacl?hl=en)).
      - question: What browsers support GoFullPage?
        answer: |
          <div className="collapse-p"><span className="highlight">Currently, you can download our app on Google Chrome and Microsoft Edge. Extensions on any other browsers are unsanctioned versions not endorsed or operated by GoFullPage.</span> So for best results, we recommend downloading our product only from the <a href="https://chrome.google.com/webstore/detail/gofullpage-full-page-scre/fdpohaocaechififmbbbbbknoalclacl?hl=en">Chrome Web Store</a> or the <a href="https://microsoftedge.microsoft.com/addons/detail/gofullpage-full-page-sc/hfaciehifhdcgoolaejkoncjciicbemc">Microsoft Edge Add-ons page</a> (it takes less than a minute).</div>

          However, it should be noted that browsers running on Chromium allow users to download GoFullPage and use GoFullPage Premium from the Chrome Web Store. Stay tuned for official extensions on other browsers!
      - question: How does GoFullPage work?
        answer: GoFullPage is a browser extension that can take a screenshot of an entire webpage that you are viewing in your browser. When you click on the extension icon, the extension scrolls down and across the current page you’re viewing in your browser, assembling each scroll window into a single image that shows in a new tab. From there you can do things like download the image or export it to PDF in a page size of your choice. We also offer premium features for editing, annotating, datestamp, and smart page breaks.
      - question: What is Full Page Screen Capture? Is it different from GoFullPage?
        answer: Full Page Screen Capture was the original name from the extension’s launch in 2012. Starting in July 2020, we have changed our name to GoFullPage. We are the same product and same team providing the same extension you’ve used and loved! Read more on the announcement about the name change [on our blog](https://blog.gofullpage.com/2020/07/20/introducing-gofullpage-2/).
      - question: Who made this extension?
        answer: Hi, I’m Peter Coles. I’m an independent software developer who lives in New York City. I originally built this extension in 2012 because I wanted to print a pixel-perfect version of a webpage I was working on, but there were no good solutions. The project was maintained for years in an [open source github repo](https://github.com/mrcoles/full-page-screen-capture-chrome-extension). In 2018, I created a private fork of the project to work on additional advanced features and improvements. I hope you can support this extension, so I can continue providing a high quality product. You can read more on [my original blog post](https://mrcoles.com/full-page-screen-capture-chrome-extension/).
  - name: Capture
    questions:
      - question: I downloaded the app, but I don’t see it in my browser!
        answer: |
          Google Chrome: you should see a jigsaw icon to the right of the URL bar. If you click on that jigsaw icon, it should open a menu of additional browser extensions you’ve downloaded. If you’re not seeing the GoFullPage icon on the browser, you should see it on this menu. You can go a step further and click the pushpin icon so that GoFullPage will be pinned to and always visible on your browser bar!

          Microsoft Edge: you should see a button with three dots to the right of the URL bar that is for “Settings and more”. If you click on this button, it should open a menu of additional browser extensions you’ve downloaded. If you’re not seeing the GoFullpage icon on the browser, you should see it on this menu. You can go a step further and right click on the GoFullPage camera icon and select “Move to the toolbar” so that GoFullPage will always be visible on your browser bar!
      - question: How do I ensure the best results?
        answer: We recommend closing out of information bars or pop-ups, scrolling the length of the page you’re interested in capturing, and loading all content before engaging the extension.
      - question: Is there a keyboard shortcut?
        answer: |
          You can trigger the extension with alt+shift+p. Furthermore you can manage and edit browser extension keyboard shortcuts on this Chrome settings page: chrome://extensions/shortcuts or this Edge settings page: edge://extensions/shortcuts

          If you’re trying to use the keyboard shortcut to take advantage of capturing a hover state on the page, this won’t work, because when the extension scrolls to each location, it will lose the hover.
      - question: Why didn’t the page scroll during capture?
        answer: |
          GoFullPage is the most advanced browser Extension for taking a screenshot of a full web page. It is able to deal with tricky page styles, prevent most fixed elements from not repeating across screenshots, identify inner scrollable elements (like in Gmail or other popular email clients), and even enter into iframes and framesets to create a single image of the full page that you are currently viewing. Despite all this, there are some pages out there on the web that do not capture properly.

          For example, if you are trying to capture a PDF, the extension is unable to access the PDF document and perform any scrolling or some sites completely manage page scrolling in their own custom JavaScript code, such as Adobe Spark pages. If you seem to have encountered an error, feel free to reach out to customer support from within the extension.
      - question: Why is the end of my screenshot blank?
        answer: |
          A number of issues cropped up in a recent Chrome release that are now solved. Take a look at the following potential reasons for a blank spot in your screenshot:

            1. If you are on Chrome 76 or 77, please update to the latest version you can do so from the “About Google Chrome page” `chrome://settings/help`.
            2. If you are running a version of the extension < 6.4, please update. This should happen automatically, but you can check by right-clicking on the extension icon and selecting “Manage Extensions”. If that page shows Version 6.2 or 6.3, then please toggle on “Developer mode” in the top right and click the “Update” button.
            3. If you are using the Chrome dev tools mobile emulator, make sure that your emulated device is completely visible on your screen, if part of it is below the fold, then that part will not get captured.

          If you’re still encountering troubles, please reach out to customer support within the extension!
      - question: Why does the image appear small or low resolution?
        answer: When your screenshot completes and it is displayed in a new tab, it is initially shown zoomed out. You can click on it to toggle zoomed in or out—either way when you download it, it will be the full resolution image.
      - question: Why is the screenshot split up into multiple images on extremely large pages?
        answer: |
          If the extension tries to generate a single image that is too large, it either breaks and the browser just shows a blank white page. The vast majority of pages on the web do not encounter this problem, but on a very large page (especially if you’re using a high pixel density screen) you might see a message from the extension that the screenshot will be broken up into multiple images. You can view and download the multiple images in the extension when the capture completes. (If the number of images is large—10 or more—then the screenshot may not complete.)

          Pro-tip: If you export as PDF and you have the PDF Size set to “full image” in the extension settings, then it will all export as one single page.
  - name: Misc
    questions:
      - question: What permissions are used?
        answer: |
          GoFullPage requires no special permissions for installation and basic usage. The app requests permissions only when they are absolutely necessary for capture or download and these permissions can be revoked or re-instated at any time on the bottom of the “Options” page (the gear icon in the extension). Once revoked, the permissions will be re-requested any time they are needed for a particular page. The permissions that can be requested are Downloads (required for download buttons and auto-download feature) and iFrame (required to scroll inner contents of iframes or framesets).

          Note: You cannot pre-emptively grant permission to GoFullPage before the app has encountered a need for the permission on a specific page or action.
      - question: Where are screenshots stored?
        answer: |
          The extension uses the browser’s HTML5 filesystem and the IndexedDB API. Both are stored within the browser in a sandboxed storage that is only accessible to the extension and only lives on your computer. They are not anywhere else online or in any servers.

          Whenever you delete them within the extension, they are fully gone—you can do this from an individual image or from the history page. Also, if you ever uninstall the extension they will all be gone too. Of course, if you download images, they’ll be in the filesystem on your computer and whatever you do with those images is up to you.
      - question: How do I uninstall the extension?
        answer: |
          Google Chrome: If you would like to remove it, just right-click on the extension icon in Chrome and select “Remove from Chrome…”. You can also go to the Chrome Extensions settings page for the extension (chrome://extensions/?id=lenmnnjaheeocemoepjoaoeipcgkodgg) to remove it there. If you’d like to try the extension again in the future, then feel free to find us once more in the [Chrome Web Store](https://chrome.google.com/webstore/detail/gofullpage-full-page-scre/fdpohaocaechififmbbbbbknoalclacl?hl=en)!

          Microsoft Edge: If you would like to remove it, just right-click on the extension icon in Edge and select “Remove from Microsoft Edge”. You can also go to the Edge Extensions settings page (edge://extensions/) to remove the extension there. If you’d like to try the extension again in the future, then feel free to find us once more in the on the [Microsoft Edge Add-ons page](https://microsoftedge.microsoft.com/addons/detail/gofullpage-full-page-sc/hfaciehifhdcgoolaejkoncjciicbemc)!

          Note: Uninstalling the extension does not unsubscribe you from our premium tool. Also, an uninstall will delete any images you have stored within the extension. If you’d like to download any ahead of time, then you can do so from the files view within the extension.
      - question: What is the privacy policy?
        answer: 'The privacy policy is listed here: https://gofullpage.com/privacy'
premium_categories:
  - name: Premium
    questions:
      - question: How does premium work?
        answer: Our premium tool costs $12 USD (not including tax and foreign exchange) per year. The subscription auto-renews each year unless renewal is disabled on your account page. Our premium subscription includes a 7-day free trial and can be canceled anytime while trialing. Visit the [GoFullPage Premium page](https://gofullpage.com/premium) and/or [watch the demo videos](https://gofullpage.com/demos#editor) to find out more.
      - question: Do I need to sign up for premium to download my screenshots?
        answer: No, you can take and download as many screenshots as you want—exporting to an image or PDF—with the free version! However, if you want to crop, edit, or annotate your screenshot using the premium tools, then you’ll need to start a premium trial to download the edited version.
      - question: How do I manage my account?
        answer: Premium users can access their account from the [“Account” link](https://gofullpage.com/account) in the top nav bar on the site. From here you can (1) see the status of and modify your current subscription, (2) access and print out any past invoices (which is useful if your work reimburses you for tools!), and (3) update your payment method.
      - question: Help, I can’t log in!
        answer: |
          Here are some issues that people have run into:

            *   If you logged in previously using the “Continue with Google” or “Continue with Facebook” button, then you have to use that for future logins. You also cannot change your password if you logged in with this method. Your welcome email will mention if you logged in with one of these methods!
            *   If you created an account using an email and password, then make sure you are using the exact email that you entered (with any capitalization that was in that email). Your welcome email will have been sent to the email with the exact uppercase/lowercase formatting you used when you signed up.
            *   If you keep encountering an error message or a hanging loading page, then please review your system settings to verify that your computer has the correct time. If the clock on your computer is out of sync with the current time, then logging in to GoFullPage Premium will not work.

                For Windows users, the browser will prevent log-in if the system settings for Date & Time have either of the following settings disabled:

                -   “Adjust for daylight savings time automatically”
                -   “Synchronize with an Internet time server”


            *   If you are running Microsoft Edge and have the extension installed both from the Chrome Web Store and also from the Microsoft Edge Add-ons Store, then login will likely only work with the Edge version of the extension. Try uninstalling one of the extensions so you only have it installed once on Microsoft Edge.
            * If you have other extensions on your browser, you could try visiting your extensions page (chrome://extensions or edge://extensions) and disabling all other extensions. Try signing in again once all other extensions are disabled to see if login is happening successfully. If it is, you can then re-enable each extension one-by-one to identify the extension that is interfering. Please report to us if you identify an interfering extension so that we can contact the authors!
      - question: How does the trial work?
        answer: New users are given a 7-day free trial to try out the extension. You will have access to all of the premium features during this time. You can cancel at any point during the trial on the account page (at the bottom of the page—there is no need for phone calls or emails) and you will not be charged anything. Upon successful completion of a trial, your card will be charged in order to continue the subscription.
      - question: How do I cancel my subscription?
        answer: You can manage your subscription from the bottom of the account page. If you are in a trial, then you can cancel the trial and you will not be charged anything. If you are in a subscription, then you can set your subscription to not renew. You will be able to use the premium features for the rest of the current term of your subscription and it will no longer renew and make no further charges.
      - question: How do charges appear on my card?
        answer: Charges will show up with the statement descriptor “GOFULLPAGE.COM” (or prior to July 23, 2020 they might show up as “Full Page Chrome Exten”). Please reach out to support -at- gofullpage -dot- com if you have questions about a charge.
      - question: How can I contact you with questions about my subscription?
        answer: Please reply to any transactional email you have received from GoFullPage or submit a customer support ticket [here](https://screencapture.mrcoles.com/).
