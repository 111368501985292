Thank you for using GoFullPage, operated by Full Page LLC ("Full Page," "we," or "our").

By using the website located at [https://gofullpage.com](https://gofullpage.com) and the related mobile website, along with the Chrome extension called “GoFullPage”—formerly called “Full Page Screen Capture”—(collectively, the “Service”), you agree to be bound by these Terms of Use (this “Terms of Use” or “Agreement”), whether or not you register as a member of Full Page ("Member"). If you wish to become a Member and/or make use of the service (the “Service”), please read this Agreement. If you object to anything in this Agreement or the Full Page Privacy Policy, do not use the Service.

PLEASE READ THIS AGREEMENT CAREFULLY AS IT CONTAINS IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES AND OBLIGATIONS, INCLUDING VARIOUS LIMITATIONS AND EXCLUSIONS, AND A DISPUTE RESOLUTION CLAUSE THAT GOVERNS HOW DISPUTES WILL BE RESOLVED.

**1. Electronic Agreement.** This Terms of Use is an electronic contract that sets out the legally binding terms of your use of the Service. This Terms of Use is subject to change by Full Page at any time, effective upon posting on the Full Page Service. By accessing and/or using the Service or becoming a Member, you accept this Terms of Use and agree to the terms, conditions and notices contained or referenced herein. Your continued use of the Service following Full Page’s posting of revised terms of any section of the Terms of Use will constitute your express and binding acceptance of and consent to the revised Terms of Use.

**2. Access and Retention.** In order to access and retain this electronic Agreement, you must have access to the World Wide Web, either directly or through devices that access web-based content, and pay any service fees associated with such access. In addition, you must use all equipment necessary to make such connection to the World Wide Web, including a computer and modem or other access device. Please print a copy of this document for your records. To retain an electronic copy of this Agreement, you may save it into any word processing program.

**3. Eligibility.** You must be at least eighteen (18) years of age. By using the Service, you represent and warrant that you have the right, authority and capacity to enter into this Agreement and to abide by all of the terms and conditions of this Agreement.

The Service is also not available to any users who have been previously removed or suspended from the Service.

By using the Service, you represent and warrant that you have the right, authority and capacity to enter into this Terms of Use and to abide by all of the terms and conditions of this Terms of Use.

**4. Commercial Use of Service. **If you are using and/or accessing the Service on behalf of a company, entity, or organization (collectively, a “Subscribing Entity”), you represent and warrant that:

**a.** You are an authorized representative of the Subscribing Entity, and that you have the authority to bind the Subscribing Entity to this Terms of Use;

**b. **You have read and understand this Terms of Use; and

**c. **You agree to this Terms of Use on behalf of the Subscribing Entity.

Illegal and/or unauthorized uses of the Service include, but are not limited to, collecting usernames and/or email addresses of Members by electronic or other means for the purpose of sending unsolicited email, unauthorized framing of or linking to the Service, sharing or disclosing your username or password to any third party or permitting any third party to access your account, attempting to impersonate another user or person, use of the Service in any fraudulent or misleading manner, any automated use of the system, such as scraping the Service, automated scripts, spiders, robots, crawlers, harvesting or data mining tools or the like, interfering with, disrupting, or creating an undue burden on the Service or the networks or services connected to the Service, and using the Service in a manner inconsistent with any and all applicable laws and regulations. Illegal and/or unauthorized use of the Service may be investigated, and appropriate legal action may be taken, including without limitation, civil, criminal, and injunctive redress. Use of the Service is with the permission of Full Page, which may be revoked at any time, for any reason, in Full Page’s sole discretion.

**5. Your Use of the Service**

**a. **You must not copy or capture, or attempt to copy or capture, any content from the Service, unless given express permission by Full Page.

**b. **You must not use any content in any way that is designed to create a separate service or that replicates any part of the offering of the Service.

**c. **You must not employ scraping or similar techniques to aggregate, repurpose, republish or otherwise make use of any content.

**d. **You must not employ any techniques or make use of any services, automated or otherwise, designed to misrepresent your activity on the Service, including without limitation by the use of bots, botnets, scripts, apps, plugins, extensions or other automated means to register accounts, log in, send messages, post comments, or otherwise to act on your behalf, particularly where such activity occurs in a multiple or repetitive fashion. You must not offer or promote the availability of any such techniques or services to any other users of the Service.

**e. **You must not alter or remove, or attempt to alter or remove, any trademark, copyright or other proprietary or legal notices contained in, or appearing on, the Service or any content appearing on the Service.

**f.** You must not, and must not permit any third party to, copy or adapt the object code of the Service, or reverse engineer, reverse assemble, decompile, modify or attempt to discover any source or object code of any part of the Service, or circumvent or attempt to circumvent or copy any copy protection mechanism or access any rights management information pertaining to content on the Service.

**g.** You must not commit or engage in, or encourage, induce, solicit or promote, any conduct that would constitute a criminal offense, give rise to civil liability or otherwise violate any law or regulation.

**h.** You must not rent, sell or lease access to the Service, or any content on the Service.

**i.** You must not deliberately impersonate any person or entity or otherwise misrepresent your affiliation with a person or entity, for example, by registering an account in the name of another person or company, or sending messages or making comments using the name of another person.

**j.** You must not stalk, exploit, threaten, abuse or otherwise harass another user, or any Full Page employee. If Full Page determines that any user has threatened, stalked, harassed, or verbally abused any Full Page employee or another Full Page Member, Full Page reserves the right to immediately terminate that user’s membership and suspend access to the Service.

**k.** You must not sell or transfer, or offer to sell or transfer, any Full Page account to any third party without the prior written approval of Full Page.

**l.** You must not collect or attempt to collect personal data, or any other kind of information about other users, including without limitation, through spidering or any form of scraping.

**m.** You must not violate, circumvent or attempt to violate or circumvent any data security measures employed by Full Page; access or attempt to access data or materials which are not intended for your use; log into, or attempt to log into, a server or account which you are not authorized to access; attempt to scan or test the vulnerability of Full Page’s servers, system or network or attempt to breach Full Page’s data security or authentication procedures; attempt to interfere with the Services by any means including, without limitation, hacking Full Page’s servers or systems, submitting a virus, overloading, mail-bombing or crashing. Without limitation to any other rights or remedies of Full Page under these Terms of Use, Full Page reserves the right to investigate any situation that appears to involve any of the above, and may report such matters to, and cooperate with, appropriate law enforcement authorities in prosecuting any users who have participated in any such violations.

**n.** You must not use the Service to capture, upload, post, store, transmit, display, copy, distribute, promote, make available or otherwise communicate to the public:

<div className="indent">

**i.** any content that is offensive, abusive, libelous, defamatory, obscene, racist, sexually explicit, ethnically or culturally offensive, indecent, that promotes violence, terrorism, or illegal acts, incites hatred on grounds of race, gender, religion or sexual orientation, or is otherwise objectionable in Full Page’s sole and reasonable discretion;

**ii.** any information, content or other material that violates, plagiarizes, misappropriates or infringes the rights of third parties including, without limitation, copyright, trademark rights, rights of privacy or publicity, confidential information or any other right; or

**iii.** any content that violates, breaches or is contrary to any law, rule, regulation, court order or is otherwise is illegal or unlawful in Full Page’s sole and reasonable opinion; or

**iv.** impersonates another person or contains photographs, images, or audio of another person without that person’s permission or, if the person is a minor, the minor’s legal guardian.

</div>

You agree to comply with the above conditions, and acknowledge and agree that Full Page has the right, in its sole discretion, to terminate your account or take such other action as we see fit if you breach any of the above conditions or any of the other terms of these Terms of Use. This may include taking court action and/or reporting offending users to the relevant authorities.

**6. Membership; Pricing; Charges on Your Account.**

**a. General. **Please note that the billing policies that are disclosed to you in subscribing to the service are deemed part of this Terms of Use. Notwithstanding anything herein, you are solely responsible for all taxes associated with your use of the Service.

**b. Billing**. Full Page bills you through an online account (your "Billing Account") for use of the Service. You agree to pay all charges at the prices then in effect for any use of the Service by you or other persons (including your agents) using your Billing Account, and you authorize Full Page to charge your chosen payment provider (your "Payment Method") for the Service. You agree to make payment using that selected Payment Method.

**c. Payment Method. **The terms of your payment will be based on your Payment Method and may be determined by agreements between you and the financial institution, credit card issuer or other provider of your chosen payment method.

**d. Recurring Billing.** Our subscription plans to the Service consist of an initial period, for which there is a one-time charge, followed by recurring period charges as agreed to by you. By entering into this Agreement, you acknowledge that your subscription has an initial and recurring payment feature and you accept responsibility for all recurring charges prior to cancellation. Full Page may submit periodic charges (e.g. yearly) without further authorization from you, until you provide prior notice (confirmed in writing by Full Page) that you have terminated this authorization or wish to change your payment method. Such notice will not affect charges submitted before Full Page could reasonably act. You may change your payment method or cancel your paid membership by a provider of your chosen Payment Method (the "Payment Method Provider") in your account settings on the Service. For your convenience, we take your payment information so that your Full Page membership will not be interrupted. We auto-renew your membership at the level you selected. Your Full Page subscription will be automatically extended for successive renewal periods of the same duration as the subscription term originally selected, at the then-current non-promotional subscription rate (if applicable). You may cancel in your account settings. Please note that you will not be eligible for a prorated refund of any portion of the subscription fee paid for the then-current subscription period; however, you will continue to enjoy access to the Service for the remainder of the subscription period.

**e. Current Information Required.** YOU MUST PROVIDE CURRENT, COMPLETE AND ACCURATE INFORMATION FOR YOUR BILLING ACCOUNT. YOU MUST PROMPTLY UPDATE ALL INFORMATION TO KEEP YOUR BILLING ACCOUNT CURRENT, COMPLETE AND ACCURATE (SUCH AS A CHANGE IN BILLING ADDRESS, CREDIT CARD NUMBER, OR CREDIT CARD EXPIRATION DATE), AND YOU MUST PROMPTLY NOTIFY FULL PAGE IF YOUR PAYMENT METHOD IS CANCELED (E.G., FOR LOSS OR THEFT) OR IF YOU BECOME AWARE OF A POTENTIAL BREACH OF SECURITY, SUCH AS THE UNAUTHORIZED DISCLOSURE OR USE OF YOUR USER NAME OR PASSWORD. CHANGES TO SUCH INFORMATION CAN BE MADE AT ACCOUNT SETTINGS. IF YOU FAIL TO PROVIDE FULL PAGE ANY OF THE FOREGOING INFORMATION, YOU AGREE THAT FULL PAGE MAY CONTINUE CHARGING YOU FOR ANY USE OF THE SERVICE UNDER YOUR BILLING ACCOUNT UNLESS YOU HAVE TERMINATED YOUR SUBSCRIPTION FOR THE SERVICE (CONFIRMED BY YOU IN WRITING UPON REQUEST BY FULL PAGE).

**f. Reaffirmation of Authorization.** Your non-termination or continued use of the Service reaffirms that Full Page is authorized to charge your Payment Method. Full Page may submit those charges for payment and you will be responsible for such charges. This does not waive Full Page’s right to seek payment directly from you. Your charges may be payable in advance, in arrears, per usage, or as otherwise described when you initially subscribed to the Service.

**g. Free Trial; Promotion.** We may provide a free trial or other promotion (the "Promotion"), which Promotion may provide you access to some or all features afforded to paying members of the Service. In order to avoid being charged a subscription fee for the Service, you must cancel your subscription prior to the termination of the trial period. If you cancel prior to the termination of the trial period yet you are still charged for access to the Service, please contact us at support@gofullpage.com.

**7. Modifications to Service. **Full Page reserves the right at any time to modify or discontinue, temporarily or permanently, the Service (or any part thereof) with or without notice. You agree that Full Page shall not be liable to you or to any third party for any modification, suspension or discontinuance of the Service.

**8. Blocking of IP Addresses. **In order to protect the integrity of the Services, Full Page reserves the right at any time in its sole discretion to block Members from certain IP addresses from accessing the Service.

**9. Term.** This Terms of Use will remain in full force and effect while you use the Service. You may terminate your membership at any time by visiting your Dashboard on Full Page. If you resign or cancel your membership to Full Page, to help Full Page analyze and improve the Service, you may be asked to provide a reason for your resignation/cancellation.

Full Page may terminate your membership for any reason by sending notice to you at the email address you provide in your application for membership, or such other email address as you may later provide to Full Page. All decisions regarding the termination of accounts shall be made in the sole discretion of Full Page. Full Page is not required to provide you notice prior to terminating your membership. Full Page is not required, and may be prohibited, from disclosing a reason for the termination of your account. Even after your membership is terminated, this Terms of Use will remain in effect. All terms that by their nature may survive termination of this Terms of Use shall be deemed to survive such termination.

**10. Third Party Content.** Full Page may provide third party content on the Service and may provide links to webpages and content of third parties (collectively, the “Third-Party Content”) as a service to those interested in this information. Full Page does not control, endorse or adopt any Third-Party Content and makes no representation or warranties of any kind regarding the Third-Party Content, including without limitation regarding its accuracy or completeness. Please be aware that Full Page does not create Third-Party Content, nor does Full Page update or monitor it. Full Page is therefore not responsible for any Third-Party Content on the Service. Users use such Third-Party Content at their own risk.

The Service may include links or references to other web sites or services solely as a convenience to Full Page users (collectively, the “Reference Sites”). Full Page does not endorse any Reference Sites or the information, materials, products, or services contained on or accessible through Reference Sites. In addition, your correspondence or business dealings with, or participation in promotions of, advertisers found on or through the Service are solely between you and the relevant advertiser. Access and use of Reference Sites, including the information, materials, products, and services on or available through Reference Sites is solely at your own risk.

**11. Intellectual Property.** Full Page, the Full Page logos and any other product or service name or slogan contained in the Service are trademarks of Full Page or our suppliers or licensors and may not be copied, imitated or used, in whole or in part, without the prior written permission of Full Page or the applicable trademark holder. Any authorized use of these trademarks must be in accordance with any guidelines that Full Page may provide you from time to time.

Full Page retains all proprietary rights in the Service, except where otherwise noted. The Service contains the copyrighted material, trademarks, and other proprietary information of Full Page, and its licensors. Except where we have given you express written permission, you may not copy, modify, publish, transmit, distribute, perform, display, or sell any such proprietary information. All content on Full Page is proprietary. Except where otherwise specified in this Agreement, all Content is copyrighted material of Full Page and for Full Page Members' use only. Distribution of Content to others is strictly prohibited. You agree that Full Page would be irreparably harmed by any violation or threatened violation of this section and that, therefore, Full Page shall be entitled to an injunction prohibiting you from any violation or threatened violation of this section, without posting bond, in addition to any other right or remedy it may have.

We may provide links to third party websites, and some of the content appearing on Full Page may be supplied by third parties. Full Page has no responsibility for these third party websites nor for their content, which is subject to and governed by the terms of use and/or privacy policies, if any, of the applicable third party content providers.

You may not use any metatags or any other hidden text utilizing “Full Page” or any other name, trademark or product or service name of Full Page without our prior written permission. In addition, the look and feel of the Full Page Service, including all page headers, custom graphics, button icons and scripts, is the service mark, trademark and/or trade dress of Full Page and may not be copied, imitated or used, in whole or in part, without our prior written permission. All other trademarks, registered trademarks, product names and company names or logos mentioned in the Service are the property of their respective owners. Reference to any products, services, processes or other information, by trade name, trademark, manufacturer, supplier or otherwise does not constitute or imply endorsement, sponsorship or recommendation thereof by us or any other affiliation.

The Service is owned and operated by Full Page. Unless otherwise indicated, all content and other materials on the Service, including, without limitation, Full Page’s logos, the visual interfaces, graphics, design, compilation, information, software, computer code (including source code or object code), services, text, pictures, information, data, sound files, other files and the selection and arrangement thereof (collectively, the “Materials”) are protected by United States copyright, trade dress, patent, and trademark laws, international conventions, and all other relevant intellectual property and proprietary rights, and applicable laws (including in your country of residence).

Please note that we do not offer refunds to Members whose accounts are terminated as a result of repeated infringement of these Terms of Use.

**12. Limitation of Liability. **In no event shall Full Page be liable for any damages whatsoever, whether direct, indirect, general, special, compensatory, consequential, and/or incidental, arising out of or relating to the Service, or use thereof. Nothing contained in the Service or in any written or oral communications from Full Page or its employees or agents shall be construed to make any promise, covenant, warranty, or guaranty, all of which are explicitly disclaimed hereby, contrary to the statements and disclaimers contained in this paragraph.

The content and functionality on the Service, along with the services provided by employees of the Service, are offered "as is" without warranty of any kind, either express or implied, including, without limitation, implied warranties of merchantability, fitness for a particular purpose, title and non-infringement. Full Page makes no warranties, express or implied, as to the ownership, accuracy, completeness or adequacy of the Service’s content or that the functionality of the Service will be uninterrupted or error-free or free from virus or third party attack. YOU HEREBY ACKNOWLEDGE THAT YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. UNDER NO CIRCUMSTANCES SHALL FULL PAGE, ITS OFFICERS, OWNERS, EMPLOYEES OR AGENTS AND THEIR RESPECTIVE HEIRS, SUCCESSORS AND ASSIGNS BE LIABLE FOR ANY DAMAGES, INCLUDING DIRECT, INCIDENTAL, PUNITIVE, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES THAT DIRECTLY OR INDIRECTLY RESULT FROM USE OF, OR INABILITY TO USE, THE SERVICE OR THE INFORMATION CONTAINED THEREIN, INCLUDING WITHOUT LIMITATION FOR VIRUSES ALLEGED TO HAVE BEEN OBTAINED FROM THE SERVICE, EVEN IF FULL PAGE HAS BEEN NOTIFIED OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES AND REGARDLESS OF THE THEORY OF LIABILITY.

TO THE GREATEST EXTENT PERMITTED BY LAW, YOU AGREE THAT IN NO EVENT WILL FULL PAGE HAVE ANY LIABILITY, CONTINGENT OR OTHERWISE, FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES IN ANY WAY ARISING OUT OF OR RELATING TO THE AGREEMENT OR THE SERVICE, INCLUDING, BUT NOT LIMITED TO LOST PROFITS, LOST DATA, LOSS OF GOODWILL, COPYRIGHT INFRINGEMENT, WORK STOPPAGE, EQUIPMENT FAILURE OR MALFUNCTION, PERSONAL INJURY, PROPERTY DAMAGE, OR ANY OTHER DAMAGES OR LOSSES, EVEN IF FULL PAGE HAS BEEN ADVISED OF THE POSSIBILITY THEREOF, AND REGARDLESS OF THE LEGAL OR EQUITABLE THEORY (CONTRACT, TORT, STATUTE, INDEMNITY OR OTHERWISE) UPON WHICH SUCH LIABILITY IS BASED.

In the event that any of the foregoing limitations are deemed to be unenforceable, to the greatest extent permitted by law, you agree that the entire aggregate liability of Full Page and sole remedy available to any Member in any case in any way arising out of or relating to the Agreement or the Service shall be limited to monetary damages that in the aggregate may not exceed the greater of \$500.00 or the sum of any amount paid by the Member or user to Full Page during the six months prior to notice to Full Page of the dispute for which the remedy is sought.

**13. Indemnity by You.** You agree to indemnify and hold Full Page, its subsidiaries, affiliates, officers, agents, and other partners and employees, harmless from any loss, liability, claim, or demand, including reasonable attorneys' fees, arising out of or related to:

**a. **your use of the Service in violation of this Terms of Use and/or arising from a breach of this Terms of Use including without limitation your representations and warranties set forth above;

**b. **any third party claim of (i) infringement of copyright or other intellectual property rights, (ii) invasion of privacy, or (iii) any other claim including without limitation that such content is illegal, offensive, or otherwise harmful and damaging, arising from your creation or dissemination of content using the Service, and/or your making available thereof to other users on or outside of the Service, and/or the actual use of such content by other users of the Service or related services in accordance with these Terms of Use;

**c.** any activity related to your account, be it by you or by any other person accessing your account with or without your consent unless such activity was caused by the act or default of Full Page.

**14. Attorney Fees.** In the event that Full Page is successful in whole or in part in any action or proceeding related to or arising from this Terms of Use, you shall be responsible for Full Page’s attorneys' fees and costs.

**15. Parental or Guardian Permission.** Some of the Content on the Service may not be appropriate for children. CHILDREN UNDER THE AGE OF 18 ARE NOT PERMITTED TO USE THE SERVICE UNLESS A SUPERVISING PARENT OR GUARDIAN IS PRESENT. INDIVIDUALS UNDER THE AGE OF 18 ARE NOT PERMITTED TO SUBSCRIBE TO OUR FEE-BASED SERVICES OR GIVE FULL PAGE THEIR EMAIL ADDRESS OR ANY OTHER PERSONALLY IDENTIFIABLE INFORMATION.

**16. Privacy.** Use of the the Service is also governed by our Privacy Policy, located at https://gofullpage.com/privacy.

**17. Jurisdiction and Choice of Law; Dispute Resolution.** If there is any dispute arising out of the Service, by using the Service, you expressly agree that any such dispute shall be governed by the laws of the State of New York, without regard to its conflict of law provisions, and you expressly agree and consent to the exclusive jurisdiction and venue of the state and federal courts of the State of New York, for the resolution of any such dispute. Acceptance of the terms and conditions of this Agreement constitutes your consent to be sued in such courts and to accept service of process outside the State of New York with the same force and effect as if such service had been made within the State of New York. You hereby agree to accept service of process for any action hereunder by certified mail return receipt requested which service shall have the same force and effect as though service had been effected by personal service in the applicable jurisdiction. If any part of these terms is unlawful, void, or unenforceable, that part will be deemed severable and will not affect the validity and enforceability of the remaining provisions.

**18. Arbitration Provision/No Class Action.** Except where prohibited by law, as a condition of using the Service, you agree that any and all disputes, claims and causes of action (collectively, "Claim") arising out of or connected with the Service, shall be resolved individually, without resort to any form of class action, exclusively by binding arbitration under the rules of the American Arbitration Association for full and final settlement of such Claim, and judgment on the award rendered in the arbitration may be entered in any court having jurisdiction thereof. Such arbitration shall be held in accordance with the Rules for Expedited Procedures under the Commercial Arbitration Rules of the American Arbitration Association or other mutually agreeable organization, before a single arbitrator (with knowledge and expertise of copyright law if the claim is all or partially for copyright infringement), selected by agreement of both parties or by an independent mediator (with knowledge and expertise of copyright law if the claim is all or partially for copyright infringement) if the parties are unable to agree. The parties shall split the arbitration and/or mediator costs. An award rendered by the arbitrator(s) may be entered and confirmed by the courts of the State of New York, County of New York, or the U.S. District Court for the Southern District of New York. The parties agree that any post-arbitration action seeking to enforce an arbitration award or action seeking equitable or injunctive relief shall be brought exclusively in the courts of the State of New York, County of New York, or the U.S. District Court for the Southern District of New York.

**19. No Third Party Beneficiaries. **You agree that, except as otherwise expressly provided in this Terms of Use, there shall be no third party beneficiaries to this Terms of Use.

**20. Availability Outside the U.S.** If you access Full Page from outside of the United States, you do so at your own risk and are responsible for compliance with the laws of your jurisdiction. If you access the Service from outside of the United States, you acknowledge that any personal information you provide will be processed in the United States and other geographies as selected by us in our sole discretion, and you hereby consent to the collection and processing of your personal information in a manner consistent with this Agreement and the Privacy Policy.

**21. Entire Agreement.** This Terms of Use, along with the Privacy Policy, contains the entire agreement between you and Full Page regarding the use of the Service.

**22. Severability; Waiver.** If any provision of this Terms of Use is found to be invalid by any court having competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of this Terms of Use, which shall remain in full force and effect. No waiver of any term of this Terms of Use shall be deemed a further or continuing waiver of such term or any other term. In addition, Full Page’s failure to enforce any term of this Terms of Use shall not be deemed as a waiver of such term or otherwise affect Full Page’s ability to enforce such term at any point in the future.

**23. Headings.** The section headings contained in this Terms of Use are for reference purposes only and shall not in any way affect the meaning or interpretation of this Terms of Use.

Please contact us with any questions regarding this agreement.

Full Page reserves all rights not expressly granted in these Terms of Use.

I HAVE READ THIS AGREEMENT AND AGREE TO ALL OF THE PROVISIONS CONTAINED ABOVE.
