import { Moment } from 'moment';
import { Auditable } from './Auditable';
import { Serialized } from './typeutil';

export type PlanInterval = 'year' | 'month' | 'day';

export const ACCOUNT_TYPE_USER = 'user';
export const ACCOUNT_TYPE_ORG = 'org';

export const ACCOUNT_TYPES = [ACCOUNT_TYPE_USER, ACCOUNT_TYPE_ORG] as const;

/**
 * For differentiating requests between a user or org
 */
export type AccountType = (typeof ACCOUNT_TYPES)[number];

export type StripeSubscriptionStatus =
  | 'active'
  | 'trialing'
  | 'past_due'
  | 'canceled'
  | 'unpaid'
  | 'incomplete'
  | 'incomplete_expired';

// ## Account

/**
 * An account.subscription record
 */
export type Subscription = {
  id: string;
  status: StripeSubscriptionStatus;
  trial_start?: Moment;
  trial_end?: Moment;
  current_period_start: Moment;
  current_period_end: Moment;
  cancel_at_period_end: boolean;
  plan: string;
  amount: number;
  interval: 'year' | 'month' | 'day';
  interval_count: number;
};

/**
 * An account.card record
 */
export type Card = {
  name: string;
  brand: string;
  last4: string;
  status: 'canceled' | 'chargeable' | 'consumed' | 'failed' | 'pending';
  is_chargeable: boolean;
};

/**
 * An account.payment record
 */
export type Payment = {
  receipt_url: string;
  amount: number;
  created: Moment;
  nickname: string;
  status: 'succeeded' | 'pending' | 'failed';
  refunded: boolean;
  refunds?: {
    amount: number;
    created: Moment;
    status: 'pending' | 'succeeded';
  }[];
};

/**
 * An account.abTestV2 record
 */
export type AbTestV2 = {
  bucket: number;
  created: number;
  group: 'classic' | 'stripe';
  trial_start?: number;
  sub_start?: number;
  no_track?: boolean;
};

/**
 * An account record (with Moments instead of timestamp numbers)
 */
export type Account = {
  userId: string;
  email?: string;
  orgId?: string;
  orgRole?: OrganizationMemberRole;
  orgStatus?: OrganizationMemberStatus;
  orgName?: string;
  orgAdminEmail?: string;
  customerId?: string;
  status?: StripeSubscriptionStatus;
  subscription?: Subscription;
  card?: Card;
  payments?: Payment[];
  abTestV2?: AbTestV2;
};

/**
 * The Account type returned from the API
 */
export type ApiAccount = Serialized<Account>;

// ## Organization

export type NewOrganization = {
  name: string;
  seats: number;
  adminEmail: string;
  customerId?: string;
  subscription?: Subscription;
  card?: Card;
};

export type ApiOrganization = Serialized<Organization>;

export type Organization = NewOrganization & {
  orgId: string;
} & Auditable;

export type OrganizationMemberStatus =
  | 'invited'
  | 'active'
  | 'deactivated'
  | 'archived';

export enum OrganizationMemberStatuses {
  Invited = 'invited',
  Active = 'active',
  Deactivated = 'deactivated',
  Archived = 'archived',
}

export type OrganizationMemberRole = 'admin' | 'user';

export enum OrganizationMemberRoles {
  Admin = 'admin',
  User = 'user',
}

export type OrganizationMember = Pick<Account, 'userId' | 'email'> & {
  orgId: string;
  orgRole: OrganizationMemberRole;
  orgStatus: OrganizationMemberStatus;
} & Auditable;
