import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import './Emojis.scss';

import hpEmojis from '../../assets/img/hp-emojis.png';
import hpEmojis2x from '../../assets/img/hp-emojis@2x.png';

// import premiumEmojis from '../../assets/img/premium-emojis.png';
// import premiumEmojis2x from '../../assets/img/premium-emojis@2x.png';

import legalEmojis from '../../assets/img/emoji/legal-emojis.png';
import legalEmojis2x from '../../assets/img/emoji/legal-emojis.png';

import dollarEmoji from '../../assets/img/dollar-emoji.png';
import dollarEmoji2x from '../../assets/img/dollar-emoji@2x.png';

import brushEmoji from '../../assets/img/emoji/emoji-brush.png';
import brushEmoji2x from '../../assets/img/emoji/emoji-brush@2x.png';
import paintingEmoji from '../../assets/img/emoji/emoji-painting.png';
import paintingEmoji2x from '../../assets/img/emoji/emoji-painting@2x.png';
import paletteEmoji from '../../assets/img/emoji/emoji-palette.png';
import paletteEmoji2x from '../../assets/img/emoji/emoji-palette@2x.png';

export const TYPE_HP = 'hp';
export const TYPE_PREMIUM = 'premium';
export const TYPE_DOLLAR = 'dollar';
export const TYPE_LEGAL = 'legal';

const TYPES = {
  [TYPE_HP]: {
    imgs: [
      {
        src: hpEmojis,
        src2x: hpEmojis2x,
        width: 187,
        height: 65,
        alt: 'laptop, camera with flash, smiling face',
      },
    ],
  },
  [TYPE_PREMIUM]: {
    style: { paddingBottom: '15px' },
    imgs: [
      // { src: premiumEmojis, src2x: premiumEmojis2x, width: 187, height: 65 }
      {
        src: paletteEmoji,
        src2x: paletteEmoji2x,
        width: 49,
        height: 50,
        alt: 'artist palette',
      },
      {
        src: brushEmoji,
        src2x: brushEmoji2x,
        width: 49,
        height: 50,
        alt: 'paintbrush',
      },
      {
        src: paintingEmoji,
        src2x: paintingEmoji2x,
        width: 49,
        height: 50,
        alt: 'framed picture',
      },
    ],
  },
  [TYPE_DOLLAR]: {
    imgs: [
      {
        src: dollarEmoji,
        src2x: dollarEmoji2x,
        width: 49,
        height: 50,
        alt: 'dollar banknote',
      },
    ],
  },
  [TYPE_LEGAL]: {
    imgs: [
      {
        src: legalEmojis,
        src2x: legalEmojis2x,
        width: 187,
        height: 65,
        alt: 'monitor, scale, documents',
      },
    ],
  },
};

export const EMOJI_TYPE_PROPTYPE = PropTypes.oneOf(Object.keys(TYPES));

const ANIMATION_DURATION = 2; // seconds

class Emojis extends Component {
  static propTypes = {
    type: EMOJI_TYPE_PROPTYPE.isRequired,
    loading: PropTypes.bool.isRequired,
    dim: PropTypes.bool.isRequired,
    space: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    type: TYPE_HP,
    loading: false,
    dim: false,
    space: false,
  };

  render() {
    let { type, loading, dim, space } = this.props;
    type = type;

    const { imgs, style } = TYPES[type];

    const className = classNames('emojis', `emojis-${type}`, {
      loading,
      dim,
      space,
    });

    return (
      <div className={className} style={style}>
        {imgs.map(({ src, src2x, width, height, alt }, i) => (
          <img
            key={i}
            style={{
              verticalAlign: 'middle',
              animationDelay: `${(i / 10) * ANIMATION_DURATION}s`,
              animationDuration: `${ANIMATION_DURATION}s`,
            }}
            className={`emoji-${i + 1}`}
            src={src}
            srcSet={`${src2x} 2x`}
            width={width}
            height={height}
            alt={alt}
          />
        ))}
      </div>
    );
  }
}

export default Emojis;
