import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const AccountFields = ({ fields }) =>
  !fields || fields.length === 0 ? null : (
    <dl className="accountfields">
      {fields.map(({ title, body }, i) => (
        <Fragment key={`${title}-${i}`}>
          <dt>{title}</dt>
          <dd>{body}</dd>
        </Fragment>
      ))}
    </dl>
  );

AccountFields.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node.isRequired,
      body: PropTypes.node.isRequired,
    }).isRequired,
  ),
};

export default AccountFields;
