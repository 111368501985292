import moment from 'moment';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { asErrorMsg } from '../../api';

import { SUPPORT_EMAIL, WEBSTORE_LINK } from '../../constants';
import connectExtension, {
  getExtensionLanding,
} from '../../extension/Provider';
import { log } from '../../logger';
import { getEcommCheckoutSuccess } from '../../utils/ga-ecomm';

import AccountFields from '../AccountFields';
import { CardSection, FormBody, PrimaryButton } from '../Base';
import Dollars from '../Dollars';
import Emojis, { TYPE_PREMIUM } from '../Emojis';
import { connectGTM } from '../GTMListener';
import LoaderDots from '../LoaderDots';
import NewsletterForm from '../NewsletterForm';

import CheckoutStage from './CheckoutStage';

class CheckoutSuccess extends CheckoutStage {
  static propTypes = {
    history: PropTypes.object.isRequired,
    account: PropTypes.object,
    extensionIsConnected: PropTypes.bool.isRequired,
    user: PropTypes.object,
    nextUrl: PropTypes.string,
    clearNextUrl: PropTypes.func.isRequired,
    dataLayerPush: PropTypes.func.isRequired,
    isPopup: PropTypes.bool.isRequired,
    performCheckoutSessionSuccess: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    // TODO - why did I do this Object.assign originally?
    this.state = Object.assign(
      {
        stripeCheckoutErrorMsg: '',
      },
      this.state,
    );
    this.state.newsletterSuccess = false;

    // remember last checkout
    this.lastSearch = null;
    this.lastParams = null;
  }

  // Handlers
  handleNewsletterSuccess = () => {
    this.setState({ newsletterSuccess: true });
  };

  handleReturnToEditor = (evt) => {
    const { clearNextUrl, isPopup } = this.props;
    clearNextUrl();
    if (isPopup) {
      evt.preventDefault();
      window.close();
      return;
    }
  };

  handleStripeCheckoutSuccess = () => {
    this.setState({ stripeCheckoutErrorMsg: '' });

    const sessionId = this._getStripeCheckoutSessionId();

    log(
      '[CheckoutPayment.handleStripeCheckoutSuccess]',
      `sessionId=${sessionId}`,
    );

    if (!sessionId) {
      this.setState({
        stripeCheckoutErrorMsg: `Error missing sessionId (${sessionId})! Please contact ${SUPPORT_EMAIL}`,
      });
      return;
    }

    return this.props
      .performCheckoutSessionSuccess({ session_id: sessionId })
      .then((data) => {
        log('[CheckoutPayment.handleStripeCheckoutSuccess.response]', data);
        // TODO(checkout) - errors for bad session_ids?
        const params =
          this.lastParams || new URLSearchParams(window.location.search);
        params.delete('checkout');
        params.delete('session_id');
        let qs = params.toString();
        qs = qs ? `?${qs}` : '';
        this.props.history.replace(
          window.location.pathname + qs + window.location.hash,
        );
      })
      .catch((err) => {
        this.setState({
          stripeCheckoutErrorMsg: asErrorMsg(err),
        });
      });
  };

  _getStripeCheckoutSessionId() {
    log(`[_getStripeCheckoutSessionId] ${window.location.href}`);
    if (window.location.search !== this.lastSearch || !this.lastParams) {
      this.lastSearch = window.location.search;
      this.lastParams = new URLSearchParams(this.lastSearch);
    }

    const params = this.lastParams;
    const isStripeCheckout = params.get('checkout') === 'success';
    return (isStripeCheckout && params.get('session_id')) || null;
  }

  _getLoader() {
    return (
      <CardSection>
        <FormBody>
          <LoaderDots />
        </FormBody>
      </CardSection>
    );
  }

  // Show Component (like Render)
  showComponent() {
    const { account, user, extensionIsConnected, isPopup } = this.props;
    const now = moment();

    const { stripeCheckoutErrorMsg } = this.state;

    let newsletterElt = null;
    let mainElt = null;

    const stripeCheckoutSessionId = this._getStripeCheckoutSessionId();

    if (!account) {
      // Not logged in yet
      mainElt = this._getLoader();
    } else if (stripeCheckoutSessionId || stripeCheckoutErrorMsg) {
      // Logged in, and has a checkout session!
      mainElt = this._getLoader();
      this.doOnce(
        'handleStripeCheckoutSuccess',
        this.handleStripeCheckoutSuccess,
        true,
      );
    } else if (!account.subscription) {
      // Logged in, but doesn't have subscription yet!
      mainElt = (
        <CardSection>
          No subscription, please{' '}
          <Link to="/checkout">start a fresh checkout.</Link>
        </CardSection>
      );
    } else {
      // Logged in, and has subscription
      const sub = account.subscription;
      const card = account.card;
      const email = (user && user.attributes.email) || '';
      const amount = sub.amount || 99999;
      const taxPercent = sub.tax_percent || 0;
      // NOTE - this is sort of wrong for trials or even a renewed subscription...
      const secondsAgo = now.diff(sub.current_period_start, 'seconds');

      // newsletter elt
      newsletterElt = !this.state.newsletterSuccess && (
        <CardSection className="slim">
          <NewsletterForm
            pClassName="dim"
            btnClassName="btn-convert btn-standout"
            initialEmail={email}
            onSuccess={this.handleNewsletterSuccess}
          />
        </CardSection>
      );

      // main elt

      // // TEST
      // sub.status = 'trialing';
      // let n = moment();
      // sub.trial_start = n.clone().subtract(16, 'seconds');
      // sub.trial_end = sub.trial_start.clone().add(7, 'days');

      let billedMsg;
      if (sub.status === 'trialing') {
        const compareTo =
          now.diff(sub.trial_start, 'seconds') > 15 ? now : sub.trial_start;
        let diff = 0;
        let diffType = '';
        for (let dt of ['days', 'hours', 'minutes', 'seconds']) {
          let exactDiff = sub.trial_end.diff(compareTo, dt, true);
          diff = Math.round(exactDiff);
          diffType = dt;
          if (exactDiff >= 1) {
            break;
          }
        }

        const linkProps = isPopup ? { target: '_blank' } : {};

        billedMsg = (
          <p className="small dim">
            You can cancel at any time from the{' '}
            <Link className="sly" to="/account" {...linkProps}>
              account page
            </Link>
            , if you complete the trial, you will be billed and receive an{' '}
            <span className="nowrap">
              email receipt in {diff}{' '}
              {diff === 1
                ? diffType.substring(0, diffType.length - 1)
                : diffType}
            </span>
          </p>
        );
      }

      const fields = [];

      if (card?.name) {
        fields.push({ title: 'Billing name', body: card.name });
      }

      fields.push(
        { title: 'Billing email', body: email },
        {
          title: 'Billing card',
          body: card ? `${card.brand} ${card.last4}` : 'n/a',
        },
        {
          title: sub.status === 'trialing' ? 'Total at end of trial' : 'Total',
          body: (
            <>
              <Dollars cents={amount} />
              {taxPercent ? (
                ' + tax'
              ) : (
                <span>
                  <br />+ tax where applicable
                </span>
              )}
            </>
          ),
        },
      );

      const { nextUrl, extensionOrigin } = this.props;

      const mainButton = extensionIsConnected ? (
        nextUrl ? (
          <PrimaryButton
            className="btn-convert btn-standout"
            type="link"
            href={nextUrl}
            onClick={this.handleReturnToEditor}
          >
            Return to editor
          </PrimaryButton>
        ) : (
          <PrimaryButton
            className="btn-convert"
            type="link"
            href={getExtensionLanding(extensionOrigin)}
            onClick={this.handleReturnToEditor}
          >
            Go to editor
          </PrimaryButton>
        )
      ) : (
        <PrimaryButton className="" type="link" href={WEBSTORE_LINK}>
          Install extension
        </PrimaryButton>
      );

      mainElt = (
        <CardSection>
          <Emojis type={TYPE_PREMIUM} space={true} />
          {mainButton}
          <AccountFields fields={fields} />
          {billedMsg}
        </CardSection>
      );

      // do gtm logging
      this.doOnce('logSuccessWithAccount', () => {
        // TODO(taxes) - common rounding fn
        const taxCents = parseInt((sub.amount * taxPercent) / 100 + 0.5, 10);
        this.props.dataLayerPush(
          getEcommCheckoutSuccess(
            sub.id,
            sub.amount + taxCents,
            taxCents,
            secondsAgo,
          ),
        );
      });
    }

    return (
      <div className="checkoutsuccess">
        <h2>Success!</h2>
        <p className="dim">
          Thank you so much for supporting{' '}
          <span className="nowrap">GoFullPage!</span>
          <br />
          See your confirmation below.
        </p>
        {newsletterElt}
        {stripeCheckoutErrorMsg && (
          <p className="br error">{stripeCheckoutErrorMsg}</p>
        )}
        {mainElt}
      </div>
    );
  }
}

export default connectExtension(connectGTM(CheckoutSuccess));
