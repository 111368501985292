import PropTypes from 'prop-types';
import { Component } from 'react';

import { getTitle } from '../utils/document';

import CheckoutPage from './CheckoutPage';

import { Helmet } from 'react-helmet';
import './PopupLoginPage.scss';

class PopupLoginPage extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
  }

  // life-cycle
  componentDidMount() {
    // set a class on `html` element for custom document styles
    document.documentElement.classList.add('is-popup');
  }

  componentWillUnmount() {
    document.documentElement.classList.remove('is-popup');
  }

  // helpers
  isSignIn() {
    let search = this.props.location.search;
    return /(^|&)signin(&|$)/.test(search.substring(1));
  }

  // render
  render() {
    const isSignIn = this.isSignIn();
    return (
      <>
        <Helmet>
          <title>{getTitle('Login')}</title>
        </Helmet>
        <CheckoutPage {...this.props} isPopup={true} isSignIn={isSignIn} />
      </>
    );
  }
}

export default PopupLoginPage;
