import PropTypes from 'prop-types';

import { EDITOR_VIDEO } from '../constants';
import { getTitle } from '../utils/document';

import { Helmet } from 'react-helmet';
import './InlineDemoEditorPage.scss';

const InlineDemoEditorPage = (props) => {
  const style = {};
  // onClick={this.handleClick}

  return (
    <div className="inline-demo">
      <Helmet>
        <title>{getTitle('Editor Demo Video')}</title>
      </Helmet>
      <div className="inline-demo--inner">
        <video
          autoPlay={true}
          preload={'auto'}
          controls={true}
          loop={false}
          muted={false}
          playsInline={true}
          style={style}
          poster={EDITOR_VIDEO.poster}
        >
          <source src={EDITOR_VIDEO.srcMp4} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

InlineDemoEditorPage.propTypes = {
  history: PropTypes.object.isRequired, // via react-router
};

// export default connectGTM(InlineDemoEditorPage);

export default InlineDemoEditorPage;
