import React, { Component } from 'react';

import {
  Button,
  Container,
  Form,
  Input,
  Label,
  FormGroup,
  FormButtons,
  CardSection,
  CardSectionMinimal,
  PrimaryButton,
  Section,
} from '../components/Base';

class StyleGuidePage extends Component {
  render() {
    return (
      <Section className="first-sec">
        <Container>
          <CardSectionMinimal>
            <h1>Style Guide</h1>
          </CardSectionMinimal>
          <CardSection>
            <h2>Style Guide</h2>
            <h3>Style Guide</h3>
            <h4>Style Guide</h4>
            <h5>Style Guide</h5>
          </CardSection>
          <CardSection>
            <Button>Button</Button>
            <br />
            <br />
            <PrimaryButton>PrimaryButton</PrimaryButton>
            <br />
            <br />
            <PrimaryButton className="btn-convert">PrimaryButton</PrimaryButton>
            <br />
            <br />
            <Button className="btn-standout">Button.btn-standout</Button>
            <br />
            <br />
            <PrimaryButton className="btn-standout">
              PrimaryButton.btn-standout
            </PrimaryButton>
            <br />
            <br />
            <PrimaryButton className="btn-standout btn-convert">
              PrimaryButton
            </PrimaryButton>
            <br />
            <br />
          </CardSection>
          <CardSection>
            <Form>
              <FormGroup>
                <Label>Label</Label>
                <Input placeholder="Something here" />
              </FormGroup>
              <FormButtons>
                <PrimaryButton>Primary</PrimaryButton>
                <Button>Secondary</Button>
              </FormButtons>
            </Form>
          </CardSection>
        </Container>
      </Section>
    );
  }
}

export default StyleGuidePage;
