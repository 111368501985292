import { useCallback, useEffect, useMemo, useState } from 'react';
import { MdArticle, MdDownload, MdSettings } from 'react-icons/md';
import { useToggle } from '../../../common/hooks';
import { Image, Maybe } from '../../../common/types';
import { HeaderSvgAction } from '../../../components/header/actions';
import ImageModal, {
  CreateImageModalContent,
  ViewImageModalContent,
} from '../../../components/ImageModal';
import { useImage } from './use-images';

export default function useImageModal(): [
  JSX.Element,
  SelectImageId,
  SelectImageFile,
] {
  const [imageUpload, closeUpload, selectUploadImage, imageFile] =
    useImageUpload();
  const [imageView, closeEdit, viewImage] = useImageView();

  const [image, close] = useMemo<[EditImage | undefined, () => void]>(() => {
    if (imageUpload) return [imageUpload, closeUpload] as any;
    if (imageView) return [imageView, closeEdit] as any;
    return [undefined, () => {}];
  }, [imageUpload, closeUpload, imageView, closeEdit]);

  const isViewing = !!imageView,
    isUploading = !!imageFile;

  const [showSidebar, toggleSidebar] = useToggle(true);

  const title = useStateSticky(
    isViewing ? 'View Image' : isUploading ? 'Upload Image' : '',
  );
  const actions = (
    <>
      <HeaderSvgAction title="Download" icon={<MdDownload />} />
      <HeaderSvgAction title="Images" icon={<MdArticle />} highlight />
      <HeaderSvgAction title="Settings" icon={<MdSettings />} highlight />
    </>
  );

  const modal = (
    <ImageModal
      image={image}
      close={close}
      headerProps={{ title, children: actions }}
      showSidebar={showSidebar}
      toggleSidebar={toggleSidebar}
    >
      {isViewing && (
        <ViewImageModalContent image={imageView as any} close={close} />
      )}
      {isUploading && (
        <CreateImageModalContent image={imageFile} close={close} />
      )}
    </ImageModal>
  );

  return [modal, viewImage, selectUploadImage];
}

/** Like 'useState' but only updates to new 'truthy' values. Good to keep values around that might be empty. */
function useStateSticky<T>(value: T): T {
  const [val, setVal] = useState(value);
  useEffect(() => void setVal((_val) => value || _val), [value]);
  return val;
}

export type SelectImageFile = (image: File) => void;
export type SelectImageId = (imageId: string) => void;
type EditImage = Partial<Image>;

function useImageView(): [Maybe<EditImage>, () => void, SelectImageId] {
  const [imageId, setImageId] = useState<string | undefined>();
  const selectedImage = useImage(imageId);

  const close = useCallback(() => setImageId(undefined), []);
  return [selectedImage, close, setImageId];
}

function useImageUpload(): [
  Maybe<EditImage>,
  () => void,
  SelectImageFile,
  Maybe<File>,
] {
  const [imageFile, setImageFile] = useState<File>();
  const imageUrl = useImageDataUrl(imageFile);

  const cancel = useCallback(() => setImageFile(undefined), []);

  const image = imageUrl ? ({ urlFull: imageUrl } as EditImage) : undefined;
  return [image, cancel, setImageFile, imageFile];
}

function useImageDataUrl(image: File | undefined) {
  const [imageUrl, setImageUrl] = useState<string>();
  useEffect(() => {
    if (image) {
      const imageUrl = URL.createObjectURL(image);
      setImageUrl(imageUrl);
      return () => URL.revokeObjectURL(imageUrl);
    } else {
      setImageUrl(undefined);
    }
  }, [image]);
  return imageUrl;
}
