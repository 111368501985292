import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { Error, TypographyCss } from './typography';

type Props = HTMLAttributes<HTMLTextAreaElement> & {
  value: string | null | undefined;
  onValueChange: (val: string) => void;
  error?: string;
  disabled?: boolean;
};

const StyledTextArea = styled.textarea`
  ${TypographyCss}
`;

const Textarea: React.FC<Props> = ({
  value,
  onValueChange,
  error,
  disabled,
  ...props
}) => (
  <div>
    <StyledTextArea
      {...props}
      disabled={disabled}
      rows={3}
      className={
        // TODO - use classNames or clsx
        'form-control' +
        ' block' +
        ' w-full' +
        ' p-[8px]' +
        ' bg-white bg-clip-padding' +
        ' border border-solid border-gray-300' +
        ' rounded' +
        ' transition' +
        ' ease-in-out' +
        ' m-0' +
        ' focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none' +
        props.className
      }
      value={value ?? undefined}
      onChange={(e) => onValueChange(e.target.value)}
    />
    {error && <Error>{error}</Error>}
  </div>
);

Textarea.displayName = 'Textarea';

export default Textarea;
