import { useCallback } from 'react';

export default function useSelectImage(onSelect: (file: File) => void) {
  return useCallback(
    async (e: ImageSelectEvent) => {
      const image = getSelectImage(e);
      if (image) onSelect(image);
    },
    [onSelect],
  );
}

type ImageSelectEvent = React.ChangeEvent<HTMLInputElement>;

function getSelectImage(e: ImageSelectEvent): File | null {
  if (e.target.files && e.target.files.length > 0) {
    const file = e.target.files[0];
    if (/^image\//.test(file.type)) {
      return file;
    }
  }
  return null;
}
