import React from 'react';
import styled from 'styled-components';
import { useAccountContext } from '../context/AccountContext';
import { Payment } from '../types/Account';
import Dollars from './Dollars';
import Divider from './atoms/divider';
import { AccentLink, Disclaimer, Label, Text, Value } from './atoms/typography';
import { EnterpriseSupportEmailLink } from './organisms/support-email-link';
import { EnterpriseDashboardPage } from './templates/EnterpriseDashboardLayout';

type PaymentRowProps = {
  payment: Payment;
};
const DateLabel = styled(Label)`
  font-weight: 500;
`;

const PaymentRow: React.FC<PaymentRowProps> = ({ payment }) => (
  <div>
    <DateLabel>
      {payment.created.toDate().toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZoneName: 'short',
      })}
    </DateLabel>
    <Value>{payment.nickname}</Value>
    <Text>
      <Dollars cents={payment.amount} />{' '}
      <AccentLink href={payment.receipt_url}>(receipt)</AccentLink>
    </Text>
  </div>
);

const Container = styled.div`
  > * {
    margin-bottom: 24px; // TODO set as a variable
  }
`;

const EnterpriseAccountPayments: React.FC = () => {
  const { account, organization, loading } = useAccountContext();

  const payments = account?.payments;

  return (
    <EnterpriseDashboardPage
      title="💵 Account"
      subTitle="Payment history"
      loading={loading}
    >
      <Container>
        {payments?.length ? (
          payments.map((payment) => (
            <PaymentRow
              key={payment.created?.toISOString()}
              payment={payment}
            />
          ))
        ) : (
          <Value>No payment history.</Value>
        )}
        <Divider />
        <Disclaimer>
          Manage your payment method from the{' '}
          <AccentLink href="/enterprise/account/overview">Overview</AccentLink>{' '}
          page.
          <br />
          Contact us at{' '}
          <EnterpriseSupportEmailLink organization={organization} /> to make any
          changes or for further assistance.
        </Disclaimer>
      </Container>
    </EnterpriseDashboardPage>
  );
};

export default EnterpriseAccountPayments;
