import { Hub } from '@aws-amplify/core';

import { HUB_CHANNEL as API_HUB_CHANNEL } from '../api/constants';
import { genColorLog, log } from '../logger';

import {
  HUB_CHANNEL as EXTENSION_HUB_CHANNEL,
  EVENT_LOGIN_SUCCESS,
} from './constants';

const STYLE = 'color:#F60;background:#FFF6EE;';
const colorLog = genColorLog(STYLE);

export const loginUser = (
  postmateChild,
  userId,
  expiresUnixTimestamp,
  status,
  showExperimental,
) => {
  // data needs:
  // * token
  // * expires
  // * status

  // TODO - is this partially duplicating work from ExtensionAPIServer.js -> authState call?

  const data = {
    userId,
    expires: expiresUnixTimestamp,
    status: status || '',
  };

  // HACK - add show_experimental
  const tokenKey = 'showExperimental';
  const token = window.location.search
    .substring(1)
    .split('&')
    .filter((x) => x === tokenKey || x.startsWith(tokenKey + '='))[0];
  if (token) {
    const sp = token.split('=');
    const tokenVal = sp[1];
    const show_experimental =
      !tokenVal ||
      ['t', '1', 'y'].indexOf(tokenVal.substring(0, 1).toLowerCase()) !== -1;
    data.show_experimental = show_experimental;
  }

  // there is no callback or Promise for Postmate.call
  postmateChild.call('login', data);
};

export default class LoginListener {
  constructor(postmateProm) {
    Hub.listen(API_HUB_CHANNEL, this.onHubCapsule, 'LoginListener');
    this.postmateProm = postmateProm;
  }

  destroy() {
    Hub.remove(API_HUB_CHANNEL, this.onHubCapsule);
  }

  // hub listener
  onHubCapsule = ({ channel, payload, source }) => {
    //
    if (channel === API_HUB_CHANNEL) {
      const object = payload.data && payload.data.object;
      if (object && payload.data.pathPrefix === 'account') {
        colorLog('[LoginListener.onHubCapsule]', channel, payload, source);

        const sub = object && object.subscription;
        const userId = object.userId;
        const expiresUnixTimestamp = sub ? sub.current_period_end.unix() : 0;
        const status = sub ? sub.status : '';

        this.postmateProm
          .then((postmateChild) => {
            // setup login-success handler - 'login-success' HARDCODED in extension
            // TODO - "once" this, so it doesn't pile up and execute a bunch of times
            postmateChild.on('login-success', (data) => {
              colorLog(
                '[LoginListener.onHubCapsule.postmateProm.login-success]',
                data,
              );
              // status string is from Stripe - using "subOk" logic from extension
              if (data.status && data.status !== 'canceled') {
                Hub.dispatch(
                  EXTENSION_HUB_CHANNEL,
                  { event: EVENT_LOGIN_SUCCESS, data },
                  'LoginListener',
                );
              }
            });

            // execute the remote login call to the extension
            colorLog('{{update?}}', userId, expiresUnixTimestamp, status);
            loginUser(postmateChild, userId, expiresUnixTimestamp, status);
          })
          .catch((err) => {
            log.error('[LoginListener.onHubCapsule.postmateProm.error]', err);
          });
      }
    }
  };
}
