import React from 'react';
import styled from 'styled-components';
import Loading from '../UpdateCardWidget/Loading';
import { TypographyCss } from '../atoms/typography';

type TableProps = React.HtmlHTMLAttributes<HTMLTableElement> & {
  headers: string[];
  rows: TableRow[];
  loading?: boolean;
};

type TableRow = {
  id: string;
  cells: (string | React.ReactNode)[];
};

const padding = 8;

const Th = styled.th`
  ${TypographyCss};
  font-weight: 700;
  padding-bottom: ${2 * padding}px;
`;

const Td = styled.td`
  ${TypographyCss};
  color: #151515;
`;

const Tr = styled.tr`
  height: 32px;
  padding: 4px 0;
  :hover {
    background-color: rgba(48, 197, 148, 0.25);
  }
`;

export const Table: React.FC<TableProps> = ({
  headers,
  rows,
  loading = false,
}) => (
  <table className="w-full table-auto border-collapse">
    <thead>
      <tr>
        {headers.map((header) => (
          <Th key={header} className="border-b text-left">
            {header}
          </Th>
        ))}
      </tr>
    </thead>
    {loading ? (
      <Loading />
    ) : (
      <tbody>
        {rows.map((row) => (
          <Tr key={row.id}>
            {row.cells.map((cell) => (
              <Td key={row.id + cell} className="border text-left">
                {cell}
              </Td>
            ))}
          </Tr>
        ))}
      </tbody>
    )}
  </table>
);

export default Table;
