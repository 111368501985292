import classNames from 'classnames';
import { FunctionComponent } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { STATS } from '../constants';
import { getThemeVariant } from '../utils/theme-util';
import Star from './icons/Star';

const emojiGlasses =
  require('../../assets/img/emoji/emoji-glasses-16.png') as string;
const emojiGlasses2x =
  require('../../assets/img/emoji/emoji-glasses-16@2x.png') as string;
const emojiHeartEyes =
  require('../../assets/img/emoji/emoji-heart-eyes-16.png') as string;
const emojiHeartEyes2x =
  require('../../assets/img/emoji/emoji-heart-eyes-16@2x.png') as string;

const YEAR = new Date().getFullYear();

type FooterLink =
  | { text: string; type: 'external'; href: string; target?: '_blank' }
  | { text: string; type: 'internal'; to: string };

type FooterLinkGroup = {
  title: string;
  links: FooterLink[];
};

type FooterLinks = FooterLinkGroup[];

const FOOTER_LINKS: FooterLinks = [
  {
    title: 'Get GoFullPage',
    links: [
      {
        text: 'GoFullPage for Chrome',
        type: 'external',
        href: 'https://chrome.google.com/webstore/detail/full-page-screen-capture/fdpohaocaechififmbbbbbknoalclacl?hl=en-US',
        target: '_blank',
      },
      {
        text: 'GoFullPage for Edge',
        type: 'external',
        href: 'https://microsoftedge.microsoft.com/addons/detail/gofullpage-full-page-sc/hfaciehifhdcgoolaejkoncjciicbemc',
        target: '_blank',
      },
    ],
  },
  {
    title: 'Learn More',
    links: [
      { text: 'Blog', type: 'external', href: 'http://blog.gofullpage.com/' },
      { text: 'Go Premium', type: 'internal', to: '/premium' },
      { text: 'Go Enterprise', type: 'internal', to: '/enterprise' },
    ],
  },
  {
    title: 'Company',
    links: [
      { text: 'Press', type: 'internal', to: '/press' },
      { text: 'About Us', type: 'internal', to: '/about' },
    ],
  },
  {
    title: 'Resources',
    links: [
      { text: 'FAQ', type: 'internal', to: '/faq' },
      { text: 'Privacy policy', type: 'internal', to: '/privacy' },
      { text: 'Terms of Use', type: 'internal', to: '/terms-of-use' },
      { text: 'Accessibility', type: 'internal', to: '/accessibility' },
    ],
  },
  {
    title: 'Connect',
    links: [
      { text: 'Contact Us', type: 'internal', to: '/contact' },
      {
        text: 'Facebook',
        type: 'external',
        href: 'https://www.facebook.com/GoFullPageOfficial/',
        target: '_blank',
      },
      {
        text: 'Twitter',
        type: 'external',
        href: 'https://x.com/gofullpage',
        target: '_blank',
      },
      {
        text: 'LinkedIn',
        type: 'external',
        href: 'https://www.linkedin.com/company/gofullpage',
        target: '_blank',
      },
    ],
  },
];

type FooterProps = {};

const Footer: FunctionComponent<FooterProps> = () => {
  const location = useLocation();
  return (
    <footer
      className={classNames(
        'bg-white text-sm',
        getThemeVariant(location.pathname) === 'enterprise-dash' &&
          'border-0 border-t border-solid border-t-gray-200',
      )}
    >
      <div className="flex flex-wrap justify-start gap-y-5 px-5 py-10 text-left md:flex-nowrap md:gap-5">
        {FOOTER_LINKS.map(({ title, links }, i) => (
          <div
            key={i}
            className={classNames(
              'w-1/2 font-normal text-gray-400 sm:w-1/3 md:w-full md:basis-full',
              {
                'pr-2 sm:pr-0': i % 2 == 0,
                'pl-2 sm:pl-0': i % 2 == 1,
              },
            )}
          >
            <div className="mb-3 font-bold">{title}</div>
            <div className="space-y-1">
              {links.map((link, j) => {
                const className =
                  'text-gray-400 hover:text-gray-600 active:text-gray-600';
                return (
                  <div key={j} className="">
                    {link.type === 'internal' ? (
                      <Link className={className} to={link.to}>
                        {link.text}
                      </Link>
                    ) : (
                      <a
                        className={className}
                        href={link.href || '#'}
                        target={link.target}
                        rel={
                          link.target === '_blank'
                            ? 'noopener noreferrer'
                            : undefined
                        }
                      >
                        {link.text}
                      </a>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
      <ul className="m-0 flex h-12 items-center justify-between gap-5 bg-gradient-to-r from-[#30C594DA] to-[#36B6E6DA] px-5 text-white">
        <li className="mr-auto hidden min-w-[160px] text-left md:block">
          GoFullPage
        </li>
        <li>
          {_img(emojiGlasses, emojiGlasses2x, 'smiling face with sunglasses')}{' '}
          {STATS.users} users
        </li>
        <li>
          {_img(
            emojiHeartEyes,
            emojiHeartEyes2x,
            'smiling face with heart-eyes',
          )}{' '}
          {STATS.ratings} ratings
        </li>
        <li>
          <Star /> {STATS.stars} stars
        </li>
        <li className="ml-auto hidden min-w-[160px] text-right md:block">
          {YEAR} &copy; Full Page, LLC
        </li>
      </ul>
      <div className="flex justify-between px-5 py-5 md:hidden">
        <div className="">GoFullPage</div>
        <div className="">{YEAR} &copy; Full Page, LLC</div>
      </div>
    </footer>
  );
};

Footer.displayName = 'Footer';

export default Footer;

//

const _img = (src: string, src2x: string, alt: string) => (
  <img
    src={src}
    srcSet={`${src2x} 2x`}
    style={{ verticalAlign: 'middle', paddingBottom: '0.4rem' }}
    alt={alt}
  />
);
