import React from 'react';
import styled from 'styled-components';
import CloseIcon from '../icons/close-icon';

export type Severity = 'error' | 'warning' | 'info' | 'success';

type Props = {
  title?: string;
  severity?: Severity;
  onClose?: () => void;
};

const Container = styled.div<{ palette: Palette }>`
  display: flex;
  padding: 6px 16px;
  border: 1px ${(props) => props.palette.border} solid;
  border-radius: 4px;
  background: ${(props) => props.palette.background};
  color: ${(props) => props.palette.text};
  justify-content: space-between;
  align-items: center;
`;

const CloseIconContainer = styled.div`
  cursor: pointer;
`;
type Palette = {
  border: string;
  text: string;
  background: string;
};
const SeverityColors: Record<Severity, Palette> = {
  error: {
    border: '#C73131',
    text: '#C73131',
    background: '#FDF5F5',
  },
  warning: {
    border: 'orange',
    text: 'orange',
    background: 'FDF5F5',
  },
  info: {
    border: '#36B6E6',
    text: '#36B6E6',
    background: 'white',
  },
  success: {
    border: '#2E4D51',
    text: '#2E4D51',
    background: '#CBF0E4',
  },
};

const Alert: React.FC<Props> = ({
  title,
  severity = 'info',
  onClose,
  children,
}) => (
  <Container className="space-x-4" palette={SeverityColors[severity]}>
    <div>
      {title && <strong>{title} </strong>}
      {children}
    </div>
    {onClose && (
      <CloseIconContainer onClick={onClose}>
        <CloseIcon color={SeverityColors[severity].text} />
      </CloseIconContainer>
    )}
  </Container>
);

export default Alert;
