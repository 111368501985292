import React from 'react';

import { Button, FormButtons } from '../Base';

export default ({
  prevStage,
  nextStage,
  goToStage,
  prevProps = {},
  nextProps = {},
  nextIsLast = false,
  isSubmitting = false,
}) => {
  return (
    <FormButtons reverse={!!nextStage}>
      {nextStage && (
        <Button
          className={nextIsLast ? 'btn-convert' : ''}
          primary={true}
          onClick={(evt) => goToStage(nextStage)}
          disabled={isSubmitting}
          {...nextProps}
        >
          {isSubmitting
            ? 'Loading…'
            : nextStage.submitText || `Next (${nextStage.name})`}
        </Button>
      )}
      {prevStage && (
        <Button
          className="btn-minimal"
          onClick={(evt) => goToStage(prevStage)}
          disabled={isSubmitting}
          {...prevProps}
        >
          Go back to {prevStage.name.toLowerCase()}
        </Button>
      )}
    </FormButtons>
  );
};
