import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { acceptInvite, declineInvite, getInviteDetails } from '../api';
import {
  handleSignInUser,
  handleSignInUserWithCredentials,
  handleSignOutUser,
} from '../auth/AuthProvider';
import {
  CardSection,
  Container,
  Form,
  FormBody,
  Input,
  Label,
  Section,
} from '../components/Base';
import Loading from '../components/UpdateCardWidget/Loading';
import Button from '../components/atoms/button';
import { Disclaimer } from '../components/atoms/typography';
import Alert from '../components/molecules/alert';
import { EnterpriseSupportEmailLink } from '../components/organisms/support-email-link';
import { QS_ORG_ACCOUNT_SUCCESS } from '../constants';
import { useAccountContext } from '../context/AccountContext';
import { AlertManager, AlertManagerProvider } from '../context/AlertManager';
import { useBoolean } from '../hooks/useBoolean';
import {
  Organization,
  OrganizationMember,
  OrganizationMemberStatuses,
} from '../types/Account';
import { User } from '../types/User';
import { insertUrlParam } from '../utils/url-util';
import { isValidPassword, noop } from '../utils/utils';
import NoMatchPage from './NoMatchPage';

export type SuccessOrgLinkParams = {
  org?: string;
  email?: string;
};

const LoadingContainer = styled.div`
  padding: 0 24px;
`;

const UnCenteredCardSection = styled(CardSection)`
  text-align: left !important;
`;

type RedeemNewAccountOnAcceptOpts = {
  newAccountPassword?: string;
};
type RedeemAccountProps = {
  invitedUserEmail: string;
  orgName: string;
  xhrPending: boolean;
  accepting: boolean;
  onAccept: () => void;
  declining: boolean;
  onDecline: () => void;
};
type RedeemNewAccountProps = RedeemAccountProps & {
  onAccept: (opts: RedeemNewAccountOnAcceptOpts) => void;
};

type DeclineDisclaimerProps = {
  declining: boolean;
  onDecline: () => void;
};
const DeclineDisclaimer: React.FC<DeclineDisclaimerProps> = ({
  declining,
  onDecline,
}) => (
  <Disclaimer>
    This invite was sent to me in error.{' '}
    <a
      onClick={(e) => {
        e.preventDefault();
        return onDecline();
      }}
      aria-disabled={declining}
      href="#"
    >
      Decline invite
    </a>
  </Disclaimer>
);

const RedeemNewAccount: React.FC<RedeemNewAccountProps> = ({
  invitedUserEmail,
  orgName,
  xhrPending,
  accepting,
  onAccept,
  declining,
  onDecline,
}) => {
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [isDirty, setIsDirty] = useState(false);

  const passwordsValid =
    password &&
    isValidPassword(password) &&
    (!password2 || (isValidPassword(password2) && password === password2));

  return (
    <Container>
      <h1>Redeem invite for your new account</h1>
      <UnCenteredCardSection>
        <h3>You’ve been invited to join {orgName}</h3>
        <p>
          Your account <b>{invitedUserEmail}</b> will be linked to this
          organization.
        </p>
        <Form
          stacked
          onSubmit={(e: Event) => {
            e.preventDefault();
            if (!isDirty) {
              setIsDirty(true);
            }
            if (xhrPending || !passwordsValid) {
              return;
            }
            onAccept({ newAccountPassword: password });
          }}
        >
          <input
            type="text"
            name="email"
            value={invitedUserEmail}
            autoComplete="username email"
            onChange={noop}
            style={{ display: 'none' }}
          />
          <FormBody>
            <div className="space-y-6">
              <div>
                <Label htmlFor="password">Create password</Label>
                <Input
                  name="password"
                  type="password"
                  autoComplete="new-password"
                  value={password}
                  onChange={(e: any) => setPassword(e.target.value)}
                  style={{ width: '100%' }}
                  onBlur={() => {
                    if (
                      !isDirty &&
                      password &&
                      (password2 || !isValidPassword(password))
                    ) {
                      setIsDirty(true);
                    } else if (
                      isDirty &&
                      !password2 &&
                      isValidPassword(password)
                    ) {
                      setIsDirty(false);
                    }
                  }}
                />
              </div>
              <div>
                <Label htmlFor="password2">Enter password again</Label>
                <Input
                  name="password2"
                  type="password"
                  autoComplete="new-password"
                  value={password2}
                  onChange={(e: any) => setPassword2(e.target.value)}
                  style={{ width: '100%' }}
                  onBlur={() => {
                    if (
                      !isDirty &&
                      password2 &&
                      (password || !isValidPassword(password2))
                    ) {
                      setIsDirty(true);
                    }
                  }}
                />
                {isDirty && !passwordsValid && (
                  <p style={{ color: 'red' }}>
                    Password must be 8+ characters long and include both
                    lowercase and uppercase letters.
                  </p>
                )}
              </div>
            </div>
          </FormBody>
          <div className="flex flex-col">
            <Button
              loading={accepting}
              disabled={xhrPending || !passwordsValid}
              type="submit"
            >
              Complete your account
            </Button>
          </div>
        </Form>
      </UnCenteredCardSection>
      <DeclineDisclaimer declining={declining} onDecline={onDecline} />
    </Container>
  );
};

type RedeemUnauthenticatedAccountProps = {
  invitedUserEmail: string;
  orgName: string;
  xhrPending: boolean;
  onSignIn: (event: React.MouseEvent) => void;
};
const RedeemExistingUnauthenticatedAccount: React.FC<
  RedeemUnauthenticatedAccountProps
> = ({ invitedUserEmail, orgName, xhrPending, onSignIn }) => (
  <Container>
    <h1>Redeem invite</h1>
    <UnCenteredCardSection>
      <h3>You’ve been invited to join {orgName}</h3>
      <p>
        Please sign in as <b>{invitedUserEmail}</b> in order to manage this
        invite.
      </p>
      <div className="flex flex-col">
        <Button loading={xhrPending} disabled={xhrPending} onClick={onSignIn}>
          Sign in
        </Button>
      </div>
    </UnCenteredCardSection>
  </Container>
);

const RedeemExistingAccount: React.FC<RedeemAccountProps> = ({
  invitedUserEmail,
  orgName,
  xhrPending,
  accepting,
  onAccept,
  declining,
  onDecline,
}) => (
  <Container>
    <h1>Redeem invite</h1>
    <UnCenteredCardSection>
      <h3>You’ve been invited to join {orgName}</h3>
      <p>
        Your account <b>{invitedUserEmail}</b> will be linked to this
        organization.
      </p>
      <div className="flex flex-col">
        <Button loading={accepting} disabled={xhrPending} onClick={onAccept}>
          Accept
        </Button>
      </div>
    </UnCenteredCardSection>
    <DeclineDisclaimer declining={declining} onDecline={onDecline} />
  </Container>
);

const InviteNotFoundState: React.FC = () => (
  <Container>
    <h1>Redeem account: invite not found</h1>
    <UnCenteredCardSection>
      This invite was not found or may have expired. Contact your group
      administrator or{' '}
      <EnterpriseSupportEmailLink subject="Help with Lost Invite" /> if you need
      more help.
    </UnCenteredCardSection>
  </Container>
);

type InviteDeclinedStateProps = {
  orgName: string;
  orgAdminEmail: string;
};
const InviteDeclinedState: React.FC<InviteDeclinedStateProps> = ({
  orgName,
  orgAdminEmail,
}) => (
  <Container>
    <h1>Redeem account</h1>
    <UnCenteredCardSection>
      Your invite to <b>{orgName}</b> has been declined. If this was a mistake,
      please reach out to the group's administrator:{' '}
      <a href={`mailto:${orgAdminEmail}?subject=Help with invite declined`}>
        {orgAdminEmail}
      </a>
    </UnCenteredCardSection>
  </Container>
);

type ImpersonatingUserStateProps = {
  authenticatedEmail: string;
  invitedUserEmail: string;
  signOut: (event: React.MouseEvent) => void;
};
const ImpersonatingUserState: React.FC<ImpersonatingUserStateProps> = ({
  authenticatedEmail,
  invitedUserEmail,
  signOut,
}) => (
  <Container>
    <h1>Redeem account: different credentials</h1>
    <UnCenteredCardSection>
      <p>
        You are currently logged in as <b>{authenticatedEmail}</b>, but this
        invite is meant for <b>{invitedUserEmail}</b>. Please sign out before
        continuing.
      </p>
      <div className="flex flex-col space-y-6">
        <Button onClick={signOut}>Sign out</Button>
      </div>
    </UnCenteredCardSection>
  </Container>
);

const InvitesPage: React.FC = () => {
  const { alert, setAlert } = useContext(AlertManager);
  const { account, loading: loadingAccount } = useAccountContext();
  const { push, location, replace } = useHistory();

  const [sub, token] = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return [params.get('sub'), params.get('token')];
  }, [location.search]);

  const [loadingDetails, toggleLoadingDetails] = useBoolean(true);

  const [accepting, toggleAccepting] = useBoolean(false);
  const [declining, toggleDeclining] = useBoolean(false);
  const [declined, toggleDeclined] = useBoolean(false);
  const [invitedUser, setInvitedUser] = useState<User>();
  const [org, setOrg] = useState<Organization>();
  const [orgAdminEmail, setOrgAdminEmail] = useState<string>();

  const loading = loadingDetails || loadingAccount;
  const invalidUrl = !sub || !token;
  const impersonatingUser =
    !!account && !!invitedUser && account.email !== invitedUser.email;
  const xhrPending = accepting || declining;
  const mustCompleteAccount = !account && invitedUser?.type === 'temp';

  // Redirect active member (this also gets
  // triggered by a user signing up with an
  // invite)
  useEffect(() => {
    const authenticatedMember = account as OrganizationMember;
    const alreadyActiveMember =
      !!authenticatedMember &&
      authenticatedMember.email === invitedUser?.email &&
      authenticatedMember.orgId === org?.orgId &&
      authenticatedMember.orgStatus === OrganizationMemberStatuses.Active;

    if (alreadyActiveMember) {
      replace(`/account?${QS_ORG_ACCOUNT_SUCCESS}`);
    }
  }, [account, invitedUser, org]);

  // Fetch invite details
  useEffect(() => {
    if (sub && token)
      getInviteDetails(sub, token)
        .then((response) => {
          setInvitedUser(response.user);
          setOrg(response.organization);
        })
        .catch(console.error)
        .finally(toggleLoadingDetails);
  }, [sub, token]);

  const gotoAccount = () => push(`/account?${QS_ORG_ACCOUNT_SUCCESS}`);

  const accept = (opts: RedeemNewAccountOnAcceptOpts = {}) => {
    if (!xhrPending) {
      toggleAccepting();
      acceptInvite(sub, token, opts.newAccountPassword ?? null)
        .then(() => {
          // Add query params so the redirect to account page can show a success alert
          insertUrlParam(QS_ORG_ACCOUNT_SUCCESS, '');

          // If already signed in, just redirect to account page
          if (account) {
            return gotoAccount();
          }

          return opts?.newAccountPassword
            ? handleSignInUserWithCredentials(
                invitedUser!.email,
                opts?.newAccountPassword,
              )
            : handleSignInUser();
        })
        .catch(() =>
          setAlert({
            title: 'Unable to process the invite',
            message:
              'Please try again and contact your admin if the error persists.',
            severity: 'error',
          }),
        )
        .finally(toggleAccepting);
    }
  };

  const decline = () => {
    if (!xhrPending && !declined) {
      toggleDeclining();
      (confirm('Are you sure you want to decline this invite?')
        ? declineInvite(sub, token)
            .then((response) => setOrgAdminEmail(response.orgAdminEmail))
            .then(toggleDeclined)
            .catch(() =>
              setAlert({
                title: 'Unable to process the invite',
                message:
                  'Please try again or contact us at contact@gofullpage.com.',
                severity: 'error',
              }),
            )
        : Promise.resolve()
      ).finally(toggleDeclining);
    }
  };

  return invalidUrl ? (
    <NoMatchPage />
  ) : loading ? (
    <LoadingContainer>
      <Loading />
    </LoadingContainer>
  ) : (
    <>
      {alert && (
        <div className="top-65 sticky mx-auto max-w-[480px]">
          <Alert
            title={alert.title}
            severity={alert.severity}
            onClose={() => setAlert(undefined)}
          >
            {alert.message}
          </Alert>
        </div>
      )}
      <Section className="account">
        {!invitedUser || !org ? (
          <InviteNotFoundState />
        ) : impersonatingUser ? (
          <ImpersonatingUserState
            authenticatedEmail={account!.email!}
            invitedUserEmail={invitedUser.email}
            signOut={handleSignOutUser}
          />
        ) : declined ? (
          <InviteDeclinedState
            orgName={org.name}
            orgAdminEmail={orgAdminEmail!}
          />
        ) : mustCompleteAccount ? (
          <RedeemNewAccount
            orgName={org.name}
            invitedUserEmail={invitedUser.email}
            accepting={accepting}
            onAccept={accept}
            declining={declining}
            onDecline={decline}
            xhrPending={xhrPending}
          />
        ) : !!account ? (
          <RedeemExistingAccount
            orgName={org.name}
            invitedUserEmail={invitedUser.email}
            accepting={accepting}
            onAccept={accept}
            declining={declining}
            onDecline={decline}
            xhrPending={xhrPending}
          />
        ) : (
          <RedeemExistingUnauthenticatedAccount
            invitedUserEmail={invitedUser.email}
            orgName={org.name}
            xhrPending={xhrPending}
            onSignIn={handleSignInUser}
          />
        )}
      </Section>
    </>
  );
};

const AlertAwareInvitesPage = () => (
  <AlertManagerProvider>
    <InvitesPage />
  </AlertManagerProvider>
);

export default AlertAwareInvitesPage;
