import { useCallback, useRef, useState } from 'react';
import {
  MdArrowRightAlt,
  MdArticle,
  MdCameraAlt,
  MdComputer,
  MdSettings,
  MdSort,
  MdUpload,
} from 'react-icons/md';
import { Image } from '../../common/types';
import { classes } from '../../common/utils';
import Header from '../../components/header';
import {
  HeaderMenu,
  HeaderMenuActionProps,
  HeaderSvgAction,
} from '../../components/header/actions';
import useSelectImage from '../../components/header/hooks/use-image-select';
import useImageModal, {
  SelectImageFile,
  SelectImageId,
} from './hooks/use-image-modal';
import { ImageSortOrder, useGroupedImages } from './hooks/use-images';

export default function ImagePage() {
  const [imageModal, viewImage, selectUploadImage] = useImageModal();
  return (
    <>
      {imageModal}
      <ImageLibrary
        viewImage={viewImage}
        selectUploadImage={selectUploadImage}
      />
    </>
  );
}

function ImageLibrary({
  viewImage,
  selectUploadImage,
}: {
  viewImage: SelectImageId;
  selectUploadImage: SelectImageFile;
}) {
  const [order, setOrder] = useState(ImageSortOrder.DateDesc);
  const imagesByDate = useGroupedImages(order);

  const UpArrow = useCallback(
    () => <MdArrowRightAlt className="h-6 w-6 -rotate-90" />,
    [],
  );
  const DownArrow = useCallback(
    () => <MdArrowRightAlt className="h-6 w-6 rotate-90" />,
    [],
  );

  return (
    <>
      <Header title="Library">
        <HeaderMenu title="Sort" icon={<MdSort />} className="col-start">
          <MenuButtonSort onClick={() => setOrder(ImageSortOrder.NameAsc)}>
            <span className="whitespace-nowrap">
              <strong>Name</strong>, A to Z
            </span>
            <UpArrow />
          </MenuButtonSort>
          <MenuButtonSort onClick={() => setOrder(ImageSortOrder.NameDesc)}>
            <span className="whitespace-nowrap">
              <strong>Name</strong>, Z to A
            </span>
            <DownArrow />
          </MenuButtonSort>
          <hr />
          <MenuButtonSort onClick={() => setOrder(ImageSortOrder.DateDesc)}>
            <span className="whitespace-nowrap">
              <strong>Date</strong>, Newest
            </span>
            <UpArrow />
          </MenuButtonSort>
          <MenuButtonSort onClick={() => setOrder(ImageSortOrder.DateAsc)}>
            <span className="whitespace-nowrap">
              <strong>Date</strong>, Oldest
            </span>
            <DownArrow />
          </MenuButtonSort>
          <hr />
          <MenuButtonSort onClick={() => setOrder(ImageSortOrder.UrlAsc)}>
            <span className="whitespace-nowrap">
              <strong>URL</strong>, A to Z
            </span>
            <UpArrow />
          </MenuButtonSort>
          <MenuButtonSort onClick={() => setOrder(ImageSortOrder.UrlDesc)}>
            <span className="whitespace-nowrap">
              <strong>URL</strong>, Z to A
            </span>
            <DownArrow />
          </MenuButtonSort>
        </HeaderMenu>

        <HeaderMenu title="Upload" icon={<MdUpload />}>
          <UploadImageButton selectUploadImage={selectUploadImage} />
          <MenuButtonUpload disabled className="pointer-events-none opacity-50">
            <MdCameraAlt className="h-6 w-6" />
            <span className="whitespace-nowrap">Add from extension</span>
          </MenuButtonUpload>
        </HeaderMenu>

        <HeaderSvgAction title="Images" icon={<MdArticle />} highlight />
        <HeaderSvgAction title="Settings" icon={<MdSettings />} highlight />
      </Header>
      <div className="mx-auto max-w-screen-xl p-4">
        <h1 className="inline-block pb-5 text-3xl">Welcome</h1>
        {imagesByDate.map((group) => (
          <ImageGrid
            key={group.title}
            title={group.title}
            images={group.images}
            onClick={viewImage}
          />
        ))}
      </div>
    </>
  );
}

function ImageGrid({
  title,
  images,
  onClick,
}: {
  title: string;
  images: Image[];
  onClick: (id: string) => void;
}) {
  return (
    <>
      <h2 className="mb-2 mt-5 text-xl">{title}</h2>
      <div className="grid grid-cols-images gap-4">
        {images.map((image) => (
          <ImageBlock
            key={image.id}
            {...image}
            onClick={() => onClick(image.id)}
          />
        ))}
      </div>
    </>
  );
}

function ImageBlock({ onClick, ...image }: Image & { onClick: () => void }) {
  return (
    <button
      key={image.id}
      onClick={onClick}
      className="gfp-grid-square relative w-full border-none bg-gray-200 bg-gradient-radial from-white to-gray-200 outline-none"
    >
      <div
        className="absolute inset-0 border-2 border-gray-500 bg-contain bg-center bg-no-repeat"
        style={{
          backgroundImage: `url('${image.urlThumb ?? image.urlFull ?? ''}'`,
        }}
      />
    </button>
  );
}

function MenuButtonSort(props: HeaderMenuActionProps) {
  return (
    <HeaderMenu.Action
      {...props}
      className={classes(props.className, 'row w-full justify-between')}
    />
  );
}

function MenuButtonUpload(props: HeaderMenuActionProps) {
  return (
    <HeaderMenu.Action
      {...props}
      className={classes(props.className, 'row w-full space-x-2')}
    />
  );
}

function UploadImageButton(props: { selectUploadImage: SelectImageFile }) {
  const onImageSelect = useSelectImage(props.selectUploadImage);
  const input = useRef<HTMLInputElement>();
  const selectImage = useCallback(() => input.current?.click(), [input]);
  return (
    <>
      <input
        ref={input as any}
        className="!hidden"
        type="file"
        accept="image/*"
        onChange={onImageSelect}
      />
      <MenuButtonUpload title="Upload" onClick={selectImage}>
        <MdComputer className="h-6 w-6" />
        <span className="whitespace-nowrap">Upload from computer</span>
      </MenuButtonUpload>
    </>
  );
}
