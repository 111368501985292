import classNames from 'classnames';
import React from 'react';

import './CameraIcon.scss';

const CameraIcon = (props) => {
  // this is '@material-ui/icons/CameraAlt';

  return (
    <svg
      focusable="false"
      viewBox="0 0 24 24"
      role="presentation"
      {...props}
      className={classNames('camera-icon', props.className)}
    >
      <circle cx="12" cy="12" r="3.2"></circle>
      <path d="M9 2L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-3.17L15 2H9zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z"></path>
    </svg>
  );
};

export default CameraIcon;
