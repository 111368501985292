import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import {
  ERROR_CODE_RETRY,
  cancelAccountSubscription,
  lookupAccount,
  updateAccountCancelAtPeriodEnd,
} from '../api';
import {
  STATE_LOADING,
  handleSignInUser,
  handleSignOutUser,
  handleSignUpUser,
  useAuthState,
} from '../auth/AuthProvider';
import Account from '../components/Account';
import LoaderDots from '../components/LoaderDots';
import { clearNext, getNext } from '../extension/redir-state';
import { log } from '../logger';
import { getTitle } from '../utils/document';
import { addBreadcrumb, captureException } from '../utils/sentry-util';

import { QS_ORG_ACCOUNT_SUCCESS } from '../constants';
import { useBoolean } from '../hooks/useBoolean';
import { useIsMounted } from '../hooks/useIsMounted';
import './AccountPage.scss';

// ## Messages

const ERROR_MESSAGE_LOAD_USER = 'Unable to load user.';
const ERROR_MESSAGE_CANCEL_SUB =
  'Error encountered canceling subscription, please try again. Contact support@gofullpage.com if the problem persists.';
const ERROR_MESSAGE_UPDATE_CARD =
  'Error encountered updating your card, please try again. Contact support@gofullpage.com if the problem persists.';
const ERROR_MESSAGE_UPDATE_RENEW =
  'Error encountered updating your subscription renew status, please try again. Contact support@gofullpage.com if the problem persists.';

/**
 * @typedef {{
 *   account?: import('../types/Account').Account | undefined;
 *   error: string;
 * }} State;
 */

/**
 * @typedef {[State, import('react').Dispatch<State>]} UseState;
 */

// ## Component

const AccountPage = ({ ...props }) => {
  const isMounted = useIsMounted();

  /** @type {UseState} */
  const [state, setState] = useState({ error: '', account: undefined });

  const { account, error } = state;

  const [successOrgLinkParams, setSuccessOrgLinkParams] = useState(undefined);
  const [newsletterSuccess, toggleNewsletterSuccess] = useBoolean();

  const nextUrl = getNext();
  const isOrgMember =
    Boolean(account?.orgId) && account?.orgStatus === 'active';
  const isOrgAdmin = isOrgMember && account?.orgRole === 'admin';
  const authStateCtx = useAuthState();
  const loading = authStateCtx.authState === STATE_LOADING;

  // if ?success in the URL and an org, then show the
  // org member success info!
  useEffect(() => {
    if (account?.orgName) {
      const params = new URLSearchParams(window.location.search);
      if (params.get(QS_ORG_ACCOUNT_SUCCESS) != null) {
        setSuccessOrgLinkParams({
          org: account.orgName,
          email: account.email,
        });
      }
    } else if (!account?.orgName && successOrgLinkParams) {
      setSuccessOrgLinkParams(undefined);
    }
  }, [account]);

  const authState = authStateCtx.authState;

  useEffect(() => {
    if (authState === 'signedIn') {
      addBreadcrumb('account', 'lookupAccount');

      let attempt = 0;
      const maxAttempts = 5;
      let isAlive = true;

      const fn = () =>
        _prom(
          lookupAccount({ includePayments: true }),
          ERROR_MESSAGE_LOAD_USER,
          (err) => {
            if (!isAlive) {
              return;
            } else if (err.code === ERROR_CODE_RETRY && attempt < maxAttempts) {
              attempt++;
              log.warn('lookupAccountError', err);
              window.setTimeout(
                () => {
                  if (isAlive) {
                    fn();
                  }
                },
                100 * Math.pow(attempt, 2),
              );
              return true;
            } else {
              captureException(err);
            }
          },
        );

      fn();

      return () => {
        isAlive = false;
      };
    }
  }, [authStateCtx.authState]);

  /**
   * Update the account object for the page
   * (Most functions auto-perform this, but this decouples
   * it so we can do it wherever.)
   * @param {*} account
   */
  const _updateAccount = useCallback((account) => {
    if (!isMounted()) {
      return;
    }

    // HACK - hold onto previous payments array if customer is the same
    setState((prevState) => {
      const prevAccount = prevState.account;
      if (
        prevAccount &&
        prevAccount.stripe_customer === account.stripe_customer &&
        prevAccount.payments
      ) {
        account.payments = prevAccount.payments;
      }

      return { error: '', account };
    });
  }, []);

  /**
   *
   * @param {() => Promise<Account>} accountProm an API function that returns an account response
   * @param {string | undefined} errorMessage optional error message text to show when an error is encountered
   * @param {undefined | (error: any) => boolean | undefined} errorCb optional error callback function, return `true` if you want to skip showing an error (should mean you're retrying)
   */
  const _prom = useCallback((accountProm, errorMessage, errorCb) => {
    // TODO NEXT - pass in these errorMessages for proper errors

    if (!isMounted()) {
      return;
    }

    setState((prevState) =>
      prevState.error ? { ...prevState, error: '' } : prevState,
    );

    return accountProm.then(_updateAccount).catch((err) => {
      log.warn(`AccountPage _prom error (${typeof err})`);
      log.error(err);
      const cbRet = errorCb && errorCb(err);
      if (isMounted() && cbRet !== true) {
        setState({
          error: err.message || errorMessage || 'Error encountered.',
        });
      }
    });
  }, []);

  const _cancelAccountSubscription = useCallback(() => {
    return _prom(cancelAccountSubscription(), ERROR_MESSAGE_CANCEL_SUB);
  }, [_prom]);

  const _updateAccountCancelAtPeriodEnd = useCallback(
    (cancelAtPeriodEnd) =>
      _prom(
        updateAccountCancelAtPeriodEnd(cancelAtPeriodEnd),
        ERROR_MESSAGE_UPDATE_RENEW,
      ),
    [_prom],
  );

  const _setError = useCallback(
    (msg) => {
      setState((prevState) => ({ ...prevState, error: msg }));
    },
    [setState],
  );

  return (
    <>
      <Helmet>
        <title>{getTitle('Account')}</title>
      </Helmet>
      {loading ? (
        <LoaderDots
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'block',
            marginTop: '40px',
          }}
        />
      ) : isOrgAdmin ? (
        <Redirect to="/enterprise/account" />
      ) : (
        <Account
          authState={authStateCtx.authState}
          user={authStateCtx.user}
          username={authStateCtx.username}
          signInUser={handleSignInUser}
          signUpUser={handleSignUpUser}
          signOutUser={handleSignOutUser}
          account={account}
          error={error}
          setError={_setError}
          cancelAccountSubscription={_cancelAccountSubscription}
          updateAccountCancelAtPeriodEnd={_updateAccountCancelAtPeriodEnd}
          updateAccount={_updateAccount}
          nextUrl={nextUrl}
          clearNextUrl={clearNext}
          history={props.history}
          successOrgLinkParams={successOrgLinkParams}
          newsletterSuccess={newsletterSuccess}
          onNewsletterSuccess={toggleNewsletterSuccess}
          isOrgMember={isOrgMember}
        />
      )}
    </>
  );
};

AccountPage.displayName = 'AccountPage';

const AccountPageWrapper = (props) => {
  return <AccountPage {...props} />;
};

export default AccountPageWrapper;
