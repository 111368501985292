import { Dialog, Transition } from '@headlessui/react';
import {
  Fragment,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { MdCalendarToday, MdChevronLeft } from 'react-icons/md';
import { Image } from '../../common/types';
import { classes } from '../../common/utils';
import useImagesStore from '../../store/images/store';
import { ImageCreateRequest, ImageEditRequest } from '../../store/images/types';
import Header, { HeaderProps } from '../header';
import { Field, ReadOnlyFieldText } from './fields';

export type ImageModalProps = PropsWithChildren<{
  headerProps: HeaderProps;
  image?: Partial<Image>;
  showSidebar?: boolean;
  toggleSidebar?: () => void;
  close: () => void;
}>;

export default function ImageModal({
  image,
  close,
  headerProps,
  showSidebar,
  toggleSidebar,
  children,
}: ImageModalProps) {
  const imageUrl = image?.urlFull;
  const isOpen = !!imageUrl;
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="normalize fixed inset-0 z-10" onClose={close}>
        <Transition.Child
          as="div"
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 z-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="flex h-screen flex-col">
          <Header {...headerProps} onNavigateBack={close} />

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="relative z-10 flex w-full flex-1">
              <div
                className="m-5 flex-1 bg-contain bg-center bg-no-repeat"
                onClick={close}
                style={{ backgroundImage: `url('${imageUrl}')` }}
              />
              <div
                className={classes(
                  'relative flex w-80 flex-col space-y-3 border-l-2 border-gray-300 bg-white p-5 text-lg transition-all duration-300',
                  !showSidebar && '-mr-80',
                )}
              >
                <button
                  onClick={toggleSidebar}
                  className="absolute left-0 top-5 z-50 grid h-12 w-8 -translate-x-full place-content-center rounded-l-md border-2 border-r-0 bg-white"
                >
                  <MdChevronLeft
                    className={classes(
                      'h-8 w-8 transition-transform duration-300',
                      showSidebar && '-rotate-180',
                    )}
                  />
                </button>
                {children}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

type CreateImageFormProps = { image?: File; close: () => void };

export function CreateImageModalContent({
  image,
  close,
}: CreateImageFormProps) {
  const [title, setTitleValue] = useState('');
  const [notes, setNotesValue] = useState('');
  const request: ImageCreateRequest = { title, notes };
  const [canUpload, uploading, upload] = useUpload(request, image, close);
  return (
    <>
      <h3 className="text-2xl">Upload Image</h3>

      <Field
        htmlFor="title"
        label="Title"
        input={
          <input
            type="text"
            name="title"
            className="form-input"
            placeholder="Something"
            value={title}
            onInput={(e) => setTitleValue(e.currentTarget.value)}
          />
        }
      />

      <Field
        htmlFor="notes"
        label="Notes"
        input={
          <textarea
            name="notes"
            className="form-textarea"
            placeholder="Something"
            value={notes}
            onInput={(e) => setNotesValue(e.currentTarget.value)}
          />
        }
      />

      <button
        onClick={upload}
        type="button"
        className="gfp-button gfp-button-primary px-3 py-2"
        disabled={!canUpload}
      >
        {uploading ? 'Uploading...' : 'Upload'}
      </button>
    </>
  );
}

export function ViewImageModalContent({
  image,
  close,
}: {
  image: Image;
  close: () => void;
}) {
  const [edit, setEdit] = useState(false);
  return edit ? (
    <EditImage image={image} cancel={() => setEdit(false)} />
  ) : (
    <ViewImage image={image} edit={() => setEdit(true)} close={close} />
  );
}

export function EditImage({
  image,
  cancel,
}: {
  image: Image;
  cancel: () => void;
}) {
  const [title, setTitleValue] = useState(image.title || '');
  const [notes, setNotesValue] = useState(image.notes || '');
  const request: ImageEditRequest = { id: image.id, title, notes };
  const [canEdit, updating, update] = useUpdate(request, cancel);
  return (
    <>
      <h3 className="text-2xl">Update Image</h3>

      <Field
        htmlFor="title"
        label="Title"
        input={
          <input
            type="text"
            name="title"
            className="form-input"
            placeholder="Something"
            value={title}
            onInput={(e) => setTitleValue(e.currentTarget.value)}
          />
        }
      />

      <Field
        htmlFor="notes"
        label="Notes"
        input={
          <textarea
            name="notes"
            className="form-textarea"
            placeholder="Something"
            value={notes}
            onInput={(e) => setNotesValue(e.currentTarget.value)}
          />
        }
      />

      <button
        onClick={update}
        type="button"
        className="gfp-button gfp-button-primary px-3 py-2"
        disabled={!canEdit}
      >
        {updating ? 'Updating...' : 'Update'}
      </button>
      <button
        onClick={cancel}
        type="button"
        className="gfp-button bg-gray-200 px-3 py-2 hover:bg-gray-300"
      >
        Cancel
      </button>
    </>
  );
}

function ViewImage({
  image,
  edit,
  close,
}: {
  image: Image;
  edit: () => void;
  close: () => void;
}) {
  const createDate = useMemo(() => image.created.toLocaleString(), [image]);
  const [warn, deleting, del] = useDelete(image.id, close);
  return (
    <>
      <h3 className="text-2xl">Info</h3>

      <ReadOnlyFieldText label="Title" text={image.title} />
      <ReadOnlyFieldText label="Notes" text={image.notes} />

      <h4 className="font-medium uppercase text-gray-600">Details</h4>
      <div className="row space-x-1">
        <MdCalendarToday className="h-6 w-6" />
        <p>{createDate}</p>
      </div>

      <button
        onClick={edit}
        type="button"
        className="gfp-button gfp-button-primary px-3 py-2"
      >
        Edit
      </button>

      <button
        onClick={del}
        type="button"
        className="gfp-button mt-5 bg-red-200 px-3 py-2 hover:bg-red-300"
      >
        {deleting ? 'Deleting...' : warn ? 'Are you sure?' : 'Delete'}
      </button>
    </>
  );
}

function useUpload(
  request: ImageCreateRequest,
  image: File | undefined,
  onComplete: () => void,
): [boolean, boolean, () => Promise<void>] {
  const canUpload = !!image;
  const [uploading, setUploading] = useState(false);
  const uploadImage = useImagesStore((s) => s.uploadImage);

  const upload = useCallback(async () => {
    if (!image) return;
    setUploading(true);
    try {
      await uploadImage(request, image);
      onComplete();
    } catch (error) {
      console.error(error);
    }
    setUploading(false);
  }, [request, image, uploadImage, onComplete]);

  return [canUpload, uploading, upload];
}

export function useUpdate(
  request: ImageEditRequest,
  onComplete: () => void,
): [boolean, boolean, () => Promise<void>] {
  const canEdit = !!request.title && !!request.notes && !!request.id;
  const [uploading, setUploading] = useState(false);
  const editImage = useImagesStore((s) => s.editImage);

  const edit = useCallback(async () => {
    setUploading(true);
    try {
      await editImage(request);
      onComplete();
    } catch (error) {
      console.error(error);
    }
    setUploading(false);
  }, [request, editImage, onComplete]);

  return [canEdit, uploading, edit];
}

export function useDelete(
  imageId: string,
  onComplete: () => void,
): [boolean, boolean, () => Promise<void>] {
  const [warn, setWarn] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const deleteImage = useImagesStore((s) => s.deleteImage);

  const del = useCallback(async () => {
    if (warn) {
      setDeleting(true);
      try {
        await deleteImage({ id: imageId });
        onComplete();
      } catch (error) {
        console.error(error);
      }
      setDeleting(false);
      setWarn(false);
    } else {
      setWarn(true);
    }
  }, [imageId, warn, deleteImage, onComplete]);

  return [warn, deleting, del];
}
