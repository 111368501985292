import React, { useContext, useEffect, useState } from 'react';
import { getOrganizationDetails, lookupAccount } from '../api';
import { AuthStateContext } from '../auth/AuthProvider';
import { Account, Organization } from '../types/Account';
import log from '../utils/log';
import { AlertManager } from './AlertManager';

type LoadingState = 'initial' | 'loading' | 'complete';

type AccountContextProps = {
  loading: boolean;
  /** more granular than loading, used for lazy fetch */
  loadingState: LoadingState;
  account?: Account;
  organization?: Organization;
  organizationError?: Error;
  refetch: () => Promise<void>;
  /** locally update the Organization  */
  setOrganization: (organization: Organization) => void;
  /** internal value for kicking of lazy fetch */
  _triggered: boolean;
  /** set internal value for kicking of lazy fetch */
  _setTriggered: (_triggered: boolean) => void;
};

export const AccountContext = React.createContext<AccountContextProps>({
  loadingState: 'initial',
  loading: true,
  setOrganization: () => Promise.reject(),
  refetch: () => Promise.reject(),
  _triggered: false,
  _setTriggered: () => {},
});

export const AccountContextProvider: React.FC = ({ children }) => {
  const { authState } = useContext(AuthStateContext);
  const [_triggered, _setTriggered] = useState(false);

  const [loadingState, setLoadingState] = useState<LoadingState>('initial');

  // let's default to `loading` so we avoid things like
  // unnecessary auto-redirects on the EnterpriseAccountPage
  const [loading, setLoading] = useState(true);

  const [account, setAccount] = useState<Account>();
  const [organization, setOrganization] = useState<Organization>();

  // HACK - track this to avoid infinite loops
  const [organizationError, setOrganizationError] = useState<Error>();

  const { setAlert } = useContext(AlertManager);

  const fetch = (silently: boolean = false) => {
    if (!silently) {
      setLoadingState('loading');
      setLoading(true);
    }
    return lookupAccount({ includePayments: true })
      .then((a) => {
        setAccount(a);

        if ('orgId' in a && !!a.orgId && a.orgRole === 'admin') {
          setOrganizationError(undefined);
          return getOrganizationDetails(a.orgId)
            .then(setOrganization)
            .catch((err) => {
              setOrganizationError(err);
              setAlert({
                title: 'Organization error.',
                message: err.message,
                severity: 'error',
              });
            });
        }
      })
      .then(() => {
        setLoadingState('complete');
        setLoading(false);
      })
      .catch((err) => {
        log.warn('AccountContextProvider.fetch.catch');
        log.error(err);
        setAlert({
          title: 'Something went wrong',
          message: 'Unable to retrieve account information',
          severity: 'error',
        });
        throw err;
      });
  };

  // handle auto-fetch & lazy load via `_triggered`
  useEffect(() => {
    switch (authState) {
      case 'loading': {
        setLoading(true);
        break;
      }
      case 'signIn': {
        setLoading(false);
        if (_triggered && loadingState === 'complete') {
          setLoadingState('initial');
          setAccount(undefined);
          setOrganization(undefined);
        }
        break;
      }
      case 'signedIn': {
        if (_triggered && loadingState === 'initial') {
          setLoadingState('loading');
          fetch();
        } else {
          setLoading(false);
        }
        break;
      }
    }
  }, [authState, _triggered]);

  return (
    <AccountContext.Provider
      value={{
        loading,
        loadingState,
        account,
        organization,
        organizationError,
        setOrganization,
        refetch: () => fetch(true),
        _triggered,
        _setTriggered,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export const useAccountContext = () => {
  const context = useContext(AccountContext);

  // wait till we ask for it to make the first request
  useEffect(() => {
    if (!context._triggered) {
      context._setTriggered(true);
    }
  }, [context._triggered]);

  return context;
};

// /**
//  * HOC to inject the `accountCtx` prop of type `AccountContextProps`
//  */
// export const withAccountContext = <
//   P extends { accountCtx: AccountContextProps }
// >(
//   Comp: ComponentType<P>
// ) => {
//   type WrappedProps = Omit<P, 'accountCtx'>;

//   const Wrapped: FunctionComponent<WrappedProps> = (props) => {
//     const accountCtx = useAccountContext();
//     const newProps = { ...props, accountCtx } as P;
//     // @ts-ignore - TODO - fix react types error
//     return <Comp {...newProps} />;
//   };

//   return Wrapped;
// };
