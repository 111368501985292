import PropTypes from 'prop-types';
import React from 'react';

import { Button, CardSection, PrimaryButton } from './Base';
import Emojis, { EMOJI_TYPE_PROPTYPE, TYPE_HP, TYPE_PREMIUM } from './Emojis';

import './InfoCard.scss';

const InfoCard = ({
  title,
  text,
  smallText,
  emojisType,
  buttonText,
  buttonLink,
  buttonType,
  buttonClassName,
}) => {
  const linkOpts = {
    [buttonType === Button.TYPE_ROUTE ? 'to' : 'href']: buttonLink,
  };
  return (
    <CardSection className="info-card">
      <div className="inner">
        <h2>{title}</h2>
        {text && <p>{text}</p>}
        <Emojis type={emojisType} />
        {smallText && <p className="dim small">{smallText}</p>}
      </div>
      <PrimaryButton
        className={buttonClassName}
        type={buttonType || 'link'}
        size="large"
        {...linkOpts}
      >
        {buttonText}
      </PrimaryButton>
    </CardSection>
  );
};

InfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  smallText: PropTypes.string,
  emojisType: EMOJI_TYPE_PROPTYPE.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
  buttonType: PropTypes.oneOf(Button.types),
  buttonClassName: PropTypes.string,
};

InfoCard.defaultProps = {
  emojisType: TYPE_HP,
};

export default InfoCard;

//

const WEBSTORE_LINK =
  'https://chrome.google.com/webstore/detail/full-page-screen-capture/fdpohaocaechififmbbbbbknoalclacl?hl=en-US';

export const FPSCFreeInfoCard = () => (
  <InfoCard
    title="GFP Free"
    text="The best free extension to screenshot and save your full webpage."
    emojisType={TYPE_HP}
    smallText="The highest ratest screenshot extension in the Chrome web store. Also available on Microsoft Edge."
    buttonText="Add to Chrome (it’s free!)"
    buttonLink={WEBSTORE_LINK}
    buttonType={Button.TYPE_LINK}
    buttonClassName={'btn-standout'}
  />
);
export const PremiumInfoCard = ({ buttonText }) => (
  <InfoCard
    title="Go Premium"
    text="Take your screenshots to the next level with GoFullPage Premium."
    emojisType={TYPE_PREMIUM}
    smallText="Including advanced editing and annotation features of screenshots."
    buttonText={buttonText || 'Get started (free 1-week trial)'}
    buttonLink={'/premium'}
    buttonType={Button.TYPE_ROUTE}
    buttonClassName={'btn-convert btn-standout'}
  />
);
