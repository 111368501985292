import Markdown from 'markdown-to-jsx';
import { Helmet } from 'react-helmet';
import { NavHashLink } from 'react-router-hash-link';

import { Container, Section } from '../components/Base';
import { connectGTM } from '../components/GTMListener';
import { HEADER_OFFSET } from '../constants';
import { getTitle } from '../utils/document';

import './FaqPage.scss';
import page from './FaqPage.yml';

const SIDEBAR_STYLE = { zIndex: 2 }; // so content below doesnt sit over it on mobile
const FAQ_CAT_STYLE = { marginTop: -HEADER_OFFSET };

const FaqPage = ({ dataLayerHistoryChange }) => {
  const categories = page.categories.slice();
  if (page.premium_categories) {
    const last = categories.pop();
    categories.push.apply(categories, page.premium_categories);
    if (last) {
      categories.push(last);
    }
  }

  // const handleAdMouseDown = (evt) => {
  //   const fakeLocation = {
  //     pathname: `/outbound/${AD_ID}${window.location.pathname}`,
  //     search: window.location.search,
  //   };
  //   dataLayerHistoryChange(fakeLocation);
  // };

  return (
    <Section className="faq first-sec">
      <Helmet>
        <title>{getTitle('FAQs')}</title>
      </Helmet>
      <Container>
        <h1>Frequently Asked Questions</h1>
        <div className="faq-content gfp-row">
          <div className="col-2 offset-1" style={SIDEBAR_STYLE}>
            <div className="faq-cats-group">
              <strong className="faq-cats-header">Categories</strong>
              <ul className="faq-cats">
                {categories.map(({ name }) => (
                  <li key={name}>
                    <NavHashLink smooth to={`#${_slug(name)}`}>
                      {name}
                    </NavHashLink>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-8">
            {/*<div className="friends">
              <div className="text">Sponsored by our friends at Toucan</div>
              <a
                className="img-wrap"
                href={AD_URL}
                onMouseDown={handleAdMouseDown}
              >
                <img
                  src={AD_SRC}
                  width={AD_WIDTH}
                  height={AD_HEIGHT}
                  alt={AD_ALT}
                  target="_blank"
                  rel="noopener noreferrer"
                />
              </a>
            </div>*/}
            {categories.map(({ name, questions }) => (
              <div key={name}>
                <div className="faq-cat" id={_slug(name)} style={FAQ_CAT_STYLE}>
                  <h2 style={{ paddingTop: HEADER_OFFSET }}>{name}</h2>
                  <div className="faq-qs">
                    {questions.map(({ question, answer }) => (
                      <div key={question} className="faq-qa">
                        <div className="faq-anchor" id={_slug(question)} />
                        <div>
                          <h3 className="faq-q">{question}</h3>
                          <div
                            className="faq-a prose"
                            options={{ forceBlock: true }}
                          >
                            <Markdown
                              className="faq-a prose"
                              options={{ forceBlock: true }}
                            >
                              {answer}
                            </Markdown>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
            <p>
              For any additional questions that have not been addressed in the
              FAQ, please fill out a customer support ticket{' '}
              <a href="https://screencapture.mrcoles.com/">here</a> and we will
              get back to you as quickly as possible. If you want to see if you
              are encountering a known issue, you can review the{' '}
              <a href="https://gofullpage.notion.site/GoFullPage-Status-Page-0d3d0f3b4aa14167b91c4f37a17cf072">
                GoFullPage Status Page
              </a>
              . If you have a premium account and have a question regarding your
              account, please reply to any transactional emails you have
              received from GoFullPage.
              <br />
              <br />
              <em>Updated: December 2023</em>
              <br />
              <br />
              <br />
              <br />
              <br />
            </p>
          </div>
        </div>
      </Container>
    </Section>
  );
};

FaqPage.displayName = 'FaqPage';

export default connectGTM(FaqPage);

// ## Helpers

const _slug = (text) =>
  text
    .toLowerCase()
    .replace(/[\'\"‘’“”]+/g, '')
    .replace(/[^a-z0-9]+/g, ' ')
    .trim()
    .replace(/\s+/g, '-');
