import { Helmet } from 'react-helmet';
import { Container, Section } from '../components/Base';
import TermsOfUseContent from '../components/TermsOfUseContent';
import { getTitle } from '../utils/document';

export default () => {
  return (
    <Section className="privacy">
      <Helmet>
        <title>{getTitle('Terms of Use')}</title>
      </Helmet>
      <Container>
        <div className="gfp-row">
          <div className="col-8 offset-2">
            <h1>GoFullPage Terms of Use Agreement</h1>
            <TermsOfUseContent />
          </div>
        </div>
      </Container>
    </Section>
  );
};
