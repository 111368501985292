import PropTypes from 'prop-types';
import { Component } from 'react';

import { Helmet } from 'react-helmet';
import {
  createCheckoutSessionUrl,
  lookupAccount,
  performCheckoutSessionSuccess,
} from '../api';
import { withAuthUser } from '../auth/AuthProvider';
import CheckoutStages from '../components/CheckoutStages';
import { ERR_CODE_LOGIN } from '../components/ErrorMessage';
import { connectGTM } from '../components/GTMListener';
import { clearNext, getNext } from '../extension/redir-state';
import { log } from '../logger';
import { getTitle } from '../utils/document';
import { addBreadcrumb, captureException } from '../utils/sentry-util';

// import './Checkout.scss';

let LAST_ABTESTV2_GROUP = '';

class CheckoutPage extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    authState: PropTypes.string.isRequired, // TODO(prop-types)
    user: PropTypes.object,
    username: PropTypes.string,
    signInUser: PropTypes.func.isRequired,
    signUpUser: PropTypes.func.isRequired,
    signOutUser: PropTypes.func.isRequired,
    isPopup: PropTypes.bool.isRequired,
    isSignIn: PropTypes.bool.isRequired,
    dataLayerHistoryChange: PropTypes.func.isRequired, // via connectGTM
  };

  static defaultProps = {
    isPopup: false,
    isSignIn: false,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  // Life-cycle
  componentDidMount() {
    this._mounted = true;
    // this._loadAccount();
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  // Handlers

  /**
   * @param {import('../api').LookupAccountOpts} [opts]
   * @returns
   */
  lookupAccount = (opts) => {
    if (this.state.error) {
      this.setState({ error: '', errorData: undefined });
    }

    addBreadcrumb('checkout', 'lookupAccount');

    return lookupAccount(opts)
      .then((account) => {
        if (this._mounted) {
          this.setState({ account });
          // if (account.abTestV2) {
          //   const exp = abTestV2GroupToGaExp(account.abTestV2.group);
          //   if (exp) {
          //     this.props.dataLayerPush({
          //       event: 'optimize.activate',
          //       event_category: 'AB',
          //       event_action: 'abTestV2',
          //       event_label: account.abTestV2.group,
          //       exp,
          //     });
          //   }
          // }
        }
      })
      .catch((err) => {
        log.error(err);
        // TODO(FIXXXXXXX) - put this on a debounce so we dont miss it - is getting triggering by continue with google new accounts - see f9ee6c193eaae9c58c236ac693945407249f38dd captureException(err);
        if (this._mounted) {
          // TODO(error)
          this.setState({
            error: 'Unable to lookup user subscription state',
            errorData: {
              userId: this.props.user?.id,
              code: ERR_CODE_LOGIN,
              error: err,
            },
          });
        }
      });
  };

  createCheckoutSessionUrl = (params) => {
    return createCheckoutSessionUrl(params);
  };

  performCheckoutSessionSuccess = (body) => {
    return performCheckoutSessionSuccess(body).then((account) => {
      if (this._mounted) {
        this.setState({ account });
      }
      return account;
    });
  };

  /**
   * Perform a checkoutSuccess API call
   * @param {{ session_id: string; }} body
   * @returns {Promise<any|null>} (need type for account)
   */
  performCheckoutSuccess = (body) => {
    if (this.state.error) {
      this.setState({ error: '', errorData: undefined });
    }

    addBreadcrumb('checkout', 'performCheckoutSuccess');

    return performCheckoutSuccess(body)
      .then((account) => {
        if (this._mounted) {
          this.setState({ account });
        }
        return account;
      })
      .catch((err) => {
        log.error(err);
        captureException(err);
        // TODO(zendesk) - does this give a flag error?
        this.setError(
          err.message ||
            'Unable to complete checkout. If this error persists, please contact support@gofullpage.com',
        );
        return null;
      });
  };

  setError = (msg) => {
    if (this._mounted) {
      this.setState({ error: msg, errorData: undefined });
    }
  };

  // Render
  render() {
    const {
      match,
      history,
      authState,
      user,
      username,
      signInUser,
      signUpUser,
      signOutUser,
      isPopup,
      isSignIn,
    } = this.props;

    const { account, checkoutResp, error, errorData } = this.state;
    const nextUrl = getNext(true);

    return (
      <>
        <Helmet>
          <title>{getTitle('Checkout')}</title>
        </Helmet>
        <CheckoutStages
          match={match}
          history={history}
          authState={authState}
          user={user}
          username={username}
          signInUser={signInUser}
          signUpUser={signUpUser}
          signOutUser={signOutUser}
          lookupAccount={this.lookupAccount}
          createCheckoutSessionUrl={this.createCheckoutSessionUrl}
          performCheckoutSessionSuccess={this.performCheckoutSessionSuccess}
          setError={this.setError}
          error={error}
          errorData={errorData}
          account={account}
          checkoutResp={checkoutResp}
          nextUrl={nextUrl}
          clearNextUrl={clearNext}
          isPopup={isPopup}
          isSignIn={isSignIn}
        />
      </>
    );
  }
}

export default withAuthUser(connectGTM(CheckoutPage));
