import * as Sentry from '@sentry/react';

import { render } from 'react-dom';

import App from './components/App';
import { setupGTM } from './components/GTMListener';
import { ENV, IS_MASTER, SENTRY_DSN } from './constants';
import log, { LOG_LEVELS, setLogLevel } from './utils/log';

// https://aws-amplify.github.io/docs/js/logger#setting-logging-levels
if (!IS_MASTER) {
  setLogLevel(LOG_LEVELS.DEBUG);
}

log.debug(`IS_MASTER? ${IS_MASTER}`);

if (SENTRY_DSN) {
  const allowUrls = [
    /^https?:\/\/(www\.)?gofullpage\.com\//,
    /^https?:\/\/dev\.d32cgdvim65k7p\.amplifyapp\.com\//,
  ];

  if (!IS_MASTER && window.location.hostname === 'localhost') {
    allowUrls.push(/^https?:\/\/localhost(:\d+)?\//);
  }

  Sentry.init({
    dsn: SENTRY_DSN,
    environment: ENV,
    allowUrls,
  });
}

setupGTM();

render(<App />, document.getElementById('root'));
