import assert from 'minimalistic-assert';
import PropTypes from 'prop-types';
import { Component } from 'react';
import Toggle from './Toggle';

import classNames from 'classnames';
import { SUPPORT_EMAIL } from '../constants';
import { log } from '../logger';

class CancelTrialWidget extends Component {
  static propTypes = {
    align: PropTypes.oneOf(['left', 'center']),
    user: PropTypes.object,
    account: PropTypes.object,
    cancelAccountSubscription: PropTypes.func.isRequired,
    updateAccountCancelAtPeriodEnd: PropTypes.func.isRequired,
    underlineCta: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      tempCancelAccountSubscription: undefined,
    };
  }

  // Handlers
  handleCancelTrial = (evt, confirmMsg) => {
    evt.preventDefault();

    const { cancelAccountSubscription } = this.props;
    // if (account.subscription.status !== 'trialing') {
    //   alert('Invalid state, not trialing');
    //   log.error(account.subscription);
    //   return;
    // }

    this.setState({
      isSubmitting: true,
    });

    return confirmPromise(confirmMsg)
      .then((didConfirm) => {
        return didConfirm ? cancelAccountSubscription() : null;
      })
      .then(this._cleanUp)
      .catch((err) => {
        log.error('[handleCancelTrial]', err);
        this._cleanUp();
        alert(
          `Error updating. Please refresh the page and try again. If this problem persists, contact ${SUPPORT_EMAIL}`,
        );
      });
  };

  handleChangeAutoRenew = (evt) => {
    evt.preventDefault();

    const { account, updateAccountCancelAtPeriodEnd } = this.props;
    // if (account.subscription.status === 'trialing') {
    //   alert('Invalid state, in trial');
    //   log.error(account.subscription);
    //   return;
    // }

    assert.equal(typeof account.subscription.cancel_at_period_end, 'boolean');

    const newVal = !account.subscription.cancel_at_period_end;

    this.setState({
      isSubmitting: true,
      tempCancelAccountSubscription: newVal,
    });

    return confirmPromise(
      'Are you sure you want to disable auto-renew? This means your subscription will not renew when it expires.',
      newVal === false,
    )
      .then((didConfirm) => {
        if (didConfirm) {
          return updateAccountCancelAtPeriodEnd(newVal);
        }
        return null;
      })
      .then(this._cleanUp)
      .catch((err) => {
        log.error('[handleChangeAutoRenew]', err);
        this._cleanUp();
        alert(
          `Error updating. Please refresh the page and try again. If this problem persists, contact ${SUPPORT_EMAIL}`,
        );
      });
  };

  // Helpers
  _cleanUp = () => {
    if (this.state.isSubmitting) {
      this.setState({
        isSubmitting: false,
        tempCancelAccountSubscription: undefined,
      });
    }
  };

  // Render
  render() {
    const { account, user, underlineCta } = this.props;
    const { isSubmitting, tempCancelAccountSubscription } = this.state;
    const { subscription } = account || {};

    const ctaStyle = { textDecoration: underlineCta ? 'underline' : undefined };

    const align = this.props.align || 'center';

    let mainNode = null;

    if (user && subscription) {
      const { status } = subscription;

      if (status) {
        switch (status) {
          case 'canceled': {
            break;
          }
          case 'incomplete':
          case 'incomplete_expired':
          case 'trialing':
          case 'past_due':
          case 'unpaid': {
            // current subscription is trialing
            let msg;
            let confirmMsg;
            if (status === 'trialing') {
              msg = 'Cancel trial';
              confirmMsg =
                'Are you sure you want to cancel your trial? This will prevent you from using GoFullPage Premium features.';
            } else {
              msg = 'Cancel subscription';
              confirmMsg =
                'Are you sure you want to cancel your subscription? This will prevent you from using GoFullPage Premium features.';
            }

            mainNode = isSubmitting ? (
              <span className="dim">loading…</span>
            ) : (
              <a
                href="#"
                onClick={(evt) => this.handleCancelTrial(evt, confirmMsg)}
                style={ctaStyle}
              >
                {msg}
              </a>
            );
            break;
          }
          case 'active': {
            // current subscription is not canceled
            const cancelAtPeriodEnd = isSubmitting
              ? tempCancelAccountSubscription
              : subscription.cancel_at_period_end;

            const msg = cancelAtPeriodEnd
              ? 'Auto-renew (disabled)'
              : 'Auto-renew (enabled)';

            mainNode = (
              <div>
                <div
                  className={classNames(
                    'flex items-center space-x-[10px]',
                    align === 'left' ? 'justify-start' : 'justify-center',
                  )}
                >
                  <Toggle
                    checked={!cancelAtPeriodEnd}
                    onChange={this.handleChangeAutoRenew}
                    name="autorenew"
                  />
                  <span
                    className={classNames(
                      'font-normal text-black',
                      isSubmitting ? 'text-gray-600' : 'text-black',
                    )}
                  >
                    {msg}
                  </span>
                </div>
                {!cancelAtPeriodEnd ? (
                  <div className="dim my-[20px]">
                    Recommended to avoid interruption to your service
                  </div>
                ) : null}
              </div>
            );
            break;
          }
          default: {
            log.error(`Unknown subscription status: ${status}`);
            break;
          }
        }
      }
    }

    return mainNode;
  }
}

// Helpers

const confirmPromise = async (question, forceYes) => {
  if (forceYes) {
    return true;
  }
  return confirm(question);
};

// Export

export default CancelTrialWidget;
