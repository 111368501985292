import querystring from 'querystring';

import { EXTENSION_ORIGINS } from '../constants';
import localStorage from '../utils/localStorage';

window.querystring = querystring;

const NEXT_KEY = '__fpnext';
const PREV_NEXT_KEY = '__fppnext';

// Get
export const getNext = (checkBackup) => {
  let val = localStorage.getItem(NEXT_KEY);
  if (_validateUrl(val)) {
    return val;
  }
  if (checkBackup === true) {
    const prevVal = localStorage.getItem(PREV_NEXT_KEY);
    if (_validateUrl(prevVal)) {
      return prevVal;
    }
  }
  return null;
};

// Set
export const setNext = (next) => localStorage.setItem(NEXT_KEY, next);

// Clear
export const clearNext = () => {
  const next = getNext();
  if (next) {
    localStorage.setItem(PREV_NEXT_KEY, next);
  }
  localStorage.removeItem(NEXT_KEY);
};

const _validateUrl = (url) =>
  url &&
  EXTENSION_ORIGINS.some(
    (origin) => url.startsWith(`${origin}/`) || url === origin,
  );

// Check URL: auto-saves state and updates URL

export const checkUrl = () => {
  const qs = querystring.parse(window.location.search.substring(1));
  const { next, p } = qs;

  let hasUpdate = false;

  if (next) {
    delete qs.next;
    setNext(next);
  }

  // // use to allow URL parameter to enable premium
  // // now require the extension to do it instead...
  // if (p) {
  //   delete qs.p;
  //   enablePremium();
  // }

  if (next || p) {
    const newQs = querystring.stringify(qs);
    const newUrl = `${window.location.pathname}${newQs ? '?' + newQs : ''}${
      window.location.hash
    }`;
    if (window.history && window.history.replaceState) {
      history.replaceState(null, null, newUrl);
    }
  }
};
