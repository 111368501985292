import { AxiosError } from 'axios';
import { CSSProperties, FunctionComponent, useState } from 'react';

type ApiErrorMessageProps = {
  error?: Error | AxiosError;
};

const ApiErrorMessage: FunctionComponent<ApiErrorMessageProps> = ({
  error,
}) => {
  const [showDetails, setShowDetails] = useState(false);

  if (!error) {
    return null;
  }

  return (
    <div className="error mb-[20px] rounded-lg bg-red-100 p-[10px]">
      {error.message}
      {showDetails ? (
        <>
          {error.stack ? (
            <div className="pt-[20px] text-[12px]" style={STYLE_BREAK_WORD}>
              {getIsAxiosError(error)
                ? getAxiosErrorDataString(error)
                : error.stack}
            </div>
          ) : null}
        </>
      ) : (
        <>
          {' '}
          (
          <a
            href="#"
            onClick={(evt) => {
              evt.preventDefault();
              setShowDetails(true);
            }}
          >
            show details
          </a>
          )
        </>
      )}
    </div>
  );
};

export default ApiErrorMessage;

const STYLE_BREAK_WORD: CSSProperties = { wordBreak: 'break-word' };

// TODO(DRY) - move these somewhere better for error handling

export function getIsAxiosError(err: Error | AxiosError): err is AxiosError {
  return !!(err && (err as AxiosError).isAxiosError);
}

function getAxiosErrorDataString(err: AxiosError) {
  if (err.response) {
    const { data, config } = err.response;
    return JSON.stringify({ path: config.socketPath, data });
  } else {
    return err.stack || `${err}`;
  }
}

export const errorToString = (err: unknown) => {
  let msg = '';
  try {
    msg = `${(err as Error).message}`;
  } catch (e) {}
  return msg || `${err}`;
};
