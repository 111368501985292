import PropTypes from 'prop-types';
import React, { Component } from 'react';
import VideoWrap from './VideoWrap';

const VideoYoutube = ({ width, height, url }) => {
  return (
    <VideoWrap width={width} height={height}>
      <iframe
        width={width}
        height={height}
        src={url}
        frameBorder={0}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </VideoWrap>
  );
};

export default VideoYoutube;
