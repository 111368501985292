"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("css.escape"); // polyfill
var pseudo_localization_1 = require("pseudo-localization");
var constants_1 = require("./constants");
var util_1 = require("./util");
exports.options = {
    pseudoloc: false,
    disabled: false
};
exports.tr = function (text, key, data, placeholders, isHtml) {
    key = key || util_1.asKey(text);
    if (exports.options.disabled) {
        return _addPlaceholders(text, data, placeholders);
    }
    var message = chrome.i18n.getMessage(key, data) ||
        _addPlaceholders(text, data, placeholders);
    if (exports.options.pseudoloc) {
        if (data && placeholders) {
            // HACK - do extra step to go back to original and extra parts
            // so we can *only* localize the text, not the placeholders
            message = text
                .split(/(\$[A-Za-z][A-Za-z0-9_]*\$)/)
                .map(function (val, i) {
                return i % 2 === 0 ? _pseudoLocalizeWithHtml(val, isHtml) : val;
            })
                .join('');
            message = _addPlaceholders(message, data, placeholders);
        }
        else {
            message = _pseudoLocalizeWithHtml(message, isHtml);
        }
    }
    return message;
};
var _addPlaceholders = function (text, data, placeholders) {
    if (data && placeholders && text) {
        Object.entries(placeholders).forEach(function (_a) {
            var key = _a[0], info = _a[1];
            var sp = info.content.split(/\$(\d+)/);
            for (var i = 1; i < sp.length; i += 2) {
                var index = parseInt(sp[i], 10);
                sp[i] = String(data[i - 1]);
            }
            text = text.replace(new RegExp("\\$" + key + "\\$", 'gi'), sp.join(''));
        });
    }
    return text;
};
/*
// sample:
tr('Hi, $name$, your number is $number$.', 'hiYourNumber', ['Alice', '10'], {
  name: { content: '$1', example: 'Alyssa P' },
  name: { content: '$2', example: '1' }
});
*/
// NOTE - this breaks from parcel builds, TODO adjust this
// to maybe only do the checkrep during the e7n build step?
// // check rep - avoid function name not matching
// if (tr.name !== constants.FN_NAME) {
//   let error = new Error(
//     `Function name mismatch: ${tr.name} vs ${constants.FN_NAME}`
//   );
//   error.name = 'CheckRep';
//   throw error;
// }
//
// ## HTML handling
//
exports.updateHtml = function (context, attr) {
    if (context === void 0) { context = null; }
    if (attr === void 0) { attr = constants_1.ATTR_NAME; }
    context = context || document;
    var elts = context.querySelectorAll("[" + CSS.escape(attr) + "]");
    elts.forEach(function (elt) {
        exports.updateElt(elt, elt.getAttribute(attr) || undefined, attr);
    });
};
exports.updateElt = function (elt, key, attr) {
    if (attr === void 0) { attr = constants_1.ATTR_NAME; }
    var htmlAttr = elt.getAttribute(attr + "-html");
    var asHtml = htmlAttr !== null && htmlAttr !== undefined;
    var text = (asHtml ? elt.innerHTML : elt.innerText).trim();
    key = key || util_1.asKey(text);
    var message = exports.tr(text, key, undefined, undefined, asHtml);
    if (message && message !== text) {
        if (asHtml) {
            elt.innerHTML = message;
        }
        else {
            elt.textContent = message;
        }
    }
};
// ## Helpers
// the localize function doesn't pay attention to HTML, so let's try to only
// convert the text parts of HTML
var _pseudoLocalizeWithHtml = function (text, isHtml) {
    if (isHtml) {
        return _separateHtml(text)
            .map(function (_a) {
            var type = _a.type, content = _a.content;
            return type === 'html' ? content : pseudo_localization_1.localize(content);
        })
            .join('');
    }
    else {
        return pseudo_localization_1.localize(text);
    }
};
// this is for pseudo-localization testing only (not production-grade), tries to
// separate out text from html tags
var _separateHtml = function (html) {
    var openChar = '<';
    var closeChar = '>';
    var results = [];
    var lastI = 0;
    var i = 0;
    var isOpen = false;
    var len = html.length;
    var _update = function () {
        if (i !== lastI) {
            results.push({
                type: isOpen ? 'html' : 'text',
                content: html.substring(lastI, i)
            });
        }
        isOpen = !isOpen;
        lastI = i;
    };
    for (; i < len; i++) {
        var char = html[i];
        if (char === openChar) {
            if (isOpen) {
                var error = new Error("Invalid HTML extra < character: " + html);
                error.name = 'InvalidHTMLError';
                throw error;
            }
            _update();
        }
        else if (char === closeChar) {
            if (!isOpen) {
                var error = new Error("Invalid HTML extra > character: " + html);
                error.name = 'InvalidHTMLError';
                throw error;
            }
            _update();
        }
    }
    _update();
    return results;
};
