import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { PrimaryButton } from '../Base';

type Color = 'grey' | 'blue' | string;

type Props = HTMLAttributes<HTMLButtonElement> & {
  onClick?: (event: React.MouseEvent) => void;
  type?: 'button' | 'link' | 'submit';
  color?: Color;
  disabled?: boolean;
  loading?: boolean;
};

const ColorTheme: Record<Color, string> = {
  grey: '#6B7280',
  blue: '#36B6E6',
};

const StyledButton = styled(PrimaryButton)<Props>`
  padding: 8px 16px;
  font-size: 16px;
  line-height: 24px;
  height: 24px;
  color: white;
  font-weight: normal;
  background-color: ${({ color }) => ColorTheme[color] ?? color};
`;

StyledButton.defaultProps = {
  type: 'button',
  color: 'blue',
};

const Button: React.FC<Props> = ({ loading, ...props }) => (
  <StyledButton {...props}>
    {loading ? 'Loading...' : props.children}
  </StyledButton>
);

Button.displayName = 'Button';

export default Button;
