import React, { PropsWithChildren } from 'react';
import { useStyledChildren } from '../../common/hooks';
import { classes } from '../../common/utils';

export function HeaderSvgAction({
  title,
  icon,
  onClick,
  highlight,
}: PropsWithChildren<{
  onClick?: () => void;
  highlight?: boolean;
  title: string;
  icon: JSX.Element;
}>) {
  const _icon = useStyledChildren(icon, 'w-6 h-6');
  return (
    <button
      onClick={onClick}
      title={title}
      className={classes(
        highlight && 'bg-white bg-opacity-20',
        'p-4 text-white hover:text-gray-300',
      )}
    >
      {_icon}
    </button>
  );
}

export function HeaderMenu({
  title,
  icon,
  className,
  children,
}: PropsWithChildren<{
  title: string;
  icon: JSX.Element;
  className?: string;
}>) {
  const _icon = useStyledChildren(icon, 'w-6 h-6');
  return (
    <div className="group relative p-4 text-white" title={title}>
      {_icon}
      <div
        className={classes(
          className,
          'absolute right-0 top-full z-10 hidden border border-gray-600 bg-white text-black group-hover:block',
        )}
      >
        {children}
      </div>
    </div>
  );
}

export type HeaderMenuActionProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

HeaderMenu.Action = function HeaderMenuAction({
  className,
  ...props
}: HeaderMenuActionProps) {
  const _className = classes(
    className,
    'hover:bg-primary-400 px-3 py-2 text-base',
  );
  return <button {...props} className={_className} />;
};
