import React, { useRef } from 'react';
import styled from 'styled-components';
import { useBoolean } from '../../hooks/useBoolean';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { noop } from '../../utils/utils';
import { TypographyCss } from '../atoms/typography';

export type DropdownOption = {
  label: string;
  onClick?: () => void;
  href?: string;
  disabled?: boolean;
};
type DropdownOptionPosition = {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
};
type Props = {
  className?: string;
  trigger: React.ReactNode;
  options: DropdownOption[];
  disabled?: boolean;
  position?: DropdownOptionPosition;
};

const Container = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownOptionsContainer = styled.div<{
  position: DropdownOptionPosition;
}>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  padding: 8px 0;
  min-width: 200px;
  background: #ffffff;
  border: 1px solid #cccccc;
  box-shadow:
    0 2px 4px -2px rgba(0, 0, 0, 0.1),
    0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1;
  overflow: auto;
  top: ${(props) => props.position.top};
  right: ${(props) => props.position.right};
  bottom: ${(props) => props.position.bottom};
  left: ${(props) => props.position.left};
`;

const DropdownOption = styled.a`
  ${TypographyCss};
  padding: 4px 16px;
  background: white;
  cursor: pointer;
  width: 100%;
  :hover {
    background: #cbf0e4;
    color: inherit;
  }
`;

const Dropdown: React.FC<Props> = ({
  className,
  trigger,
  options,
  disabled = options.length === 0,
  position = {
    right: 0,
  },
}) => {
  const ref = useRef(null);
  const [showOptions, toggleShowOptions, setShowOptionsTo] = useBoolean();

  useOnClickOutside(ref, () => setShowOptionsTo(false));

  return (
    <Container className={className} ref={ref}>
      <span
        onClick={disabled ? noop : toggleShowOptions}
        aria-disabled={disabled}
      >
        {trigger}
      </span>
      {showOptions && (
        <DropdownOptionsContainer position={position}>
          {options.map((option, index) => (
            <DropdownOption
              key={index}
              href={option.href}
              onClick={() => {
                toggleShowOptions();
                (option.onClick ?? noop)();
              }}
            >
              {option.label}
            </DropdownOption>
          ))}
        </DropdownOptionsContainer>
      )}
    </Container>
  );
};

export default Dropdown;
