import React, { useContext, useMemo } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import styled from 'styled-components';
import { AlertManager } from '../../context/AlertManager';
import Loading from '../UpdateCardWidget/Loading';
import Divider from '../atoms/divider';
import Alert from '../molecules/alert';

type RouteDefinition = {
  path: string;
  label: string;
  content: React.FC;
};

type Props = {
  routes: RouteDefinition[];
};
const paddingTop = '24px';

const headerHeight = '60px'; // '6rem'; //cf. src/sass/constants.scss

const fullPaddingTop = `${parseInt(paddingTop, 10) + parseInt(headerHeight, 10)}px`;

const Container = styled.section`
  padding: 0;
  background: white;
  min-height: 75.8vh;
`;
const AlertContainer = styled.div`
  padding: 16px;
`;
const ColumnsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: stretch;
`;
const SideNavContainer = styled.section`
  width: 224px;
  display: flex;
  padding: ${paddingTop} 20px;
  flex-flow: row nowrap;
  text-align: left !important;
  background: inherit;
  position: relative;
`;
const StickyContainerWrapper = styled.div`
  display: block;
  width: 100%;
`;
const StickyContainer = styled.div`
  position: sticky;
  top: ${fullPaddingTop};
`;
type SideNavItemContainerProps = {
  selected: boolean;
};
const SideNavItemContainer = styled.div<SideNavItemContainerProps>`
  display: flex;
  flex: 1;
  width: 100%;
  align-content: center;
  padding: 8px 16px;
  border-radius: 10px;
  text-decoration: none;
  margin-bottom: 8px;
  color: #333333;
  cursor: pointer;
  :hover {
    background: #30c594;
    color: white;
  }
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  background-color: ${(props) => (props.selected ? '#f0f0f0' : 'none')};
  font-weight: ${(props) => (props.selected ? 700 : 400)};
`;
const PageContainer = styled.section`
  flex: 1;
  padding: 0 20px 24px 20px;
  background: inherit;
  text-align: left !important;
  > * {
    margin-bottom: 8px;
  }
`;
const PageHeaderContainer = styled.div`
  background: inherit;
  position: sticky;
  top: ${headerHeight};
  padding-top: ${paddingTop};
  background-color: white;
`;
const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  margin-bottom: 8px;
`;
const PageToolbarContainer = styled.div`
  display: flex;
  align-self: flex-end;
`;
const PageTitlesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const PageTitle = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 36px;
  display: flex;
  color: #161616;
  margin-right: 4px;
  margin-bottom: 4px;
`;
const PageSubTitle = styled(PageTitle)`
  font-weight: 400;
`;

type SideNavMenuItemProps = {
  item: RouteDefinition;
  selected: boolean;
};
const SideNavMenuItem: React.FC<SideNavMenuItemProps> = ({
  item,
  selected,
}) => {
  const { push } = useHistory();
  return (
    <SideNavItemContainer selected={selected} onClick={() => push(item.path)}>
      {item.label}
    </SideNavItemContainer>
  );
};

const prefixWith = (path: string) => (route: RouteDefinition) => ({
  ...route,
  path: path + route.path,
});

type EnterpriseDashboardPageProps = {
  title: string;
  subTitle: string;
  toolbar?: React.ReactElement;
  loading?: boolean;
};

export const EnterpriseDashboardPage: React.FC<
  EnterpriseDashboardPageProps
> = ({ title, subTitle, toolbar, loading, children }) => (
  <>
    <PageHeaderContainer>
      <PageHeader>
        <PageTitlesContainer>
          <PageTitle>{title}</PageTitle>
          <PageSubTitle>{subTitle}</PageSubTitle>
        </PageTitlesContainer>
        <PageToolbarContainer>{toolbar}</PageToolbarContainer>
      </PageHeader>
      <Divider />
    </PageHeaderContainer>
    {loading ? <Loading /> : <div className="pb-10">{children}</div>}
  </>
);

export const EnterpriseDashboardLayout: React.FC<Props> = ({ routes }) => {
  const { alert, setAlert } = useContext(AlertManager);
  const { url, path: rootPath } = useRouteMatch();
  const firstPath = routes[0].path;
  const pathname = useHistory().location.pathname;
  const activePath = pathname.replace(url, '') ?? firstPath;
  const rootAwareSubRoutes = useMemo(
    () => routes.map(prefixWith(rootPath)),
    [rootPath],
  );

  return (
    <Container>
      {alert && (
        <AlertContainer>
          <Alert
            title={alert.title}
            severity={alert.severity}
            onClose={() => setAlert(undefined)}
          >
            {alert.message}
          </Alert>
        </AlertContainer>
      )}
      <ColumnsContainer>
        <SideNavContainer>
          <StickyContainerWrapper>
            <StickyContainer>
              {routes.map((route) => (
                <SideNavMenuItem
                  key={route.path}
                  item={prefixWith(rootPath)(route)}
                  selected={route.path === activePath}
                />
              ))}
            </StickyContainer>
          </StickyContainerWrapper>
        </SideNavContainer>
        <PageContainer>
          <Switch>
            {rootAwareSubRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                component={route.content}
              />
            ))}
            <Route
              path=""
              component={() => <Redirect to={rootAwareSubRoutes[0].path} />}
            />
          </Switch>
        </PageContainer>
      </ColumnsContainer>
    </Container>
  );
};
