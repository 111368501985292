import { Component } from 'react';
import { Link } from 'react-router-dom';

import { Container, Section } from '../components/Base';
import Emojis, { TYPE_PREMIUM } from '../components/Emojis';
import NewsletterForm from '../components/NewsletterForm';
import { getTitle } from '../utils/document';

import { Helmet } from 'react-helmet';
import './ComingSoonPage.scss';

class ComingSoonPage extends Component {
  render() {
    return (
      <>
        <Helmet>
          <title>{getTitle('Coming soon')}</title>
        </Helmet>
        <Section className="nomatch first-sec">
          <Container>
            <h1>
              Sshh, this isn’t <span className="nowrap">ready, yet</span>
            </h1>
            <Emojis dim={true} space={true} type={TYPE_PREMIUM} />
            <p>
              Something new is coming here soon. Stay tuned!
              <br />
              <br />
              <Link to={'/'}>Return to home</Link>
            </p>
          </Container>
        </Section>
        <Section className="newsletter-sec extendbg-lol">
          <Container>
            <NewsletterForm />
          </Container>
        </Section>
      </>
    );
  }
}

export default ComingSoonPage;
