import React from 'react';

import OrphanText from './OrphanText';

export default ({ name, date, text, img, img2x }) => {
  const isRetina = window.devicePixelRatio || 1 > 1;
  const style = {
    backgroundImage: `url(${isRetina ? img2x : img})`,
  };
  const paragraphs = text.split('\n');
  return (
    <div className="review">
      <span className="face" style={style} />
      <strong>{name}</strong>
      <p className="stars stars-5">
        <span className="sr-only">5 stars</span>
      </p>
      <p className="date">Reviewed on {date}</p>
      {paragraphs.map((p) => (
        <p key={p}>
          <OrphanText text={p} />
        </p>
      ))}
    </div>
  );
};
