import PropTypes from 'prop-types';
import React, { Component } from 'react';

import './Video.scss';

const HEADER_HEIGHT = 60;

const VideoWrap = ({ styles, width, height, children }) => {
  const containerStyle = (styles && styles.container) || {
    marginLeft: 'auto',
    marginRight: 'auto',
    // make sure we vertically fit into the viewport
    maxWidth: `${(width / (height + HEADER_HEIGHT)) * 80}vh`,
  };
  const innerContainerStyle = (styles && styles.innerContainer) || {
    paddingBottom: `${(100 * height) / width}%`,
  };

  return (
    <div className="compvideo" style={containerStyle}>
      <div className="compvideo-inner" style={innerContainerStyle}>
        <div className="abs-wrapper">{children}</div>
      </div>
    </div>
  );
};

export default VideoWrap;
