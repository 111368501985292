import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  cancelAccountSubscription,
  updateAccountCancelAtPeriodEnd,
} from '../api';
import {
  STATE_LOADING,
  handleSignOutUser,
  useAuthState,
} from '../auth/AuthProvider';
import { useAccountContext } from '../context/AccountContext';
import { AlertManager } from '../context/AlertManager';
import { ACCOUNT_TYPE_ORG } from '../types/Account';
import { getUserProvider, getUserProviderText } from '../utils/user-provider';
import { bracket, capitalize, snakeToSpace } from '../utils/utils';
import CancelTrialWidget from './CancelTrialWidget';
import SubscriptionWillCancelHeader from './SubscriptionWillCancelHeader';
import UpdateCardWidget from './UpdateCardWidget';
import Divider from './atoms/divider';
import { Disclaimer, Label, LabelValue, Link } from './atoms/typography';
import { EnterpriseSupportEmailLink } from './organisms/support-email-link';
import { EnterpriseDashboardPage } from './templates/EnterpriseDashboardLayout';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  > * {
    margin-bottom: 16px;
  }
`;
const UpdateCardWidgetContainer = styled.div`
  width: 100%;
  #fp-update-card-form-container {
    color: #999;
  }
`;

const EnterpriseAccountOverview: React.FC = () => {
  const history = useHistory();

  const { authState, user } = useAuthState();
  const loadingUser = authState === STATE_LOADING;

  const { setAlert } = useContext(AlertManager);
  const {
    loading: loadingAccount,
    organization,
    setOrganization,
    refetch,
  } = useAccountContext();
  const loading = loadingUser || loadingAccount;
  const subscription = organization?.subscription;
  const hasActiveSubscription =
    !!subscription && subscription.status !== 'canceled';

  const subscriptionStatus = bracket(
    capitalize(snakeToSpace(subscription?.status?.replace('active', ''))),
  );

  // TODO - instead of `refetch` calls, just update the state with the result of the update calls!
  const doUpdateAccountCancelAtPeriodEnd = useCallback(
    (cancelAtPeriodEnd: boolean) =>
      updateAccountCancelAtPeriodEnd(cancelAtPeriodEnd).then(async (acct) => {
        await refetch();
        return acct;
      }),
    [refetch],
  );

  return (
    <EnterpriseDashboardPage
      title="🖼 Account"
      subTitle="Overview"
      loading={loading || !user}
    >
      <Container>
        <SubscriptionWillCancelHeader
          subscription={subscription}
          updateAccountCancelAtPeriodEnd={doUpdateAccountCancelAtPeriodEnd}
        />
        <LabelValue label="Email" value={user?.attributes.email} />
        <LabelValue
          label="Subscription"
          value={
            hasActiveSubscription
              ? `Enterprise ${subscriptionStatus}`
              : 'Inactive'
          }
        />
        {hasActiveSubscription && (
          <>
            <LabelValue
              label="Plan size"
              value={`${organization?.seats ?? 0} seats`}
            />
            <LabelValue
              label="Frequency"
              value={`${subscription.interval_count || '1'}-${
                organization?.subscription?.interval || 'year'
              }`}
            />
            <LabelValue
              label="Current subscription period"
              value={`${subscription.current_period_start.format(
                'LL',
              )} – ${subscription.current_period_end.format('LL')}`}
            />
            <LabelValue
              label="Log-in method"
              value={getUserProviderText(getUserProvider(user))}
            />
            <LabelValue
              style={{ width: '480px' }}
              label="Card details"
              value={
                <UpdateCardWidgetContainer>
                  <UpdateCardWidget
                    account={organization}
                    accountType={ACCOUNT_TYPE_ORG}
                    updateOrganization={setOrganization}
                    setError={(err: Error) =>
                      setAlert({
                        title: err.name,
                        message: err.message,
                        severity: 'error',
                      })
                    }
                    history={history}
                  />
                </UpdateCardWidgetContainer>
              }
            />
          </>
        )}
        <Divider />
        <Label>
          <b>Actions</b>
        </Label>
        <CancelTrialWidget
          align="left"
          user={user}
          account={organization}
          updateAccountCancelAtPeriodEnd={doUpdateAccountCancelAtPeriodEnd}
          cancelAccountSubscription={() =>
            cancelAccountSubscription().then(refetch)
          }
          underlineCta
        />
        <Link onClick={handleSignOutUser}>Sign out</Link>
        <Disclaimer>
          Contact us at{' '}
          <EnterpriseSupportEmailLink organization={organization} /> to
          re-assign admin role, change plan size, or for further assistance.
        </Disclaimer>
      </Container>
    </EnterpriseDashboardPage>
  );
};

export default EnterpriseAccountOverview;
