import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Player,
  ControlBar,
  ReplayControl,
  ForwardControl,
  CurrentTimeDisplay,
  TimeDivider,
  PlaybackRateMenuButton,
  VolumeMenuButton,
} from 'video-react';

import VideoWrap from './VideoWrap';

import 'video-react/dist/video-react.css';

const HEADER_HEIGHT = 60;

export default class Video extends Component {
  static propTypes = {
    poster: PropTypes.string,
    srcMp4: PropTypes.string.isRequired,
    srcWebm: PropTypes.string,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    autoPlay: PropTypes.bool.isRequired,
    controls: PropTypes.bool.isRequired,
    preload: PropTypes.oneOf(['none', 'metadata', 'auto']),
    loop: PropTypes.bool.isRequired,
    eltStyles: PropTypes.object,
  };

  static defaultProps = {
    autoPlay: true,
    preload: 'none',
    controls: false,
    loop: true,
  };

  render() {
    const {
      poster,
      srcMp4,
      srcWebm,
      width,
      height,
      autoPlay,
      preload,
      controls,
      loop,
      eltStyles,
    } = this.props;

    return (
      <VideoWrap styles={eltStyles} width={width} height={height}>
        <Player
          aspectRatio={`${width}:${height}`}
          playsInline
          poster={poster}
          autoPlay={autoPlay}
          muted
          loop={loop}
          controls={controls}
          preload={preload}
          style={{ paddingTop: 0, height: '100%' }}
        >
          <source src={srcMp4} type="video/mp4" />
          {srcWebm && <source src={srcWebm} type="video/webm" />}

          <ControlBar>
            <CurrentTimeDisplay order={4.1} />
            <TimeDivider order={4.2} />
            <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
            <VolumeMenuButton disabled={true} />
          </ControlBar>
        </Player>
      </VideoWrap>
    );
  }
}

// <ControlBar>
//   <ReplayControl seconds={10} order={1.1} />
//   <ForwardControl seconds={30} order={1.2} />
//   <CurrentTimeDisplay order={4.1} />
//   <TimeDivider order={4.2} />
//   <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
//   <VolumeMenuButton disabled />
// </ControlBar>
