import * as Sentry from '@sentry/react';
import { Breadcrumb } from '@sentry/react';
import { SENTRY_DSN } from '../constants';
import log from './log';
// import { Scope } from '@sentry/react';

export const addBreadcrumb = (
  category: string,
  message: string,
  data?: { [key: string]: any },
) => {
  if (SENTRY_DSN) {
    const breadcrumb: Breadcrumb = {
      // type?: string;
      level: Sentry.Severity.Debug,
      category,
      message,
      data,
    };
    Sentry.addBreadcrumb(breadcrumb);
  } else {
    log.info(
      `%c[SENTRY_BREADCRUMB] [${category}]`,
      'background:#999',
      message,
      data,
    );
  }
};

export const captureMessage = (message: string) => {
  if (SENTRY_DSN) {
    // let context: Scope;
    Sentry.captureMessage(message);
  } else {
    log.info(`%c[SENTRY_MESSAGE]`, 'background:#fb6', message);
  }
};

export const captureException = (exception: Error) => {
  log.error(exception);
  if (SENTRY_DSN) {
    Sentry.captureException(exception);
  } else {
    log.info(`%c[SENTRY_MESSAGE]`, 'background:#f66', exception);
  }
};
