import { useEffect } from 'react';
import ImagePage from './pages/images';

export default function ImageHostingApp() {
  useResetRem();
  return (
    <div className="normalize">
      <ImagePage />
    </div>
  );
}

function useResetRem() {
  useEffect(() => {
    const html = document.body.parentElement;
    html?.style.setProperty('font-size', '16px');
    return () => {
      html?.style.removeProperty('font-size');
    };
  }, []);
}
