import Postmate from 'postmate';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { EXTENSION_LANDING_PATH, EXTENSION_ORIGINS } from '../constants';

import { genColorLog } from '../logger';
import { sleep } from '../utils/promise-util';

const STYLE = 'color:#F60;background:#FFF6EE;';
const colorLog = genColorLog(STYLE);

export const EXTENSION_PROPS = {
  extensionIsInstalled: PropTypes.bool.isRequired, // uses cache before connected
  extensionIsConnected: PropTypes.bool.isRequired, // true iff has connected in current page session
  extensionGetHandshake: PropTypes.func.isRequired,
};

const CONTEXT_KEY = 'extensionProvider';
const CONTEXT_TYPES = {
  [CONTEXT_KEY]: PropTypes.shape(EXTENSION_PROPS).isRequired,
};

const LOCAL_CONNECTED_KEY = '__extensionProviderCnct';
const LOCAL_CONNECTED_VAL = '1';

export class ExtensionProvider extends Component {
  static childContextTypes = CONTEXT_TYPES;
  static propTypes = {
    extensionApiUrls: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    ListenerClass: PropTypes.func, // type Class
    container: PropTypes.object.isRequired,
    classListArray: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  };
  static defaultProps = {
    container: document.body,
    classListArray: ['h'],
  };

  // constructor
  constructor(props) {
    super(props);

    const isInstalled = this._getConnected();

    this.state = { isInstalled, isConnected: false, extensionOrigin: null };
    this._postmateChild = null;
    this._extensionOrigin = null;

    this._mounted = true;
  }

  // provider
  getChildContext() {
    const { isInstalled, isConnected, extensionOrigin, postmateError } =
      this.state;
    return {
      [CONTEXT_KEY]: {
        extensionIsInstalled: isInstalled,
        extensionIsConnected: isConnected,
        extensionGetHandshake: this.getHandshake,
        extensionOrigin: extensionOrigin,
      },
    };
  }

  // life-cycle
  componentDidMount() {
    // HACK - skip for /_iframe page
    const pathname = window.location.pathname;
    const lastPathPart = pathname.split('/').pop();

    if (lastPathPart.substring(0, 1) === '_') {
      // skip extension provider for /_iframe and /_test
      colorLog(`[extension.Provider] skipping for ${pathname}`);
      return;
    }

    this.getHandshake()
      .then((child) => {
        if (!this._mounted) {
          return;
        }

        colorLog(`>> [Postmate.ExtProv.mounted] got handshake`);

        this.setState({
          isConnected: true,
          isInstalled: true,
          extensionOrigin: this._extensionOrigin,
        });
        this._setConnected(true);
      })
      .catch((err) => {
        if (this._mounted) {
          colorLog(`>> [Postmate.ExtProv.mounted] handshake ERROR`, err);

          this.setState({ isConnected: false, isInstalled: false });
          this._setConnected(false);
        }
      });

    // optional listener class
    // should have constructor that takes getHandshake promise and a
    // destroy method
    if (this.props.ListenerClass) {
      this.listener = new this.props.ListenerClass(this.getHandshake());
    }
  }

  componentWillUnmount() {
    this._mounted = false;
    window.clearTimeout(this.timeoutId);
    if (this._postmateChild) {
      this._postmateChild.destroy();
    }
    if (this.listener && this.listener.destroy) {
      this.listener.destroy();
    }
  }

  // ## methods

  getHandshake = () => {
    // preserve `this` via arrow function

    if (!this._handshake) {
      const { extensionApiUrls, container, classListArray } = this.props;

      this._handshake = new Promise((resolve, reject) => {
        colorLog(`>> [Postmate.ExtProv] begin handshake`);

        let uriIndex = -1;
        let didResolve = false;

        const attemptNextUri = () => {
          // postmate handshake
          uriIndex++;
          const extensionApiUrl = extensionApiUrls[uriIndex];
          colorLog(`>> [Postmate.ExtProv.attempt] ${extensionApiUrl}`);

          if (!extensionApiUrl) {
            sleep(10000)
              .then(() => {
                if (!didResolve) {
                  const err = new Error('No matching URLs');
                  err.name = 'NoUrls';
                  reject(err);
                }
              })
              .catch((err) => {
                reject(err);
              });
            return;
          }

          // timeout check - since postmate can just hang
          // without rejecting
          //
          // TODO - handle rejections in a better
          // way... previously the 500ms was too
          // fast for some users and they were
          // unable to login. Now we still try
          // multiple extensions if available
          // e.g., Edge can be the Add-ons or
          // Web store extension, with the same
          // timeout of 500ms, but have an over-
          // arching 10s delay at end before we
          // give up.
          let startTime = new Date().getTime();
          let didTimeout = false;

          const timeoutId = window.setTimeout(() => {
            didTimeout = true;
            const nowTime = new Date().getTime();
            colorLog(
              `>> [Postmate.ExtProv.TIMEDOUT] (${nowTime - startTime}ms)`,
            );
            attemptNextUri();
          }, 500);

          new Postmate({
            container,
            url: extensionApiUrl,
            classListArray,
          })
            .then((child) => {
              const nowTime = new Date().getTime();
              colorLog(
                `>> [Postmate.ExtProv.then] got child (${
                  nowTime - startTime
                }ms) (didResolve=${didResolve}, didTimeout=${didTimeout}, isMounted=${
                  this._mounted
                })`,
              );
              window.clearTimeout(timeoutId);

              if (didResolve || !this._mounted) {
                child.destroy();
                return;
              }

              this._postmateChild = child;
              // chrome-extension://asdf/p/_api.html -> chrome-extension://asdf
              this._extensionOrigin = extensionApiUrl
                .split('/')
                .slice(0, 3)
                .join('/');

              didResolve = true;
              resolve(child);
            })
            .catch((err) => {
              colorLog(`>> [Postmate.Error]`, err);
              window.clearTimeout(timeoutId);
              attemptNextUri();
            });
        };

        attemptNextUri();
      });
    }

    return this._handshake;
  };

  // ## helpers

  _getConnected() {
    return window.localStorage
      ? window.localStorage.getItem(LOCAL_CONNECTED_KEY) === LOCAL_CONNECTED_VAL
      : false;
  }

  // ### Set connected
  //
  // Cache the connected state of the extension
  //
  _setConnected(isConnected) {
    if (window.localStorage) {
      window.localStorage.setItem(
        LOCAL_CONNECTED_KEY,
        isConnected ? LOCAL_CONNECTED_VAL : '',
      );
      return true;
    }
    return false;
  }

  // render
  render() {
    return <>{this.props.children}</>;
  }
}

const connectExtension = (Comp) => {
  return class ExtensionAware extends Component {
    static contextTypes = CONTEXT_TYPES;

    render() {
      const ctxData = this.context[CONTEXT_KEY];
      return <Comp {...ctxData} {...this.props} />;
    }
  };
};

export default connectExtension;

export const getExtensionLanding = (extensionOrigin) => {
  extensionOrigin = extensionOrigin || EXTENSION_ORIGINS[0];
  return extensionOrigin + EXTENSION_LANDING_PATH;
};
