import assert from 'minimalistic-assert';
import PropTypes from 'prop-types';
import { Component } from 'react';

import { getAbPlanData } from '../abtest';
import { Button, Container, PrimaryButton, Section } from '../components/Base';
import Dollars from '../components/Dollars';
import Emojis, { TYPE_PREMIUM } from '../components/Emojis';
import { connectGTM } from '../components/GTMListener';
import SupportsFeature from '../components/SupportsFeature';
import VideoYoutube from '../components/VideoYoutube';
import { EDITOR_VIDEO } from '../constants';
import { log } from '../logger';
import { getTitle } from '../utils/document';
import { getEcommAddToCart, getEcommProductDetail } from '../utils/ga-ecomm';

import { Helmet } from 'react-helmet';
import './PremiumPage.scss';
import page from './PremiumPageData.ts';

assert(EDITOR_VIDEO, 'EDITOR_VIDEO is not defined');

class PremiumPage extends Component {
  static propTypes = {
    dataLayerPush: PropTypes.func.isRequired,
  };

  // Life-cycle
  componentDidMount() {
    const prodDetail = getEcommProductDetail('Premium Page');
    log(`ECOM PRODUCT VIEW: ${JSON.stringify(prodDetail)}`);
    this.props.dataLayerPush(prodDetail);
  }

  // Handlers
  handleProductClick = (evt) => {
    const ecommAddToCart = getEcommAddToCart('Premium Page');
    log(`ECOM ADD TO CART: ${JSON.stringify(ecommAddToCart)}`);
    this.props.dataLayerPush(ecommAddToCart);
  };

  // Render
  render() {
    const plan = getAbPlanData();

    const ctaButton = (
      <PrimaryButton
        className="btn-standout btn-convert"
        type={Button.TYPE_ROUTE}
        to="/checkout"
        size="large"
        onClick={this.handleProductClick}
      >
        Get started (free 1-week trial)
      </PrimaryButton>
    );
    return (
      <div className="premium">
        <Helmet>
          <title>{getTitle('Go Premium')}</title>
        </Helmet>
        <Section className="first-sec">
          <Container>
            <div className="gfp-row">
              <div className="col-8 offset-2">
                <h1>
                  Take your screenshots to the next level with GoFullPage
                  Premium
                </h1>
                <Emojis type={TYPE_PREMIUM} space={true} />
                <p>{ctaButton}</p>
                <p className="hero-info">
                  Support this independent extension and improve your workflow
                  with premium, including advanced editing and annotation
                  features of screenshots for only{' '}
                  <strong className="whitespace-nowrap">
                    <Dollars
                      cents={plan.amount}
                      monthly={true}
                      simplify={true}
                    />{' '}
                    a month
                  </strong>
                  .
                </p>
              </div>
            </div>
          </Container>
        </Section>
        <Section className="blue-sec">
          <Container>
            <p>See a demo of the new premium editor</p>
            <VideoYoutube
              url={EDITOR_VIDEO.youtube}
              width={EDITOR_VIDEO.width}
              height={EDITOR_VIDEO.height}
            />
          </Container>
        </Section>
        <Section>
          <Container>
            <div className="gfp-row">
              <div className="col-8 offset-2">
                <table className="features-table">
                  <thead>
                    <tr>
                      <th className="name-col">GoFullPage Features</th>
                      <th>Basic</th>
                      <th>Premium</th>
                    </tr>
                  </thead>
                  <tbody>
                    {page.features.map((f) => (
                      <tr key={f.name}>
                        <td className="name-col">{f.name}</td>
                        <td>
                          <SupportsFeature supports={f.basic} />
                        </td>
                        <td>
                          <SupportsFeature supports={f.premium} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Container>
        </Section>
        <Section>
          <Container>
            {ctaButton}
            <br />
            <br />
            <br />
          </Container>
        </Section>
      </div>
    );
  }
}

export default connectGTM(PremiumPage);
