/**
 * Helpers for running something like
 * an AB test in Google Analytics
 * using the GTM.
 */

import { useRef } from 'react';
import localStorage from './utils/localStorage';

export const randInt = (num: number) => {
  return Math.floor(Math.random() * num);
};

export const resetAbTestVal = (key: string) => {
  localStorage.removeItem(key);
};

export const getAbTestVal = (key: string, numChoices: number = 2) => {
  const val = localStorage.getItem(key);
  if (val && /^\d+$/.test(val)) {
    const num = parseInt(val);
    if (num >= 0 && num < numChoices) {
      return num;
    }
  }

  const num = randInt(numChoices);
  localStorage.setItem(key, String(num));

  return num;
};

/**
 * Get a localStorage backed
 * AB test value for the given
 * key and randomly chosen from
 * the given number of choices.
 */
export const useAbTestVal = ({
  key,
  numChoices,
}: {
  key: string;
  numChoices?: number;
}) => {
  const valRef = useRef<number | null>(null);
  if (valRef.current == null) {
    valRef.current = getAbTestVal(key, numChoices);
  }
  return valRef.current;
};

// ## Individual tests

// ### Homepage tests 2024-06

export const AB_HP202406_KEY = 'ab:hp202406';

export const AB_HP202406_TESTS = ['old', 'new'];

export const AB_HP202406_CFG = {
  key: AB_HP202406_KEY,
  numChoices: AB_HP202406_TESTS.length,
};
