import React from 'react';
import styled, { css } from 'styled-components';

export const TypographyCss = css`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #151515;
  margin: 0;
`;

export const Text = styled.p`
  ${TypographyCss};
  color: #333333;
`;
Text.displayName = 'Text';

export const Label = styled(Text)`
  color: #151515;
`;
Label.displayName = 'Label';

export const Value = styled(Label)`
  color: #999999;
`;
Value.displayName = 'Value';

export const Error = styled(Text)`
  color: #c73131;
`;
Error.displayName = 'Error';

export const Disclaimer = styled(Value)`
  font-size: 14px;
`;
Disclaimer.displayName = 'Disclaimer';

const LabelValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;
LabelValueContainer.displayName = 'LabelValueContainer';

type LabelValueProps = React.HtmlHTMLAttributes<HTMLDivElement> & {
  label: string;
  value: string | React.ReactNode;
};

export const LabelValue: React.FC<LabelValueProps> = ({
  label,
  value,
  ...props
}) => (
  <LabelValueContainer {...props}>
    <Label>{label}</Label>
    {typeof value === 'string' ? <Value>{value}</Value> : <>{value}</>}
  </LabelValueContainer>
);
LabelValue.displayName = 'LabelValue';

// ${TypographyCss};

export const Link = styled.a`
  cursor: pointer;
  color: #999999;
  text-decoration: underline;
`;
Link.displayName = 'Link';

export const AccentLink = styled(Link)`
  color: #36b6e6;
  text-decoration: none;
`;
AccentLink.displayName = 'AccentLink';
