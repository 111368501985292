import classNames from 'classnames';

export default ({ cols, elts, eltClassName }) => {
  const classes = classNames({
    [`col-${12 / cols}`]: true,
    [eltClassName]: eltClassName,
  });

  const rows = [];
  let row;

  elts.forEach((elt, i) => {
    if (i % cols === 0) {
      row = [];
      rows.push(row);
    }
    row.push(elt);
  });

  return (
    <>
      {rows.map((row, i) => (
        <div key={i} className="gfp-row">
          {row.map((elt, j) => (
            <div key={j} className={classes}>
              {elt}
            </div>
          ))}
        </div>
      ))}
    </>
  );
};
