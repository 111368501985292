import { Component } from 'react';

import { Helmet } from 'react-helmet';
import { Button, Container, PrimaryButton, Section } from '../components/Base';
import Emojis from '../components/Emojis';
import { getTitle } from '../utils/document';

class NoMatchPage extends Component {
  render() {
    return (
      <Section className="nomatch first-sec">
        <Helmet>
          <title>{getTitle('Page not found')}</title>
        </Helmet>
        <Container>
          <h1>Page not found</h1>
          <Emojis dim={true} space={true} />
          <PrimaryButton
            type={Button.TYPE_ROUTE}
            to="/"
            className="btn-standout"
          >
            Return to home
          </PrimaryButton>
        </Container>
      </Section>
    );
  }
}

export default NoMatchPage;
