import logoBlt from '../../assets/img/logos/log-blt.png';
import logoBlt2x from '../../assets/img/logos/log-blt@2x.png';
import logoBus from '../../assets/img/logos/log-bus.png';
import logoBus2x from '../../assets/img/logos/log-bus@2x.png';
import logoFas from '../../assets/img/logos/log-fas.png';
import logoFas2x from '../../assets/img/logos/log-fas@2x.png';
import logoGiz from '../../assets/img/logos/log-giz.png';
import logoGiz2x from '../../assets/img/logos/log-giz@2x.png';
import logoGtm from '../../assets/img/logos/log-gtm.png';
import logoGtm2x from '../../assets/img/logos/log-gtm@2x.png';
import logoLif from '../../assets/img/logos/log-lif.png';
import logoLif2x from '../../assets/img/logos/log-lif@2x.png';
import logoMint from '../../assets/img/logos/log-mint.png';
import logoMint2x from '../../assets/img/logos/log-mint@2x.png';
import logoMud from '../../assets/img/logos/log-mud.png';
import logoMud2x from '../../assets/img/logos/log-mud@2x.png';
import logoPcm from '../../assets/img/logos/log-pcm.png';
import logoPcm2x from '../../assets/img/logos/log-pcm@2x.png';
import logoSel from '../../assets/img/logos/log-sel.png';
import logoSel2x from '../../assets/img/logos/log-sel@2x.png';
import logoTec from '../../assets/img/logos/log-tec.png';
import logoTec2x from '../../assets/img/logos/log-tec@2x.png';
import logoUsa from '../../assets/img/logos/log-usa.png';
import logoUsa2x from '../../assets/img/logos/log-usa@2x.png';
import logoZdn from '../../assets/img/logos/log-zdn.png';
import logoZdn2x from '../../assets/img/logos/log-zdn@2x.png';

export const LOGO_GUIDINGTECHMEDIA = {
  alt: 'Guiding Tech Media',
  src: logoGtm,
  src2x: logoGtm2x,
  width: 276,
  height: 120,
};

export const LOGO_SEARCHENGINELAND = {
  alt: 'Search Engine Land',
  src: logoSel,
  src2x: logoSel2x,
  width: 349,
  height: 56,
};

export const LOGO_FASTCOMPANY = {
  alt: 'FastCompany',
  src: logoFas,
  src2x: logoFas2x,
  width: 345,
  height: 54,
};

export const LOGO_BUILTIN = {
  alt: 'builtin',
  src: logoBlt,
  src2x: logoBlt2x,
  width: 300,
  height: 130,
};

export const LOGO_MAKEUSEOF = {
  alt: 'Make Use Of',
  src: logoMud,
  src2x: logoMud2x,
  width: 330,
  height: 100,
};

export const LOGO_MINT = {
  alt: 'Mint',
  src: logoMint,
  src2x: logoMint2x,
  width: 254,
  height: 90,
};

export const LOGO_LIFEHACKER = {
  alt: 'lifehacker',
  src: logoLif,
  src2x: logoLif2x,
  width: 352,
  height: 88,
};

export const LOGO_TECHRADAR = {
  alt: 'techradar',
  src: logoTec,
  src2x: logoTec2x,
  width: 351,
  height: 56,
};
export const LOGO_PCMAG = {
  alt: 'PC Mag',
  src: logoPcm,
  src2x: logoPcm2x,
  width: 157,
  height: 189,
};
export const LOGO_BUSINESSINSIDER = {
  alt: 'Business Insider',
  src: logoBus,
  src2x: logoBus2x,
  width: 280,
  height: 88,
};
export const LOGO_USATODAY = {
  alt: 'USA Today',
  src: logoUsa,
  src2x: logoUsa2x,
  width: 306,
  height: 111,
};
export const LOGO_GIZMODO = {
  alt: 'Gizmodo',
  src: logoGiz,
  src2x: logoGiz2x,
  width: 350,
  height: 50,
};
export const LOGO_ZDNET = {
  alt: 'ZDNet',
  src: logoZdn,
  src2x: logoZdn2x,
  width: 194,
  height: 50,
};
