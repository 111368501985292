// useful with Header and Footer to disable click out links
export const isCheckout = (location) =>
  location.pathname.startsWith('/checkout');

/**
 * Prepend the protocol and host to the path.
 * @param {string} path absolute path (starting with /)
 */
export const asAbsoluteUrl = (path) => {
  return `${window.location.protocol}//${window.location.host}${path}`;
};

/**
 * Update the query string of the path
 * @param {{[key: string]: string | null}} updates
 * @param {Location} [location]
 * @param {URLSearchParams} [params] will be mutated
 */
export const updateQueryString = (updates, location, params) => {
  location = location || window.location;
  params = params || new URLSearchParams(location.search);

  Object.entries(updates).forEach(([key, val]) => {
    if (val == null) {
      params.delete(key);
    } else {
      params.set(key, val);
    }
  });

  let search = params.toString();
  if (search) {
    search = '?' + search;
  }

  return location.pathname + search + location.hash;
};

export const insertUrlParam = (key, value) => {
  if (history.pushState) {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, value);
    const url =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      '?' +
      searchParams.toString();

    window.history.pushState({ path: url }, '', url);
  }
};
