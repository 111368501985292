export default async function fetchAdapter(
  input: RequestInfo,
  init?: RequestInit,
) {
  return fetch(input, {
    ...init,
    headers: {
      'Content-Type': 'application/json',
      ...init?.headers,
    },
  });
}

export const urls = {
  api: {
    images: `/images/`,
    image: (id: string) => `/images/${id}/`,
  },
};
