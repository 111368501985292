import React from 'react';

const DotsMenuIcon: React.FC<React.HtmlHTMLAttributes<HTMLOrSVGElement>> = (
  props,
) => (
  <svg
    width="17"
    height="4"
    viewBox="0 0 17 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="2.77783" cy="2" r="2" fill={props.color} />
    <circle cx="8.77783" cy="2" r="2" fill={props.color} />
    <circle cx="14.7778" cy="2" r="2" fill={props.color} />
  </svg>
);

export default DotsMenuIcon;
