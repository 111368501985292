import { PropsWithChildren } from 'react';
import { MdArrowLeft, MdCameraAlt } from 'react-icons/md';
import { classes } from '../../common/utils';

export type HeaderProps = PropsWithChildren<{
  title: string;
  onNavigateBack?: () => void;
}>;

export default function Header({
  title,
  onNavigateBack: back,
  children,
}: HeaderProps) {
  return (
    <header className="relative z-10 flex justify-between bg-black px-12 pb-0 pt-px text-white">
      <div className="row space-x-4 pl-4">
        <button className="group row space-x-1" onClick={back} disabled={!back}>
          <MdArrowLeft
            className={classes(
              !back && '!hidden',
              '-ml-6 h-6 w-6 transition-all group-hover:-translate-x-1',
            )}
          />
          <MdCameraAlt className="h-7 w-7" />
        </button>
        <span className="-mt-1 text-2xl">{title}</span>
      </div>
      <div className="row space-x-px">{children}</div>
    </header>
  );
}
