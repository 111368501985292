import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import TermsOfUsePage from '../pages/TermsOfUsePage.mdx';

export default class TermsOfUseContent extends PureComponent {
  static propTypes = {
    noSelect: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    noSelect: false,
  };

  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount() {
    const domElt = this.ref.current;
    if (this.props.noSelect && domElt) {
      // HACK - set tabIndex -1 so we don't tab into this by mistake
      Array.prototype.slice
        .call(domElt.getElementsByTagName('a'))
        .forEach((link) => {
          link.tabIndex = -1;
        });
    }
  }

  render() {
    return (
      <div ref={this.ref}>
        <div className="prose legal-text">
          <TermsOfUsePage />
        </div>
      </div>
    );
  }
}
