import PropTypes from 'prop-types';
import { Component, Fragment } from 'react';
import { STATE_LOADING, STATE_SIGNEDIN } from '../auth/AuthProvider';
import { IS_MASTER } from '../constants';
import { getIsMac } from '../sniff';
import { getUserProvider, getUserProviderText } from '../utils/user-provider';
import './Account.scss';
import AccountFields from './AccountFields';
import AccountPanel from './AccountPanel';
import {
  Button,
  ButtonSizes,
  CardSection,
  CardSectionMinimal,
  Container,
  PrimaryButton,
  Section,
} from './Base';
import CancelTrialWidget from './CancelTrialWidget';
import Dollars from './Dollars';
import Emojis, { TYPE_DOLLAR, TYPE_PREMIUM } from './Emojis';
import ErrorMessage from './ErrorMessage';
import NewsletterForm from './NewsletterForm';
import SubscriptionWillCancelHeader from './SubscriptionWillCancelHeader';
import UpdateCardWidget from './UpdateCardWidget';

class Account extends Component {
  static propTypes = {
    authState: PropTypes.string.isRequired,
    signInUser: PropTypes.func.isRequired,
    signUpUser: PropTypes.func.isRequired,
    signOutUser: PropTypes.func.isRequired,
    user: PropTypes.object,
    username: PropTypes.string,
    account: PropTypes.shape({
      userId: PropTypes.string.isRequired,
      customerId: PropTypes.string,
      orgId: PropTypes.string,
      orgRole: PropTypes.string,
      subscription: PropTypes.object,
      card: PropTypes.object,
      payments: PropTypes.array,
    }),
    error: PropTypes.string,
    setError: PropTypes.func.isRequired,
    cancelAccountSubscription: PropTypes.func.isRequired,
    updateAccountCancelAtPeriodEnd: PropTypes.func.isRequired,
    updateAccount: PropTypes.func.isRequired,
    nextUrl: PropTypes.string,
    clearNextUrl: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    successOrgLinkParams: PropTypes.object,
    newsletterSuccess: PropTypes.bool,
    onNewsletterSuccess: PropTypes.func,
    isOrgMember: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  render() {
    const {
      authState,
      error,
      signInUser,
      signUpUser,
      signOutUser,
      user,
      username,
      account,
      cancelAccountSubscription,
      updateAccountCancelAtPeriodEnd,
      setError,
      successOrgLinkParams,
      newsletterSuccess,
      onNewsletterSuccess,
      isOrgMember,
    } = this.props;

    const params = new URLSearchParams(window.location.search);
    const _hideVal = params.get('hide_account');
    const hideAccountInfo = _hideVal === '1';

    const canShowUpdateEmail = !hideAccountInfo && Boolean(account);

    const { payments, customerId, subscription, userId } = account || {};

    let redirElt = null;
    let mainElt = null;
    let paymentsElt = null;
    let buttons = null;

    const email = (user && user.attributes && user.attributes.email) || '';

    const loginMethod = getUserProviderText(getUserProvider(user));

    const debugField =
      !IS_MASTER && subscription && !hideAccountInfo
        ? {
            title: 'DEBUG',
            body: (
              <>
                {[
                  ['userId', userId],
                  ['cus', customerId],
                  ['sub', subscription.id],
                  ['status', subscription.status],
                ].map(([k, v]) => (
                  <Fragment key={k}>
                    <p style={{ margin: 0 }} className="nowrap">
                      {k}: {v}
                    </p>
                  </Fragment>
                ))}
              </>
            ),
          }
        : null;

    switch (authState) {
      case STATE_LOADING: {
        mainElt = (
          <CardSection>
            <h3>Loading…</h3>
            <Emojis type={TYPE_PREMIUM} loading={true} space={true} />
          </CardSection>
        );
        break;
      }
      case STATE_SIGNEDIN: {
        // sub status: trialing, active, past_due, canceled, unpaid
        const fields = [];
        let buttonElt = null;
        let isBasic = false;

        let addedNextUrl = false;

        if (isOrgMember) {
          if (account.orgName) {
            fields.push({
              title: 'Group name',
              body: account.orgName,
            });
          }
          if (account.orgAdminEmail) {
            fields.push({
              title: 'Group administrator',
              body: account.orgAdminEmail,
            });
          }
          fields.push(
            { title: 'Subscription', body: 'Enterprise' },
            { title: 'Email', body: email },
            { title: 'Log-in Method', body: loginMethod },
          );
        } else if (subscription && subscription.status !== 'canceled') {
          // has active subscription or trial
          const isTrialing = subscription.status === 'trialing';
          const nextChargeDate = isTrialing
            ? subscription.trial_end
            : subscription.current_period_end;

          fields.push({
            title: 'Subscription',
            body: `Premium${isTrialing ? ' (in trial)' : ''}`,
          });
          if (!hideAccountInfo) {
            fields.push(
              {
                title: 'Frequency',
                body: `${subscription.interval_count}-${subscription.interval}`,
              },
              {
                title: subscription.cancel_at_period_end
                  ? 'Canceling on'
                  : 'Next charge',
                body: nextChargeDate.format('LL'),
              },
              {
                title: isTrialing ? 'Trial period' : 'Subscription period',
                body: `${subscription.current_period_start.format(
                  'LL',
                )} –  ${subscription.current_period_end.format('LL')}`,
              },
            );
          }

          fields.push(
            { title: 'Email', body: email },
            { title: 'Log-in Method', body: loginMethod },
          );

          if (!hideAccountInfo) {
            fields.push({
              title: 'Card details',
              body: (
                <UpdateCardWidget
                  account={account}
                  updateAccount={this.props.updateAccount}
                  setError={setError}
                  history={this.props.history}
                />
              ),
            });
          }

          // redirect element - show if logged in, has sub, and has nextUrl
          const { nextUrl } = this.props;
          if (nextUrl && authState === STATE_SIGNEDIN) {
            // onClick={clearNextUrl}
            addedNextUrl = true;
            redirElt = (
              <CardSection>
                <h3>Welcome back!</h3>
                <PrimaryButton
                  className="btn-standout btn-convert"
                  type="link"
                  size="medium"
                  href={nextUrl}
                >
                  Return to extension
                </PrimaryButton>
              </CardSection>
            );
          }
        } else if (account) {
          // has no sub or previous one is canceled (and is logged-in!)
          isBasic = true;

          fields.push(
            { title: 'Subscription', body: 'Basic' },
            { title: 'Email', body: email },
            { title: 'Log-in Method', body: loginMethod },
          );

          buttonElt = (
            <PrimaryButton
              className="btn-standout btn-convert"
              type={Button.TYPE_ROUTE}
              to="/checkout"
              href=""
            >
              Go premium
            </PrimaryButton>
          );
        }

        // payments (invoices) state
        if (payments && payments.length && !hideAccountInfo) {
          const paymentsFields = payments.map((p) => ({
            title:
              p.created.format('LL') +
              (p.status !== 'succeeded' ? ` (${p.status})` : ''),
            body: (
              <div className="payment-body">
                <span className="dim">{p.nickname}</span>
                <span className="money-line">
                  <Dollars cents={p.amount} />{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={p.receipt_url}
                  >
                    (receipt)
                  </a>
                </span>
                {p.refunds &&
                  p.refunds.length > 0 &&
                  p.refunds.map((ref, i) => (
                    <div key={`ref-${i}`} className="br br-top">
                      <span className="dim">
                        {p.status === 'pending'
                          ? 'Refund pending'
                          : `Refunded ${ref.created.format('LL')}`}
                      </span>
                      <span className="money-line money-line-refund">
                        <Dollars cents={-p.amount} />
                      </span>
                    </div>
                  ))}
              </div>
            ),
          }));

          paymentsElt = (
            <CardSection>
              <h3>Payments</h3>
              <Emojis type={TYPE_DOLLAR} space={true} />
              <AccountFields fields={paymentsFields} />
            </CardSection>
          );
        }

        if (debugField) {
          fields.push(debugField);
        }

        mainElt = (
          <AccountPanel
            user={user}
            username={username}
            canShowUpdate={canShowUpdateEmail}
            heading={
              (!hideAccountInfo && (
                <SubscriptionWillCancelHeader
                  subscription={subscription}
                  updateAccountCancelAtPeriodEnd={
                    updateAccountCancelAtPeriodEnd
                  }
                />
              )) ||
              null
            }
          >
            <Emojis
              type={TYPE_PREMIUM}
              loading={!account}
              dim={isBasic}
              space={true}
            />
            <AccountFields fields={fields} />
            {buttonElt}
          </AccountPanel>
        );

        buttons = [
          !addedNextUrl && this.props.nextUrl ? (
            <a
              key="back-to-extension"
              className="dim"
              href={this.props.nextUrl}
            >
              Back to extension
            </a>
          ) : null,
          hideAccountInfo ? null : (
            <CancelTrialWidget
              user={user}
              account={account}
              updateAccountCancelAtPeriodEnd={updateAccountCancelAtPeriodEnd}
              cancelAccountSubscription={cancelAccountSubscription}
            />
          ),
          <a key="sign-out" href="#" onClick={signOutUser}>
            Sign out
          </a>,
        ];
        break;
      }
      default: {
        mainElt = (
          <>
            <CardSection>
              <h3>You are not signed in</h3>
              <Emojis
                type={TYPE_PREMIUM}
                loading={false}
                dim={true}
                space={true}
              />
              <div className="spaced-items">
                <PrimaryButton
                  className="btn-standout"
                  size={ButtonSizes.small}
                  onClick={signInUser}
                >
                  Sign In
                </PrimaryButton>
                <span>
                  or{' '}
                  <a href="#" onClick={signUpUser}>
                    Sign Up
                  </a>
                </span>
              </div>
            </CardSection>
            <p className="dim">
              Errors with login? Try force reloading the page (
              {getIsMac() ? 'cmd + shift + r' : 'ctrl + F5'})
            </p>
          </>
        );

        break;
      }
    }

    return (
      <Section className="account first-sec">
        <Container>
          {successOrgLinkParams ? (
            <>
              <div className="card-box">
                <h1>Success</h1>
                <p>
                  Your account is part of {successOrgLinkParams.org}. Review the
                  <a href="https://gofullpage.notion.site/GoFullPage-Enterprise-Start-Guide-e3fd383f05a048eeaba096e44bfed161">
                    {' '}
                    start guide
                  </a>{' '}
                  to get started with your Premium features.
                </p>
              </div>
              {!newsletterSuccess && (
                <CardSection className="slim">
                  <NewsletterForm
                    pClassName="dim"
                    btnClassName="btn-convert btn-standout"
                    initialEmail={successOrgLinkParams.email}
                    onSuccess={onNewsletterSuccess}
                  />
                </CardSection>
              )}
            </>
          ) : (
            <h1>Account</h1>
          )}
          {error && (
            <ErrorMessage error={error} card={true} scrollIntoView={true} />
          )}
          {redirElt}
          {mainElt}
          {paymentsElt}
          {hideAccountInfo && (
            <CardSectionMinimal className="dim em">
              Contact your account administrator to make changes to this
              account.
            </CardSectionMinimal>
          )}
          {buttons && (
            <CardSectionMinimal>
              {buttons.filter(Boolean).map((b, i) => (
                <div key={i} className="account-buttons-elt">
                  {b}
                </div>
              ))}
            </CardSectionMinimal>
          )}
        </Container>
      </Section>
    );
  }
}

Account.displayName = 'Account';

export default Account;
