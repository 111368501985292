import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';

import { isCheckout } from '../utils/url-util';

import { getThemeVariant } from '../utils/theme-util';
import { Container } from './Base';
import CameraIcon from './icons/CameraIcon';

import './Header.scss';
import PushdownMessage from './PushdownMessage';

// const PUSHDOWN_MESSAGE = {
//   text: (
//     <>
//       Same team, new name. <strong>Full Page Screen Capture</strong> is now{' '}
//       <strong>GoFullPage</strong>!{' '}
//       <span className="nowrap">Read more on the blog.</span>
//     </>
//   ),
//   url: 'https://blog.gofullpage.com/2020/07/20/introducing-gofullpage-2/',
// };

const PUSHDOWN_MESSAGE = null;

const LINKS = [
  // {
  //   to: '/legal',
  //   name: 'GoFullPage Legal',
  //   coming_soon: true,
  // },
  {
    to: '/enterprise',
    name: 'GoFullPage Enterprise',
    // coming_soon: true,
  },
  {
    to: '/premium',
    name: 'Go Premium',
  },
  // {
  //   href: 'http://blog.gofullpage.com/',
  //   name: 'Blog',
  // },
  // {
  //   to: '/faq',
  //   name: 'FAQ',
  // },
  {
    to: '/sales',
    name: 'Contact Sales',
  },
  {
    to: '/account',
    name: 'Account',
  },
];

/** HACK: when true, force the header to have ab g, we do this on /enterprise/* because the UI is different */
const getForceBg = (path) => getThemeVariant(path) === 'enterprise-dash';

const SET_BG_AT = 5;

class Header extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { showBg: false, isToggleOn: false };
  }

  // Life-cycle
  componentDidMount() {
    this._mounted = true;
    document.body.addEventListener('click', this._clickCheck, false);
    window.addEventListener('scroll', this._handleScroll, false);
    Toggler.addListener(this.onToggle);
    this._handleScroll();
  }

  componentWillUnmount() {
    this._mounted = false;
    document.body.removeEventListener('click', this._clickCheck);
    window.removeEventListener('scroll', this._handleScroll);
    Toggler.removeListener(this.offToggle);
  }

  onToggle = (isOn) => {
    this.setState({ isToggleOn: isOn });
  };

  // Handlers
  toggleNav = (evt) => {
    Toggler.toggle();
  };

  _clickCheck = (evt) => {
    if (this.state.isToggleOn) {
      if (closest(evt.target, (elt) => elt.nodeName.toUpperCase() === 'A')) {
        Toggler.off();
      } else if (
        !closest(evt.target, (elt) => elt.nodeName.toUpperCase() === 'HEADER')
      ) {
        evt.preventDefault();
        Toggler.off();
      }
    }
  };

  _handleScroll = (evt) => {
    const scrollY = window.scrollY;
    const disableClickOut = isCheckout(this.props.location);
    const { showBg } = this.state;
    if (
      !disableClickOut &&
      ((showBg && scrollY <= SET_BG_AT) || (!showBg && scrollY > SET_BG_AT)) &&
      this._mounted
    ) {
      this.setState({ showBg: !showBg });
    }
  };

  // Render
  render() {
    const { showBg, isToggleOn } = this.state;
    const disableClickOut = isCheckout(this.props.location);

    const logoInnerElt = (
      <>
        <CameraIcon className="icon" /> GoFullPage
      </>
    );

    const logoElt = disableClickOut ? (
      <span className="logo">{logoInnerElt}</span>
    ) : (
      <NavLink to="/" exact={true} className="logo">
        {logoInnerElt}
      </NavLink>
    );

    const className = classNames('header', {
      'with-bg': showBg || getForceBg(this.props.location.pathname),
      simple: disableClickOut,
    });

    const navLinksAttrs = {};
    if (!isToggleOn) {
      navLinksAttrs['aria-hidden'] = 'true';
    }

    return (
      <>
        {PUSHDOWN_MESSAGE && PUSHDOWN_MESSAGE.text && (
          <PushdownMessage {...PUSHDOWN_MESSAGE} />
        )}
        <header className={className}>
          <Container className="group">
            {logoElt}
            {!disableClickOut && (
              <nav>
                <button
                  className="hamburger"
                  type="button"
                  onClick={this.toggleNav}
                  aria-label={isToggleOn ? 'Close the menu' : 'Open the menu'}
                >
                  <span className="sr-only">view nav</span>
                  <span className="bar1" />
                  <span className="bar2" />
                </button>
                <div className="nav-links" {...navLinksAttrs}>
                  <ul className="group container">
                    {LINKS.map((l) =>
                      l.href ? (
                        <li key={l.href}>
                          <a href={l.href}>
                            {l.coming_soon && <ComingSoon />}
                            {l.name}
                          </a>
                        </li>
                      ) : l.to ? (
                        <li key={l.to}>
                          <NavLink to={l.to}>
                            {l.coming_soon && <ComingSoon />}
                            {l.name}
                          </NavLink>
                        </li>
                      ) : null,
                    )}
                  </ul>
                </div>
              </nav>
            )}
          </Container>
        </header>
      </>
    );
  }
}

export default withRouter(Header);

// ## Helpers

const ComingSoon = () => {
  return <span className="coming-soon">New</span>;
};

const Toggler = (function () {
  const elt = document.documentElement;
  const className = 'expand-nav';
  const self = {
    toggle: (force) => {
      elt.classList.toggle(className, force);
      const isOn = self.isOn();
      self._listeners.forEach((t) => t(isOn));
    },
    on: () => self.toggle(true),
    off: () => self.toggle(false),
    isOn: () => elt.classList.contains(className),
    _listeners: [],
    addListener: (fn) => self._listeners.push(fn),
    removeListener: (fn) =>
      (self._listeners = self._listeners.filter((t) => t !== fn)),
  };
  return self;
})();

const closest = (elt, checkFn) => {
  while (elt && !checkFn(elt)) {
    elt = elt.parentElement;
  }
  return elt;
};
