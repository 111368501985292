// Use this to
export const extractProps = (props, keys) => {
  const spreadProps = Object.assign({}, props);
  const customProps = {};
  keys.forEach((k) => {
    if (k in spreadProps) {
      customProps[k] = spreadProps[k];
      delete spreadProps[k];
    }
  });

  return { spreadProps, customProps };
};
