import React from 'react';

import { Button, PrimaryButton } from '../../components/Base';

export default () => {
  return (
    <p>
      Your subscription has expired. You can sign up for a new one:
      <br />
      <PrimaryButton type={Button.TYPE_ROUTE} to="/checkout" size="small">
        Go Premium
      </PrimaryButton>
    </p>
  );
};
