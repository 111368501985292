import emojiStar from '../../../assets/img/emoji/emoji-star-16.png';
import emojiStar2x from '../../../assets/img/emoji/emoji-star-16@2x.png';

const STYLE = {
  verticalAlign: 'middle',
  paddingBottom: '0.4rem',
  margin: '0 1px',
};

export default function Star(props) {
  return (
    <img
      src={emojiStar}
      srcSet={`${emojiStar2x} 2x`}
      style={STYLE}
      {...props}
    />
  );
}

export function Stars({ count, ...props }) {
  return [...new Array(count)].map((_, i) => <Star key={i} {...props} />);
}
