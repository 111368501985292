import React from 'react';

export default ({ text, num = 2 }) => {
  let tokens = text.split(/\s+/);
  let orphans = [];
  while (num-- > 0) {
    orphans.unshift(tokens.pop());
  }
  return (
    <>
      {tokens.length ? tokens.join(' ') + ' ' : ''}
      <span className="nowrap">{orphans.join(' ')}</span>
    </>
  );
};
