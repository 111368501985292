import { Component } from 'react';

// # CheckoutStage
//
// The concept of rendering checkout stages this way is
// to have the React instance for each stage to exist
// throughout the entire checkout, so if you go back
// or forwards, they'll still have any state that they
// had before (which was especially useful with the Stripe
// Elements which otherwise get reset every time, but
// now that we are using the hosted checkout, it may
// not matter anymore...)
//
export default class CheckoutStage extends Component {
  constructor(props) {
    super(props);
    this._didOnce = {};
  }

  render() {
    return this.props.show ? this.showComponent() : null;
  }

  /**
   * Do Once
   * This is a convenience function to do something just
   * once during the lifetime of this Component instance,
   * i.e., the GA Ecomm tracking calls in checkout stages
   * only when they have states (so done inside `showComponent`)
   *
   * @param {string} key
   * @param {() => void} fn
   * @param {boolean} [async]
   */
  doOnce(key, fn, async) {
    if (!this._didOnce[key]) {
      if (async) {
        window.setTimeout(fn, 0);
      } else {
        fn();
      }
      this._didOnce[key] = true;
    }
  }

  // Show Component
  //
  // This is the function you want to override (not render).
  //
  showComponent() {
    return <div>UNIMPLEMENTED</div>;
  }
}
