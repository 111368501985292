import { useMemo } from 'react';
import { useStyledElement } from '../../common/hooks';
import { classes } from '../../common/utils';

export const ClassNameLabel = 'flex flex-col w-full items-stretch space-y-1';
export const ClassNameLabelText = 'text-gray-600';
export const ClassNameField =
  'mt-0 block w-full border-0 border-b-2 border-gray-300 px-1 focus:ring-0 focus:border-primary focus:outline-none';

export function ReadOnlyFieldText(props: { label: string; text: string }) {
  const paragraphs = useMemo(() => props.text?.split('\n') ?? [], [props.text]);
  return (
    <label className={ClassNameLabel}>
      <span className={ClassNameLabelText}>{props.label}</span>
      <div className={classes(ClassNameField, 'space-y-1')}>
        {paragraphs.map((text) => (
          <p>{text}</p>
        ))}
      </div>
    </label>
  );
}

export function Field(props: {
  label: string;
  input: JSX.Element;
  htmlFor: string;
}) {
  const _input = useStyledElement(props.input, ClassNameField);
  return (
    <label className={ClassNameLabel} htmlFor={props.htmlFor}>
      <span className={ClassNameLabelText}>{props.label}</span>
      {_input}
    </label>
  );
}
