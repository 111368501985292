import styled from 'styled-components';

const Divider = styled.div`
  border-top: 1px solid #f0f0f0;
  width: 100%;
`;

Divider.displayName = 'Divider';

export default Divider;
