import { updateHtml, tr, options } from 'e7n';

options.disabled = true; // NOTE - disable for now so we can focus on launching!

// HACK - fake the chrome.i18n.getMessage API - TODO use proper API
interface ChromeI18nWindow extends Window {
  chrome?: {
    i18n?: {
      getMessage?: (...args: any[]) => any;
    };
  };
}

declare let window: ChromeI18nWindow;

if (!window.chrome) {
  window.chrome = {};
}
if (!window.chrome.i18n) {
  window.chrome.i18n = {};
}
if (!window.chrome.i18n.getMessage) {
  window.chrome.i18n.getMessage = () => undefined;
}
// ENDHACK

// @ts-ignore
if (process.env.PSEUDOLOC === '1') {
  options.pseudoloc = true;
}

export { tr, updateHtml };
