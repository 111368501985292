import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

import { MAILCHIMP_URL } from '../constants';
import { log } from '../logger';

import { Form, Input, Button, ButtonSizes } from './Base';
import OrphanText from './OrphanText';

const PLEA_TEXT =
  'Join our newsletter to keep up with the latest product updates:';

export default class NewsletterForm extends Component {
  static propTypes = {
    btnClassName: PropTypes.string,
    pClassName: PropTypes.string,
    initialEmail: PropTypes.string,
    onSuccess: PropTypes.func,
    doubleOptIn: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    doubleOptIn: true,
  };

  constructor(props) {
    super(props);
    this.state = {};
    if (props.initialEmail) {
      this.state.email = props.initialEmail;
    }
  }

  // Life-cycle
  handleChangeInput = (evt) => {
    const targ = evt.currentTarget;
    log(`[${targ.name}]: ${targ.value}`);
    this.setState({ [targ.name]: targ.value });
  };

  handleSuccess = () => {
    // HACK - a way to access success events :(
    if (this._didSuccess) {
      return;
    }
    this._didSuccess = true;
    if (this.props.onSuccess) {
      this.props.onSuccess(); // { email: this.state.email });
    }
  };

  // Render
  render() {
    const { btnClassName, pClassName, doubleOptIn } = this.props;
    const { email } = this.state;

    return (
      <MailchimpSubscribe
        url={MAILCHIMP_URL}
        render={({ subscribe, status, message }) => {
          if (status === 'success') {
            this.handleSuccess();
          }
          return (
            <div className="newsletterform">
              <p className={pClassName}>
                <OrphanText text={PLEA_TEXT} num={3} />
              </p>
              <Form
                className="pure-form-center-spaced br"
                onSubmit={(evt) => {
                  evt.preventDefault();
                  subscribe({ EMAIL: email });
                }}
              >
                <Input
                  name="email"
                  onChange={this.handleChangeInput}
                  value={email}
                  placeholder={'you@example.com'}
                />
                <Button
                  className={classNames(btnClassName)}
                  type="submit"
                  size={ButtonSizes.small}
                  primary={true}
                >
                  Submit
                </Button>
              </Form>
              {status === 'sending' && <div className="msg">sending...</div>}
              {status === 'error' && (
                <div
                  className="msg error"
                  dangerouslySetInnerHTML={{ __html: message }}
                />
              )}
              {status === 'success' && (
                <div className="msg">
                  {doubleOptIn
                    ? 'Success, a double opt-in confirmation email is on its way!'
                    : 'Subscribed!'}
                </div>
              )}
            </div>
          );
        }}
      />
    );
  }
}
