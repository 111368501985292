import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Label } from '../atoms/typography';

type Props = {
  title: string | React.ReactNode;
  opened: boolean;
  onClose: () => void;
  actions?: React.ReactNode;
};

const Title = styled(Label)`
  font-weight: 700;
`;

const Modal: React.FC<Props> = ({
  title,
  opened,
  onClose,
  actions,
  children,
}) =>
  !opened ? (
    <></>
  ) : (
    <Transition.Root show={opened} as={Fragment}>
      <Dialog as="div" className="relative z-[1000]" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-[1000] overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative min-w-[480px] transform overflow-hidden rounded-lg bg-white p-[24px] text-left shadow-xl transition-all sm:w-full sm:max-w-lg">
                <div className="bg-white">
                  <div className="flex flex-col items-start">
                    <Title>{title}</Title>
                    <div className="my-[16px] w-full">{children}</div>
                  </div>
                </div>
                {actions && <div>{actions}</div>}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );

export default Modal;
