import { tr } from '../utils/e7n-util';

type Feature = {
  name: string;
  premium: boolean;
  enterprise: boolean;
};

const FEATURES: Feature[] = [
  {
    name: tr('Image cropping'),
    premium: true,
    enterprise: true,
  },
  {
    name: tr('Date & timestamp'),
    premium: true,
    enterprise: true,
  },
  {
    name: tr('Annotations: blur, text, highlight'),
    premium: true,
    enterprise: true,
  },
  {
    name: tr('Guaranteed capture'),
    premium: false,
    enterprise: true,
  },
  {
    name: tr('Multi-user accounts'),
    premium: false,
    enterprise: true,
  },
  {
    name: tr('Group demo and webinar'),
    premium: false,
    enterprise: true,
  },
];

const data = {
  features: FEATURES,
};

export default data;
