import { Helmet } from 'react-helmet';

import { Container, Section } from '../components/Base';
import { getTitle } from '../utils/document';

import AccessibilityPage from './AccessibilityPage.mdx';

export default () => {
  return (
    <Section className="privacy">
      <Helmet>
        <title>{getTitle('Accessibility')}</title>
      </Helmet>
      <Container>
        <div className="gfp-row">
          <div className="col-8 offset-2">
            <h1>Accessibility</h1>
            <div className="prose legal-text">
              <AccessibilityPage />
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
};
