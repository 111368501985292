import { useCallback, useEffect, useMemo } from 'react';
import useImagesStore from '../../../store/images/store';
import { Image } from '../../../common/types';

/** A hook to fetch images, add them to the internal state, and update when images are updated internally. */
export function useImages(): Image[] {
  // Fetch images on component load
  const fetchImages = useImagesStore((s) => s.fetchImages);
  useEffect(() => void fetchImages(), [fetchImages]);

  return useImagesStore((s) => s.images);
}

export function useImage(imageId: string | undefined): Image | undefined {
  return useImagesStore(
    useCallback((s) => (imageId ? s.index[imageId] : undefined), [imageId]),
  );
}

export enum ImageSortOrder {
  DateAsc,
  DateDesc,
  NameAsc,
  NameDesc,
  UrlAsc,
  UrlDesc,
}

type ImageGroup = { title: string; images: Image[] };

export function useGroupedImages(sortOrder: ImageSortOrder): ImageGroup[] {
  const images = useImages();
  return useMemo(() => {
    switch (sortOrder) {
      case ImageSortOrder.DateAsc:
      case ImageSortOrder.DateDesc:
        return ImageByDate(images, sortOrder === ImageSortOrder.DateAsc);
      case ImageSortOrder.NameAsc:
      case ImageSortOrder.NameDesc:
        return ImageByName(images, sortOrder === ImageSortOrder.NameAsc);
      case ImageSortOrder.UrlAsc:
      case ImageSortOrder.UrlDesc:
        return ImageByUrl(images, sortOrder === ImageSortOrder.UrlAsc);
      default:
        return [];
    }
  }, [images, sortOrder]);
}

function getImageGroups<TKey extends string | number>(
  images: Image[],
  getGroupByKey: (image: Image) => TKey,
  getGroupTitle: (key: TKey) => string,
  reverse?: boolean,
): ImageGroup[] {
  const imageGroupsByKey = new Map<TKey, Image[]>();

  for (const image of images) {
    const key = getGroupByKey(image);
    pushToMap(imageGroupsByKey, key, image);
  }

  const imageGroups = [...imageGroupsByKey.entries()].map(([key, images]) => ({
    title: getGroupTitle(key),
    images,
  }));
  return reverse ? imageGroups.reverse() : imageGroups;
}

function pushToMap<K, V>(index: Map<K, V[]>, key: K, value: V) {
  if (!index.has(key)) index.set(key, []);
  index.get(key)?.push(value);
}

function ImageByDate(images: Image[], ascending: boolean): ImageGroup[] {
  return getImageGroups(
    images,
    (img) => img.created.toISOString().split('T')[0],
    (key) => new Date(key).toLocaleDateString(),
    ascending,
  ); // images are returned in descending order so reverse them again to ascend them
}

function ImageByName(images: Image[], ascending: boolean): ImageGroup[] {
  return getImageGroups(
    images,
    (img) => img.title?.[0] || '',
    (key) => key || 'N/A',
    ascending,
  ); // images are returned in descending order so reverse them again to ascend them
}

function ImageByUrl(images: Image[], ascending: boolean): ImageGroup[] {
  return getImageGroups(
    images,
    (img) => urlToDomain(img.urlLink) || '',
    (key) => key || 'N/A',
    ascending,
  ); // images are returned in descending order so reverse them again to ascend them
}

function urlToDomain(url: string | undefined): string | null {
  return url?.split('//').slice(-1)[0].split('/')[0] || null;
}
