import { Hub } from '@aws-amplify/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';

import {
  logCheckoutAccount,
  logCheckoutPayment,
  logCheckoutSuccess,
} from '../../abtest';
import {
  AUTH_PROVIDER_CHANNEL,
  AUTH_PROVIDER_EVT_SIGNED_IN,
} from '../../auth/constants';
import { CHECKOUT_TYPE_STRIPE, IS_ABTESTV2_ENABLED } from '../../constants';
import {
  EVENT_LOGIN_SUCCESS,
  HUB_CHANNEL as EXTENSION_HUB_CHANNEL,
} from '../../extension/constants';
import { updateQueryString } from '../../utils/url-util';

import { Container, Section } from '../Base';
import ErrorMessage from '../ErrorMessage';
import LoaderDots from '../LoaderDots';

import CheckoutAccount from './CheckoutAccount';
import CheckoutPayment from './CheckoutPayment';
import CheckoutSuccess from './CheckoutSuccess';

import {
  AuthStateVal,
  STATE_LOADING,
  STATE_SIGNEDIN,
  STATE_SIGNIN,
} from '../../auth/AuthProvider';
import { log } from '../../logger';
import Account from '../Account';
import './index.scss';

const STAGE_ACCOUNT = {
  slug: 'account',
  name: 'Sign Up',
  component: CheckoutAccount,
  logAb: logCheckoutAccount, // TODO(ab-v1) - remove?
};
const STAGE_PAYMENT = {
  slug: 'payment',
  name: 'Billing',
  component: CheckoutPayment,
  nextIsLast: true,
  logAb: logCheckoutPayment, // TODO(ab-v1) - remove?
};
const STAGE_SUCCESS = {
  slug: 'success',
  name: 'Success!',
  submitText: 'Start free trial', // NOTE - this appears as submit in prior step
  component: CheckoutSuccess,
  logAb: logCheckoutSuccess, // TODO(ab-v1) - remove?
};

const STAGES = [STAGE_ACCOUNT, STAGE_PAYMENT, STAGE_SUCCESS];

STAGES.forEach((stage, i) => (stage.index = i));

class CheckoutStages extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    authState: PropTypes.string.isRequired,
    user: PropTypes.object,
    signInUser: PropTypes.func.isRequired,
    signUpUser: PropTypes.func.isRequired,
    signOutUser: PropTypes.func.isRequired,
    lookupAccount: PropTypes.func.isRequired,
    error: PropTypes.string,
    errorData: PropTypes.object,
    setError: PropTypes.func.isRequired,
    account: PropTypes.shape({
      userId: PropTypes.string.isRequired,
      customerId: PropTypes.string,
      subscription: PropTypes.object,
      card: PropTypes.object,
      payments: PropTypes.array,
    }),
    checkoutResp: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      didExtensionLogin: false, // keep track of if we logged into extn successfully
      didCheckout: false, // keep track of did a new sign up this time
    };

    // non-reactive state
    this.stageHistory = [];

    // HACK - set the checkout type
    const params = new URLSearchParams(window.location.search);
    const checkoutType = params.get('checkout_method');
    this.forceAbTestV2Group = checkoutType;
  }

  // Life-cycle

  componentDidMount() {
    this._mounted = true;
    Hub.listen(AUTH_PROVIDER_CHANNEL, this.onHubCapsule, 'CheckoutStages');
    Hub.listen(EXTENSION_HUB_CHANNEL, this.onHubCapsule, 'CheckoutStages');

    if (this.isSignedIn() && !this.props.account) {
      this.props.lookupAccount({
        includeAbTest: IS_ABTESTV2_ENABLED,
        forceAbTestV2Group: this.forceAbTestV2Group,
      });
    }
    this._updateAb();
  }

  componentWillUnmount() {
    this._mounted = false;
    Hub.remove(AUTH_PROVIDER_CHANNEL, this.onHubCapsule);
    Hub.remove(EXTENSION_HUB_CHANNEL, this.onHubCapsule);
  }

  componentDidUpdate(prevProps) {
    this._updateAb();
  }

  _updateAb() {
    if (!this._mounted) {
      return;
    }
    const { stage } = this.getStages();
    const user = this.props.user;
    // NOTE - these ratelimit per event, so it's ok to call extra unnecessary times
    stage.logAb && stage.logAb((user && user.id) || null);
  }

  // Hub listener

  onHubCapsule = ({ channel, payload, source }) => {
    // if the user logs in while viewing the account stage,
    // then auto-progress to next stage
    if (
      channel === AUTH_PROVIDER_CHANNEL &&
      payload.event === AUTH_PROVIDER_EVT_SIGNED_IN
    ) {
      const { stage, nextStage } = this.getStages();
      // start an account lookup for next stage
      this.props.lookupAccount({
        includeAbTest: IS_ABTESTV2_ENABLED,
        forceAbTestV2Group: this.forceAbTestV2Group,
      });
      if (stage === STAGE_ACCOUNT) {
        const params = new URLSearchParams(window.location.search);

        // HARDCODED `?checkout=cancel` in src/app.js
        // set here to avoid auto-progressing on login
        if (params.get('checkout') === 'cancel') {
          if (window.history && window.history.replaceState) {
            const newUrl = updateQueryString(
              { checkout: null },
              window.location,
              params,
            );
            window.history.replaceState(null, '', newUrl);
          }
        } else {
          this.goToStage(nextStage);
        }
      }
    }
    // listen for successful logins -- this can be used with
    // the popup to know when it's safe to close the popup
    // and return to the extension
    else if (
      channel === EXTENSION_HUB_CHANNEL &&
      payload.event === EVENT_LOGIN_SUCCESS
    ) {
      this.setState({ didExtensionLogin: true });
    }
  };

  // Helpers
  wrappedCreateCheckoutSessionUrl = (checkoutParams) => {
    // TODO - maybe unnecessary to wrap for stripeCheckout
    // since it leaves this site...
    this.setState({ didCheckout: true });
    return this.props.createCheckoutSessionUrl(checkoutParams);
  };

  getStages() {
    const { match } = this.props;
    const path = window.location.pathname;

    let nextStage;
    let prevStage;
    let stage = STAGES.find(({ slug }) =>
      path.startsWith(`${match.url}/${slug}`),
    );

    if (!this.isSignedIn() && stage !== STAGE_ACCOUNT) {
      nextStage = stage;
      stage = STAGE_ACCOUNT;
    } else if (!stage) {
      stage = STAGE_PAYMENT;
    }

    if (!nextStage) {
      prevStage = STAGES[stage.index - 1];
      nextStage = STAGES[stage.index + 1];
    }

    return { stage, prevStage, nextStage };
  }

  goToStage = (stage) => {
    const path = `${this.props.match.url}/${stage.slug}`;
    this.props.history.push(path);
  };

  isSignedIn() {
    return this.props.authState === STATE_SIGNEDIN;
  }

  // Render

  render() {
    /** @type {{
     *   account?: Account;
     *   authState: AuthStateVal;
     *   error: string;
     *   isPopup?: boolean;
     *   isSignIn?: boolean;
     *   match: { url: string };
     *   signInUser: () => void;
     *   signUpUser: () => void;
     * }}
     */
    const {
      account,
      authState,
      error,
      errorData,
      isPopup,
      isSignIn,
      match,
      signInUser,
      signUpUser,
    } = this.props;

    const { didExtensionLogin, didCheckout } = this.state;

    const urlPathPrefix = match.url;
    const signedIn = this.isSignedIn();

    // TODO(NEXT)(NEXT) - error where org hasn't completed setup and has missing status

    const alreadyHasSubscription = Boolean(
      account?.status && account.status !== 'canceled',
    );

    const { stage, prevStage, nextStage } = this.getStages();

    // Determine isStripeCheckout based on AbTestV2 info!
    const isStripeCheckout = account?.abTestV2?.group === CHECKOUT_TYPE_STRIPE;

    // HACK - update the non-reactive stage history object
    const stageHistory = this.stageHistory;
    if (
      !stageHistory.length ||
      stageHistory[stageHistory.length - 1] !== stage
    ) {
      stageHistory.push(stage);
    }

    const shouldClosePopup =
      isPopup &&
      alreadyHasSubscription &&
      stage === STAGE_PAYMENT &&
      !didCheckout;

    const isLoading =
      authState === STATE_LOADING ||
      (stage === STAGE_PAYMENT && !account) ||
      shouldClosePopup ||
      (isPopup && stage === STAGE_ACCOUNT && stageHistory.length === 1);

    // popup - auto-close when it is a regular login
    if (shouldClosePopup && didExtensionLogin) {
      // only close if we both logged in with a sub and didn't need to checkout
      log('%c CLOSE_POPUP ', 'background:red;color:white;');
      window.close();
    }

    // popup - autoload the hosted UI if first load of checkout and logged out
    if (isPopup && authState === STATE_SIGNIN && stageHistory.length === 1) {
      if (isSignIn) {
        signInUser();
      } else {
        signUpUser();
      }
    }

    // render all components together, so we can hold onto form state
    // of the card until we leave checkout (may not work anymore,
    // now that we're using the hosted checkout...)

    const className = classNames('account first-sec checkoutstages', {
      'is-popup': isPopup,
    });

    const isPopupLoading = isPopup && isLoading;

    // HACK - check header position to properly offset .steps
    const header = document.querySelector('header');
    const headerTop = (header && header.offsetTop) || 0;

    return (
      <Section className={className}>
        {isPopupLoading && (
          <div style={{ padding: '4em 0' }}>
            <LoaderDots style={{ display: 'block', margin: '0 auto' }} />
          </div>
        )}
        {!isPopupLoading && (
          <ol className="steps" style={{ marginTop: `${headerTop}px` }}>
            {STAGES.map((tStage, i) => (
              <li
                key={`stage-${i}`}
                className={stage.index >= tStage.index ? 'active' : ''}
              >
                {i + 1}. {tStage.name}
              </li>
            ))}
          </ol>
        )}
        <Container>
          {error && (
            <ErrorMessage
              error={error}
              errorData={errorData}
              scrollIntoView={true}
            />
          )}
          {STAGES.map((stg) => (
            <stg.component
              {...this.props}
              key={stg.index}
              urlPathPrefix={urlPathPrefix}
              signedIn={signedIn}
              account={account}
              alreadyHasSubscription={alreadyHasSubscription}
              index={stg.index}
              nextIsLast={stg.nextIsLast}
              show={!isPopupLoading && stage.index === stg.index}
              goToStage={this.goToStage}
              prevStage={prevStage}
              nextStage={nextStage}
              isPopup={isPopup}
              isStripeCheckout={isStripeCheckout}
              createCheckoutSessionUrl={this.wrappedCreateCheckoutSessionUrl}
              performCheckoutSessionSuccess={
                this.props.performCheckoutSessionSuccess
              }
            />
          ))}
        </Container>
      </Section>
    );
  }
}

export default CheckoutStages;
