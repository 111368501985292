import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import BTFArrow from './icons/BTFArrow';

const SCROLL_OFFSET = 120;

export default class Benefits extends Component {
  static propTypes = {
    benefits: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        type: PropTypes.string,
      }).isRequired,
    ).isRequired,
  };

  // Constructor
  constructor(props) {
    super(props);
    this.arrowRef = React.createRef();
    this.state = { animateArrow: true };
  }

  // Life-cycle
  componentDidMount() {
    this._mounted = true;
    window.addEventListener('scroll', this._scrollEvt, false);
    this._intervalId = window.setInterval(this._scrollCheck, 200);
  }

  componentWillUnmount() {
    this._mounted = false;
    this._clearEvts();
  }

  _clearEvts = () => {
    window.removeEventListener('scroll', this._scrollEvt);
    window.clearInterval(this._intervalId);
  };

  _scrollEvt = (evt) => {
    this._scrolled = true;
  };

  _scrollCheck = () => {
    if (!this._scrolled) {
      return;
    }

    const offsetTop = this._getArrowOffsetTop();
    if (offsetTop === null) {
      return;
    }

    if (window.scrollY >= offsetTop - SCROLL_OFFSET) {
      this._clearEvts();
      if (this._mounted) {
        this.setState({ animateArrow: false });
      }
    }
  };

  _getArrowOffsetTop = () => {
    let node = this.arrowRef.current;
    if (!node) {
      return null;
    }

    let offsetTop = 0;
    while (node) {
      offsetTop += node.offsetTop || 0;
      node = node.offsetParent;
    }

    return offsetTop;
  };

  _scroll = (left, top) => {
    if (window.scroll && typeof window.scroll === 'function') {
      window.scroll({ left, top, behavior: 'smooth' });
    } else {
      window.scrollTo(left, top);
    }
  };

  // Handlers
  handleClickArrow = (evt) => {
    evt.preventDefault();
    const offsetTop = this._getArrowOffsetTop();
    this._scroll(0, offsetTop - SCROLL_OFFSET);
    this.setState({ animateArrow: false });
  };

  // Render
  render() {
    const { benefits } = this.props;
    const { animateArrow } = this.state;

    return (
      <div className="info-block">
        <p className="title">
          <strong>Show me the benefits</strong>
        </p>
        <div className="btf" ref={this.arrowRef}>
          <BTFArrow animate={animateArrow} onClick={this.handleClickArrow} />
        </div>
        <ul className="benefits">
          {benefits.map(({ title, text, type, linkText }) => {
            return (
              <li key={title}>
                <div className={`ben-item${type ? 'ben-item-' + type : ''}`}>
                  <strong>{title}:</strong>{' '}
                  {linkText && type === 'premium' && (
                    <Link className="sly" to="/premium">
                      {linkText}
                    </Link>
                  )}{' '}
                  {text}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}
