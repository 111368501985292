import querystring from 'querystring';
import { Component } from 'react';

import inIframe from '../utils/iframe-check';

/**
 * Component that checks for `?__fpextid={id}` in the
 * URL and save it in local storage. In non-MASTER envs
 * this is used to know which extension id we expect
 * to connect to.
 */
export default class TestExtensionIdSetter extends Component {
  componentDidMount() {
    const key = '__fpextid';
    const qs = querystring.parse(window.location.search.substring(1));
    if (qs.id) {
      const id = qs.id;
      window.localStorage.setItem(key, id);
    } else {
      window.localStorage.removeItem(key);
    }

    if (!inIframe) {
      window.location = '/';
    }
  }

  render() {
    return <div />;
  }
}
