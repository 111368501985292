/**
 * DUPED from `reportLink` in  util.js in the extension code
 */

const REPORT_LINK = 'https://screencapture.mrcoles.com/';
const MAX_LINK_LEN = 1600; // 2083; - arbitrarily smaller than max

/**
 * Create a report link
 *
 * Example:
 *
 * v = 7.12
 * u = chrome-extension://fdpohaocaechififmbbbbbknoalclacl/editor.html?id=44&e=0
 * i = us-east-1:9f2084dd-e787-4697-b5f1-1a50c675428b
 * s = sub-ok
 * o = auto_dl2=false;pdf_url_and_date=true;save_as=true;fmt=png;fixed_elts=true;adv_scroll=true;pdf_format=letter;pdf_smart_page=true;pdf_date_fmt=DATE_FULL;pdf_insert_links=false;dir=;fit_copies=true;frame_persist=true
 * e = [EditorReportButton]
 *
 * What we want:
 *
 * u = https://gofullpage.com/connect
 * i = us-east-1:9f2084dd-e787-4697-b5f1-1a50c675428b
 * e = [CheckoutError]
 *
 *
 */
export const getReportLink = (url: string, err?: any, userId?: string) => {
  const args: { key: string; val: string }[] = [];
  if (url) {
    args.push({ key: 'u', val: b64(url) });
  }
  if (userId) {
    args.push({ key: 'i', val: b64(userId) });
  }
  const errStr = errorToString(err);
  if (errStr) {
    args.push({ key: 'e', val: b64(errStr) });
  }

  const params = new URLSearchParams();
  args.forEach(({ key, val }) => {
    params.set(key, val);
  });

  let qs = params.toString();
  const max_len = MAX_LINK_LEN - REPORT_LINK.length - 1;
  if (qs.length >= max_len) {
    qs = qs.substring(0, max_len);
  }
  return REPORT_LINK + (qs ? '?' + qs : '');
};

/**
 * Convert an error to a string
 * @param e
 * @returns
 */
const errorToString = (e: any) => {
  let msg = undefined;
  if (typeof e === 'string') {
    return e;
  } else if (e) {
    msg = [e.name, e.via ? `(${e.via})` : '', e.message, e.stack]
      .filter((x) => x)
      .join(' ');
  }
  return msg;
};

/**
 * Base64 encode a string
 */
const b64 = (val: string) => {
  var converted = false;
  try {
    val = btoa(val);
    converted = true;
  } catch (e) {}

  if (converted) {
    while (val.charAt(val.length - 1) === '=') {
      val = val.substring(0, val.length - 1);
    }
  }

  return val;
};
