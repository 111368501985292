import React, { useMemo } from 'react';

const ResponsiveImg = ({ src, src2x, width, height, style, ...props }) => {
  const _src = ((window.devicePixelRatio || 1 > 1) && src2x) || src;
  const _style = useMemo(() => {
    return {
      ...style,
      width: `${width}px`,
      // height: `${(height / MAX_CONTAINER) * 100}%`,
      aspectRatio: `auto ${width} / ${height}`,
      maxWidth: '100%',
    };
  }, [style, width, height]);

  return <img src={_src} style={_style} {...props} />;
};

export default ResponsiveImg;
