import PropTypes from 'prop-types';
import React from 'react';

const Dollars = ({ cents, simplify, monthly }) => {
  let dollars = (cents / 100 / (monthly ? 12 : 1)).toLocaleString(undefined, {
    style: 'currency',
    currency: 'USD',
  });
  if (simplify && (dollars.endsWith('.00') || dollars.endsWith(',00'))) {
    dollars = dollars.substring(0, dollars.length - 3);
  }
  return <>{dollars}</>;
};

Dollars.propTypes = {
  cents: PropTypes.number.isRequired,
  simplify: PropTypes.bool,
  monthly: PropTypes.bool,
};

export default Dollars;
