import { Hub } from '@aws-amplify/core';
import PropTypes from 'prop-types';
import { Component } from 'react';

import {
  asErrorMsg,
  createUpdatePaymentSessionUrl,
  performUpdatePaymentSessionSuccess,
} from '../../api';
import { ACCOUNT_TYPES } from '../../types/Account';
import { asAbsoluteUrl } from '../../utils/url-util';

class UpdateCard extends Component {
  static propTypes = {
    account: PropTypes.object.isRequired, //TODO(prop-types)
    accountType: PropTypes.oneOf(ACCOUNT_TYPES),
    // TODO - requires updateOrganization if accountType is "org", otherwise requires updateAccount... maybe type this better...
    updateAccount: PropTypes.func,
    updateOrganization: PropTypes.func,
    setError: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showUpdated: false,
      stripeCheckoutErrorMsg: '',
    };
    this._mounted = true;
  }

  // Life-cycle
  componentDidMount() {
    Hub.listen('UpdateCardWidget', this.onHubCapsule, 'UpdateCardComponent');

    const params = new URLSearchParams(window.location.search);
    const session_id =
      (params.get('checkout') === 'success' && params.get('session_id')) ||
      null;
    const account_type = this.props.accountType;
    if (session_id) {
      this.handleStripeCheckoutUpdateCardSuccess(session_id, account_type);
    }
  }

  componentWillUnmount() {
    this._mounted = false;
    Hub.remove('UpdateCardWidget', this.onHubCapsule);
  }

  // Hub listener
  onHubCapsule = ({ channel, payload, source }) => {
    if (channel === 'UpdateCardWidget') {
      const { event, data } = payload;
      if (event === 'updated') {
        this.safeSetState({ showUpdated: true });
        window.setTimeout(() => {
          this.safeSetState({ showUpdated: false });
        }, 2000);
      }
    }
  };

  // Handlers

  /**
   *
   * @param {string} session_id
   * @param {import('../../types/Account').AccountType} [account_type] if doing an org checkout, then *must* specify
   */
  handleStripeCheckoutUpdateCardSuccess = (session_id, account_type) => {
    this.setState({ loading: true });

    performUpdatePaymentSessionSuccess({ session_id, account_type })
      .then((data) => {
        const params = new URLSearchParams(window.location.search);
        params.delete('checkout');
        params.delete('session_id');
        let qs = params.toString();
        qs = qs ? `?${qs}` : '';
        this.props.history.replace(
          window.location.pathname + qs + window.location.hash,
        );
        this.setState({ loading: false });

        if (data.type === 'organization') {
          const org = data.data;
          this.props.updateOrganization(org);
        } else {
          const account = data.data;
          this.props.updateAccount(account);
        }
      })
      .catch((err) => {
        this.props.setError(err);
        this.setState({
          loading: false,
          stripeCheckoutErrorMsg: asErrorMsg(err),
        });
      });
  };

  handleShowForm = (evt) => {
    if (evt) {
      evt.preventDefault();
    }

    const account_type = this.props.accountType;

    this.setState({
      stripeCheckoutErrorMsg: '',
      loading: true,
    });

    const cancel_url = asAbsoluteUrl(window.location.pathname);
    const success_url = asAbsoluteUrl(window.location.pathname);

    createUpdatePaymentSessionUrl({
      cancel_url,
      success_url,
      account_type,
    })
      .then((url) => {
        // account is auto updated by the resp?
        window.location.href = url;
      })
      .catch((err) => {
        this.setState({
          stripeCheckoutErrorMsg: asErrorMsg(err),
          loading: false,
        });
      });
  };

  handleHideForm = (evt) => {
    if (evt) {
      evt.preventDefault();
    }
    this.setState({ showUpdated: false });
  };

  // Helpers
  safeSetState(newState) {
    if (this._mounted) {
      this.setState(newState);
    }
  }

  // Render
  render() {
    const { account, setError } = this.props;
    const { error, loading, showUpdated, stripeCheckoutErrorMsg } = this.state;

    let cardElt;
    if (account) {
      cardElt = loading ? (
        <p className="dim">(loading…)</p>
      ) : (
        <p>
          {account.card
            ? `${account.card.brand} ${account.card.last4}`
            : 'No card on file'}{' '}
          <a href="#" onClick={this.handleShowForm}>
            (update)
          </a>
        </p>
      );
    }

    let formElt;
    if (showUpdated) {
      formElt = 'Card updated!';
    }

    return (
      <div id="fp-update-card-form-container" style={{ width: '100%' }}>
        {error && <div className="error">{error}</div>}
        {cardElt}
        {stripeCheckoutErrorMsg && (
          <p className="br error">{stripeCheckoutErrorMsg}</p>
        )}
        {formElt}
      </div>
    );
  }
}

export default UpdateCard;
